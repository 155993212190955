import useIsAuthenticate from "./hooks/useIsAuthenticate";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../services/http";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import LmcVerifyEmail from "./components/LmcVerifyEmail";
import { LmcActionKind } from "./contexts";
import SocialAuth from "./SocialAuth";
import { IResolveParams } from "reactjs-social-login";
import { TextInput, Button, Label } from "flowbite-react";
import { lmcInputClass } from "./Index";
// import { HiArrowLeft } from "react-icons/hi";
import LmcUserStartPage from "./LmcUserStartPage";
import { useTranslation } from "react-i18next";
import { LanguageChanger } from "../dashboard/Layout";
import { HiEye, HiEyeOff } from "react-icons/hi";

const LmcUserViewLogin = (props: any) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { processId } = useParams();
  const isAuthenticated = useIsAuthenticate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showLoginBtn, setShowLoginBtn] = useState(false);
  const [startPage, setStartPage] = useState(true);
  const {
    state: {
      entity: { entity_data },
    },
    dispatch,
  } = useLmcUserView();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ user_email: string; user_password: string }>();

  const LoginLmcUserMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.post(
        `/auth/${entity_data?.entity_identification}/login`,
        data
      )
  );

  const socialAuthLoginMutation = useMutation<
    any,
    AxiosError<any, any>,
    { type: "login"; providerPayload: any }
  >((data) =>
    HttpService.post(
      `/auth/${entity_data?.entity_identification}/social/login`,
      data
    )
  );

  const [showConfirmEmail, setShowConfirmEmail] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState({
    user_email: "",
    user_identification: "",
    user_password: "",
  });

  const handleLoginStartSocialAuth = () => {};

  const handleResolveSocialAuth = (
    provider: IResolveParams["provider"],
    data: IResolveParams["data"]
  ) => {
    const cpPayload = {
      access_token:
        provider === "google"
          ? data?.access_token
          : data?.authorization?.id_token,
      provider,
    };

    socialAuthLoginMutation.mutate(
      { type: "login", providerPayload: cpPayload },
      {
        onSuccess(data, variables, context) {
          dispatch({
            type: LmcActionKind.LOGIN,
            payload: data.data.element,
          });
        },
        onError(error, variables, context) {
          const code = error.response?.data?.code;

          if (code && code === "RSUSKO026")
            navigate(`/process/${processId}/register`, { replace: true });

          toast.error(error.response?.data.message || error.message);
        },
      }
    );
  };

  const handleRejectSocialAuth = (err: any) => {
    if (err.err.error !== "popup_closed_by_user")
      toast.error(t("lmcUser.key15"));
  };

  const onSubmit = (data: { user_email: string; user_password: string }) => {
    setShowConfirmEmail(false);

    LoginLmcUserMutation.mutate(data, {
      onSuccess({ data }, variables, context) {
        dispatch({
          type: LmcActionKind.LOGIN,
          payload: { ...data.element, password: variables.user_password },
        });
      },
      onError(error: any, variables, context) {
        const code = error.response?.data?.code;

        if (code && code === "RSUSKO015")
          navigate(`/process/${processId}/register`, {
            replace: true,
            state: {
              user_email: data.user_email,
              user_password: data.user_password,
            },
          });
        else if (code && code === "RSUSOK006") {
          setUserInfo({
            user_email: data.user_email,
            user_identification: error.response.data.element.userIdentification,
            user_password: data.user_password,
          });

          setShowConfirmEmail(true);
        }

        toast.error(
          code && code === "RSUSKO029"
            ? t("lmcUser.key61")
            : error.response?.data.message || error.message
        );
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated)
      navigate(`/process/${processId}/dashboard`, { replace: true });
  }, [isAuthenticated, navigate, processId]);

  return (
    <div className="w-full md:w-[400px] mx-auto bg-gray-900 flex flex-col justify-start items-center min-h-screen relative">
      <header className="w-full h-[268px] px-4 pt-5 pb-8 bg-gray-800 rounded-[32px] rounded-tr-[32px]">
        <div className="flex justify-end">
          <LanguageChanger i18n={i18n} className="bg-transparent" />
        </div>

        <div className="flex-col w-full justify-center items-center inline-flex">
          <div className="w-16 h-16 overflow-hidden">
            <img
              src={entity_data?.entity_logo}
              alt={entity_data?.entity_name}
              className="w-16 h-16 object-cover"
            />
          </div>
          <h1 className="mt-4 text-center text-gray-200 text-2xl font-bold font-['Figtree']">
            {t("lmcUser.key16")} {entity_data?.entity_name}
          </h1>
          <p className="mt-2 text-center text-gray-200 text-base font-normal font-['Figtree'] leading-normal">
            {t("lmcUser.key17")}
          </p>
        </div>
      </header>

      <main className="w-full p-4 flex flex-col justify-start items-center gap-4">
        <div className="w-full flex-col justify-between items-center flex">
          <SocialAuth
            className="w-full"
            providers={["Google", "Apple"]}
            onLoginStartSocialAuthStart={handleLoginStartSocialAuth}
            onResolveSocialAuth={handleResolveSocialAuth}
            onRejectSocialAuth={handleRejectSocialAuth}
            redirectUrl={{
              Apple: `${process.env.REACT_APP_APPLE_CALLBACK}`,
            }}
            providersIcon={{
              Apple: (
                <div className="bg-gray-700 rounded-[100px] px-[10px] w-full h-11 flex flex-row justify-center items-center cursor-pointer relative mb-4">
                  <img
                    src="../../images/apple.svg"
                    alt="Sign up with Google"
                    className="w-6 h-6 absolute top-[10px] left-[10px]"
                  />
                  <p className="text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("lmcUser.key18")}
                  </p>
                </div>
              ),
              Google: (
                <div className="bg-gray-700 rounded-[100px] px-[10px] w-full h-11 flex flex-row justify-center items-center cursor-pointer relative">
                  <img
                    src="../../images/google.svg"
                    alt="Sign up with Google"
                    className="w-6 h-6 absolute top-[10px] left-[10px]"
                  />
                  <p className="text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("lmcUser.key19")}
                  </p>
                </div>
              ),
            }}
          />
        </div>

        <div className="my-4">
          <p className="text-center text-gray-200 text-xs font-bold font-['Figtree'] uppercase leading-none">
            {t("lmcUser.key20")}
          </p>
        </div>

        {showLoginBtn ? (
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="w-full bg-gray-900">
              <Label
                htmlFor="user_email"
                className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("lmcUser.key21")}
              </Label>
              <TextInput
                id="user_email"
                type="email"
                className={lmcInputClass}
                placeholder="Email"
                color={!!errors.user_email ? "failure" : undefined}
                {...register("user_email", {
                  required: true,
                  pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                })}
                helperText={
                  errors.user_email?.type === "required"
                    ? t("lmcUser.key22")
                    : errors.user_email?.type === "pattern"
                    ? t("lmcUser.key23")
                    : ""
                }
              />
            </div>

            <div className="w-full bg-gray-900 my-4">
              <Label
                htmlFor="user_password"
                className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("lmcUser.key50")}
              </Label>
              <div className="relative">
                <TextInput
                  id="user_password"
                  type={showPassword ? "text" : "password"}
                  className={lmcInputClass}
                  placeholder={t("lmcUser.key51").toString()}
                  color={!!errors.user_password ? "failure" : undefined}
                  {...register("user_password", {
                    required: true,
                    pattern: /^[^\s]{8,32}$/,
                  })}
                  helperText={
                    errors.user_password?.type === "required"
                      ? "Password is required"
                      : errors.user_password?.type === "pattern"
                      ? "Minimum 8 characters and maximum 32 characters, all characters (including special characters)"
                      : ""
                  }
                />
                <button
                  className="w-5 h-5 absolute right-2 top-3"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword ? (
                    <HiEye color="#9CA3AF" size={20} />
                  ) : (
                    <HiEyeOff color="#9CA3AF" size={20} />
                  )}
                </button>
              </div>
            </div>

            <div className="flex justify-end mb-4">
              <Link
                to={`/process/${processId}/forgot-password`}
                className="text-right text-sky-400 text-base font-normal font-['Figtree'] leading-normal"
              >
                {t("lmcUser.key52")}
              </Link>
            </div>

            <div className="w-full">
              <Button
                // size="lg"
                className="w-full h-11 bg-sky-400 rounded-[100px] text-neutral-900"
                type="submit"
                disabled={LoginLmcUserMutation.isLoading}
                isProcessing={LoginLmcUserMutation.isLoading}
              >
                {t("lmcUser.key24")}
              </Button>
            </div>
          </form>
        ) : (
          <Button
            className="w-full h-11 bg-sky-400 rounded-[100px] text-neutral-900"
            disabled={LoginLmcUserMutation.isLoading}
            isProcessing={LoginLmcUserMutation.isLoading}
            onClick={() => setShowLoginBtn(true)}
          >
            {t("lmcUser.key25")}
          </Button>
        )}
      </main>

      <LmcVerifyEmail
        userEmail={userInfo.user_email}
        userPassword={userInfo.user_password}
        userId={userInfo.user_identification}
        showConfirmEmailModal={showConfirmEmail}
        handleLogin={onSubmit}
        isLoading={LoginLmcUserMutation.isLoading}
      />

      {startPage && <LmcUserStartPage setStartPage={setStartPage} />}
    </div>
  );
};

export default LmcUserViewLogin;
