import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DlSearch = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const handleCheckIfHitEnter = (key: string) => {
    if (key === "Enter") handleGoToSearch();
  };

  const handleGoToSearch = () => {
    let searchQuery = query;
    setQuery("");
    if (searchQuery && (searchQuery !== "" || searchQuery !== undefined))
      navigate("search", {
        state: {
          query,
        },
      });
  };

  return (
    <div className="grow shrink basis-0 self-stretch pl-px pr-[13px] py-[9px] justify-start items-center gap-3 flex">
      <button className="w-5 h-5 relative" onClick={handleGoToSearch}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
            fill="#9CA3AF"
          />
        </svg>
      </button>
      <input
        type="search"
        className="grow shrink basis-0 border-none px-0 focus:ring-0 text-gray-500 text-sm font-normal font-['Figtree'] leading-tight"
        placeholder={t("dashboard.dlSearch.placeholder").toString()}
        value={query}
        onChange={(e) => handleSearch(e.target.value)}
        onKeyDown={(e) => handleCheckIfHitEnter(e.key)}
      />
    </div>
  );
};

export default DlSearch;
