import React from "react";
import ProfileAvatar from "./ProfileAvatar";
import HttpService from "../../../../services/http";
import useAuth from "../../../../hooks/useAuth";
import { useQuery } from "react-query";
import ProfileForm from "./ProfileForm";
import { useTranslation } from "react-i18next";

const ProfileBasicInfo = () => {
  const { t } = useTranslation();
  const { customer_identification, user } = useAuth();

  const profileQuery = useQuery("profile", () =>
    HttpService.get(
      `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/profile`,
      {
        auth: HttpService.getToken(),
      }
    )
  );

  let content;

  if (profileQuery.isLoading) content = <p>Loading...</p>;

  if (profileQuery.isError) content = <p>Failed to load data.</p>;

  if (profileQuery.isSuccess) {
    const profileData = profileQuery.data.data.element;

    content = (
      <React.Fragment>
        {/* Profile Avatar */}
        <ProfileAvatar
          avatar={profileData.user_avatar}
          entity_identification={user.entityIdentification}
          customerId={profileData.user_identification}
        />

        {/* Profile Infos */}
        <ProfileForm
          data={profileData}
          entity_identification={user.entityIdentification}
          customerId={customer_identification}
        />
      </React.Fragment>
    );
  }

  return (
    <div className="p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
      <div className="self-stretch h-14 flex-col justify-start items-start gap-2 flex">
        <div className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.profile.key2")}
        </div>
        <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.profile.key3")}
        </div>
      </div>

      {content}
    </div>
  );
};

export default ProfileBasicInfo;
