import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./i18n/locales/en/translations.json";
import fr from "./i18n/locales/fr/translations.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      "en-US": {
        translation: en,
      },
      "en-EU": {
        translation: en,
      },
      "en-CH": {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      "fr-FR": {
        translation: fr,
      },
      "fr-CH": {
        translation: fr,
      },
    },
    fallbackLng: "en-US",
    debug: false,
    lng: "en-US",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["cookie", "localStorage", "navigator"],
      caches: ["cookie"],
      lookupCookie: "NEXT_LOCALE",
      // convertDetectedLanguage(lng) {
      //   const lngMapperReverse: { [key: string]: string } = {
      //     en: "en",
      //     "en-US": "en",
      //     "en-CH": "en",
      //     "en-EU": "en",
      //     fr: "fr",
      //     "fr-CH": "fr",
      //     "fr-FR": "fr",
      //   };

      //   return lngMapperReverse[lng] || lng;
      // },
      cookieOptions: {
        domain:
          window.location.hostname.split(".").length > 2
            ? "." +
              window.location.hostname
                .split(".")
                .slice(
                  window.location.hostname.split(".").length - 2,
                  window.location.hostname.split(".").length
                )
                .join(".")
            : "." + window.location.hostname,
        secure: true,
        sameSite: "none",
        maxAge: 60 * 60 * 24 * 365, // 1 year
      },
    },
    react: {
      useSuspense: false, // Optional: disable suspense for immediate rendering
    },
    // cookies: {
    //   // Configuration for your language cookie
    //   store: "cookie",
    //   key: "NEXT_LOCALE", // Your desired cookie key
    //   // expirationTime: 30 * 24 * 60 * 60, // Expires in 30 days (adjust as needed)
    //   path: "/", // Cookie path (accessible throughout website)
    //   secure: true,
    //   sameSite: "none",
    // },
  });

export default i18n;
