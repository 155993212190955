import { useState } from "react";
import LmcInPageHeader from "./components/LmcInPageHeader";
import { useForm } from "react-hook-form";
import { useLmcUserView } from "./hooks/useLmcUserView";
// import { useQuery } from 'react-query';
// import HttpService from '../../../services/http';
// import Divider from '../../public/Divider';
import moment from "moment";
import { Label, TextInput } from "flowbite-react";
import { lmcInputClass } from "./Index";

type ProfileType = {
  user_user_name?: string | null;
  user_first_name?: string | null;
  user_last_name?: string | null;
  user_email: string | null;
  user_birth_date?: string | null;
  user_country_code?: string | null;
};

const LmcUserViewProfile = () => {
  const {
    state: { auth },
  } = useLmcUserView();

  const [
    isInEditMode,
    // setIsInEditMode
  ] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileType>({
    defaultValues: {
      user_user_name: auth.profile?.user_name,
      user_first_name: auth.profile?.first_name,
      user_last_name: auth.profile?.last_name,
      user_birth_date: moment(auth.profile?.birth_date).format("YYYY-MM-DD"),
      user_email: auth.profile?.email,
    },
  });

  const [toggleDateType, setToggleDateType] = useState<"text" | "date">("text");

  const onSubmit = (data: any) => {
    // console.log(data);
  };

  return (
    <div className="lmc-uv-profile-page">
      <LmcInPageHeader
        pageTitle="Customer information"
        pageInfoDetails={{
          title: "Customer information",
          description: "Check out the details of your rewards program here.",
        }}
      />

      <div>
        <div className="content">
          <div className={`form-area ${isInEditMode ? "edit" : ""}`}>
            <div className="title-area">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.8776 14.7502C22.8776 13.2607 21.6588 12.042 20.1693 12.042C18.6797 12.042 17.461 13.2607 17.461 14.7502C17.461 16.2398 18.6797 17.4585 20.1693 17.4585C21.6588 17.4585 22.8776 16.2398 22.8776 14.7502ZM28.2941 26.9376C28.0233 25.9761 23.8255 24.2293 20.1693 24.2293C16.5131 24.2293 12.3152 25.9761 12.0444 26.9511V28.2917H28.2941V26.9376ZM14.7527 14.7502C14.7527 11.7576 17.1766 9.33366 20.1693 9.33366C23.1619 9.33366 25.5859 11.7576 25.5859 14.7502C25.5859 17.7429 23.1619 20.1668 20.1693 20.1668C17.1766 20.1668 14.7527 17.7429 14.7527 14.7502ZM9.3361 26.9376C9.3361 23.3355 16.5537 21.521 20.1693 21.521C23.7848 21.521 31.0024 23.3355 31.0024 26.9376V31H9.3361V26.9376Z"
                  fill="white"
                />
              </svg>
              <span className="text-white mt-5 block">
                Customer information{" "}
              </span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <Label htmlFor="user_email">Email address</Label>
                <TextInput
                  className={lmcInputClass}
                  type="email"
                  // placeholder={isInEditMode ? 'Email' : ''}
                  color={!!errors.user_email ? "failure" : ""}
                  readOnly
                  helperText={<>{errors.user_email?.message}</>}
                  {...register("user_email")}
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="user_first_name">First name</Label>
                <TextInput
                  className={lmcInputClass}
                  type="text"
                  placeholder={isInEditMode ? "First name" : ""}
                  color={!!errors.user_first_name ? "failure" : ""}
                  readOnly={!isInEditMode}
                  helperText={<>{errors.user_first_name?.message}</>}
                  {...register("user_first_name")}
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="user_last_name">Last name</Label>
                <TextInput
                  className={lmcInputClass}
                  type="text"
                  placeholder={isInEditMode ? "Last name" : ""}
                  color={!!errors.user_last_name ? "failure" : ""}
                  readOnly={!isInEditMode}
                  helperText={<>{errors.user_last_name?.message}</>}
                  {...register("user_last_name")}
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="user_user_name">Username</Label>
                <TextInput
                  className={lmcInputClass}
                  type="text"
                  placeholder={isInEditMode ? "Username" : ""}
                  color={!!errors.user_user_name ? "failure" : ""}
                  readOnly={!isInEditMode}
                  helperText={<>{errors.user_user_name?.message}</>}
                  {...register("user_user_name")}
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="user_birth_date">Birth date</Label>
                <TextInput
                  className={lmcInputClass}
                  type={toggleDateType}
                  placeholder={isInEditMode ? "Birth date" : ""}
                  color={!!errors.user_birth_date ? "failure" : ""}
                  onFocus={() => setToggleDateType("date")}
                  readOnly={!isInEditMode}
                  helperText={<>{errors.user_birth_date?.message}</>}
                  {...register("user_birth_date", {
                    onBlur(event) {
                      setToggleDateType("text");
                    },
                  })}
                  max={
                    toggleDateType === "date"
                      ? moment().format("YYYY-MM-DD")
                      : undefined
                  }
                />
              </div>

              {/* <div className="text-end mt-4">
                <Button
                  variant=""
                  className="rounded-pill bg-white"
                  type="button"
                  onClick={() => setIsInEditMode(!isInEditMode)}
                >
                  {isInEditMode ? (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                        fill="#119AFC"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.545 4.765L9.235 5.455L2.44 12.25H1.75V11.56L8.545 4.765ZM11.245 0.25C11.0575 0.25 10.8625 0.325 10.72 0.4675L9.3475 1.84L12.16 4.6525L13.5325 3.28C13.825 2.9875 13.825 2.515 13.5325 2.2225L11.7775 0.4675C11.6275 0.3175 11.44 0.25 11.245 0.25ZM8.545 2.6425L0.25 10.9375V13.75H3.0625L11.3575 5.455L8.545 2.6425Z"
                        fill="#119AFC"
                      />
                    </svg>
                  )}
                  <span className="ms-2">
                    {isInEditMode ? "Cancel" : "Edit"}
                  </span>
                </Button>
                {isInEditMode && (
                  <Button
                    variant=""
                    className="rounded-pill bg-white ms-3"
                    type="submit"
                  >
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.75078 8.1502L1.60078 5.0002L0.550781 6.05019L4.75078 10.2502L13.7508 1.2502L12.7008 0.200195L4.75078 8.1502Z"
                        fill="#119AFC"
                      />
                    </svg>

                    <span className="ms-2">Done</span>
                  </Button>
                )}
              </div> */}
            </form>
          </div>

          <div className="history-area mt-6">
            <div className="title-area">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM14.3476 25.8711C15.9126 27.4587 17.8163 28.2525 20.0585 28.2525C21.1872 28.2525 22.2481 28.0381 23.2413 27.6092C24.2345 27.1803 25.1036 26.5972 25.8485 25.8598C26.5934 25.1224 27.1803 24.2571 27.6092 23.2639C28.0381 22.2707 28.2525 21.2098 28.2525 20.0811C28.2525 18.9525 28.0381 17.8991 27.6092 16.9209C27.1803 15.9427 26.5934 15.0887 25.8485 14.3589C25.1036 13.629 24.2345 13.0534 23.2413 12.632C22.2481 12.2107 21.1872 12 20.0585 12C18.8546 12 17.7147 12.2634 16.6387 12.7901C15.5628 13.3168 14.6335 14.0391 13.851 14.9571V12.5643H12.4966V17.2595H17.2143V15.9051H14.8442C15.5063 15.1376 16.2851 14.5206 17.1805 14.0541C18.0759 13.5876 19.0352 13.3544 20.0585 13.3544C21.9396 13.3544 23.5498 13.9977 24.8891 15.2844C26.2285 16.571 26.8981 18.1474 26.8981 20.0134C26.8981 21.9246 26.236 23.5498 24.9117 24.8891C23.5874 26.2285 21.9697 26.8981 20.0585 26.8981C18.1775 26.8981 16.5898 26.236 15.2956 24.9117C14.0015 23.5874 13.3544 21.9848 13.3544 20.1037H12C12 22.361 12.7825 24.2834 14.3476 25.8711ZM19.4716 20.3746L22.9479 23.8056L23.9185 22.835L20.826 19.8102V15.5439H19.4716V20.3746Z"
                  fill="white"
                />
              </svg>
              <span className="block my-4 text-white">Customer history </span>
            </div>
            <div className="infos">
              <ul className="list-unstyled p-0">
                {/* <li className="mb-3">
                  <h6 className="fw-normal">Creation date:</h6>
                  <b className="mb-0">
                    {moment(auth.userLastConnection).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </b>
                </li> */}
                <li className="text-white">
                  <h6 className="fw-normal">Last connection:</h6>
                  <b className="mb-0">
                    {moment(
                      auth.userLastConnection || auth.profile.last_connection
                    ).format("YYYY-MM-DD HH:mm:ss")}
                  </b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LmcUserViewProfile;
