import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import HttpService from "../../../services/http";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React from "react";
import { Button, Spinner } from "flowbite-react";
import { HiCheck, HiX } from "react-icons/hi";

const EntityCheck = ({
  entity_identification,
}: {
  entity_identification: string;
}) => {
  const [t] = useTranslation();
  const { customer_identification } = useAuth();

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    "entity-check",
    () =>
      HttpService.get(
        `/${customer_identification}/${entity_identification}/entity/check`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  let content;
  if (isLoading) content = <Spinner size="sm" />;

  if (isError)
    content = (
      <Button color="light" onClick={() => refetch()} size="sm">
        {t("dashboard.entityCheck.btn-01")}
      </Button>
    );

  if (isSuccess)
    content = (
      <React.Fragment>
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <div className="w-6 h-6 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 16L8.58579 11.4142C9.36683 10.6332 10.6332 10.6332 11.4142 11.4142L16 16M14 14L15.5858 12.4142C16.3668 11.6332 17.6332 11.6332 18.4142 12.4142L20 14M14 8H14.01M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                  stroke="#9CA3AF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.entityCheck.text-01")}
            </div>
          </div>
          {res?.data?.element?.cdn_logo_project_path &&
          res?.data?.element?.cdn_logo_project_path === "OK" ? (
            <div className="w-4 h-5 justify-center items-center flex">
              <div className="w-4 h-4 relative bg-emerald-400 rounded border border-sky-500 flex flex-col items-center justify-center">
                <HiCheck color="white" />
              </div>
            </div>
          ) : (
            <div className="justify-end items-center gap-5 flex">
              <Link
                to={"/control-panel/develop"}
                state={{ section: "linksConfig" }}
                className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
              >
                {t("dashboard.entityCheck.key1")}
              </Link>
              <div className="w-4 h-5 justify-center items-center flex">
                <div className="w-4 h-4 relative bg-gray-300 rounded border border-gray-300 flex items-center justify-center">
                  <HiX color="white" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="self-stretch justify-between items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <div className="w-6 h-6 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 19V10.0704C3 9.40165 3.3342 8.77718 3.8906 8.40625L10.8906 3.73959C11.5624 3.29172 12.4376 3.29172 13.1094 3.73959L20.1094 8.40625C20.6658 8.77718 21 9.40165 21 10.0704V19M3 19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19M3 19L9.75 14.5M21 19L14.25 14.5M3 9.99999L9.75 14.5M21 9.99999L14.25 14.5M14.25 14.5L13.1094 15.2604C12.4376 15.7083 11.5624 15.7083 10.8906 15.2604L9.75 14.5"
                  stroke="#9CA3AF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.entityCheck.text-02")}
            </div>
          </div>
          {res?.data?.element?.email_address_sender &&
          res?.data?.element?.email_address_sender === "OK" ? (
            <div className="w-4 h-5 justify-center items-center flex">
              <div className="w-4 h-4 relative bg-emerald-400 rounded border border-sky-500 flex flex-col items-center justify-center">
                <HiCheck color="white" />
              </div>
            </div>
          ) : (
            <div className="justify-end items-center gap-5 flex">
              <Link
                to={"/control-panel/develop"}
                state={{ section: "linksConfig" }}
                className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
              >
                {t("dashboard.entityCheck.key1")}
              </Link>
              <div className="w-4 h-5 justify-center items-center flex">
                <div className="w-4 h-4 relative bg-gray-300 rounded border border-gray-300 flex items-center justify-center">
                  <HiX color="white" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="self-stretch justify-between items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <div className="w-6 h-6 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15 7C16.1046 7 17 7.89543 17 9M21 9C21 12.3137 18.3137 15 15 15C14.3938 15 13.8087 14.9101 13.2571 14.7429L11 17H9V19H7V21H4C3.44772 21 3 20.5523 3 20V17.4142C3 17.149 3.10536 16.8946 3.29289 16.7071L9.25707 10.7429C9.08989 10.1914 9 9.60617 9 9C9 5.68629 11.6863 3 15 3C18.3137 3 21 5.68629 21 9Z"
                  stroke="#9CA3AF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.entityCheck.text-03")}
            </div>
          </div>
          {res?.data?.element?.web_site_reset_password_page &&
          res?.data?.element?.web_site_reset_password_page === "OK" ? (
            <div className="w-4 h-5 justify-center items-center flex">
              <div className="w-4 h-4 relative bg-emerald-400 rounded border border-sky-500 flex flex-col items-center justify-center">
                <HiCheck color="white" />
              </div>
            </div>
          ) : (
            <div className="justify-end items-center gap-5 flex">
              <Link
                to={"/control-panel/develop"}
                state={{ section: "linksConfig" }}
                className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
              >
                {t("dashboard.entityCheck.key1")}
              </Link>
              <div className="w-4 h-5 justify-center items-center flex">
                <div className="w-4 h-4 relative bg-gray-300 rounded border border-gray-300 flex items-center justify-center">
                  <HiX color="white" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="self-stretch justify-between items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <div className="w-6 h-6 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 16L7 12M7 12L11 8M7 12L21 12M16 16V17C16 18.6569 14.6569 20 13 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H13C14.6569 4 16 5.34315 16 7V8"
                  stroke="#9CA3AF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.entityCheck.text-04")}
            </div>
          </div>
          {res?.data?.element?.web_site_login_page &&
          res?.data?.element?.web_site_login_page === "OK" ? (
            <div className="w-4 h-5 justify-center items-center flex">
              <div className="w-4 h-4 relative bg-emerald-400 rounded border border-sky-500 flex flex-col items-center justify-center">
                <HiCheck color="white" />
              </div>
            </div>
          ) : (
            <div className="justify-end items-center gap-5 flex">
              <Link
                to={"/control-panel/develop"}
                state={{ section: "linksConfig" }}
                className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
              >
                {t("dashboard.entityCheck.key1")}
              </Link>
              <div className="w-4 h-5 justify-center items-center flex">
                <div className="w-4 h-4 relative bg-gray-300 rounded border border-gray-300 flex items-center justify-center">
                  <HiX color="white" />
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch text-gray-700 text-2xl font-bold font-['Figtree'] leading-none">
        {t("dashboard.entityCheck.title")}
      </div>
      <div className="self-stretch flex-col justify-center items-start gap-5 flex">
        {content}
      </div>
    </div>
  );
};

export default EntityCheck;
