import React, { Component, ErrorInfo, ReactNode } from "react";
import HttpService from "../../services/http";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    try {
      if (process.env.NODE_ENV === "production")
        await HttpService.post("/ui/bug-report", {
          error: { error, errorInfo },
        });
      console.error("Uncaught error:", error, errorInfo);
    } catch (err: any) {
      console.error("Ui report error:", err.response?.data?.message);
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div className="text-center">
            <img
              title="Super User Manager"
              loading="lazy"
              src="https://cdn.1cdn.app/application/UMD/23030509171543_UMD_Error500_2.svg"
              alt="CDN"
              className="img-fluid my-3 d-block mx-auto"
              width={400}
              height={400}
            />
            <h2 className="text-center">
              Something bad has happened, but the situation is under control!
            </h2>
            <p>
              we're not exactly sure what happened, but our engineers will check
              the cause soon.
            </p>
            <a href="/" className="btn btn-lg btn-outline-primary rounded-pill">
              Go home
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
