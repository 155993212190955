import { useEffect, useState } from "react"
import useAuth from "../../../../hooks/useAuth"
import UpdateEntity from "../entities/UpdateEntity"
import ServiceAvatarUpdate from "./ServiceAvatarUpdate"
import { useTranslation } from "react-i18next";
const EntityPreferencesPreview = ({ entityPreferencesData, entityData }: { entityPreferencesData: any, entityData: any }) => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const [entityDataValues, setEntityDataValues] = useState({
    entity_name: '',
    entity_description: ''
  })

  useEffect(() => {
    setEntityDataValues({
      entity_name: entityData?.entity_name,
      entity_description: entityData?.entity_description,
    })
  }, [entityData])


  return (
    <div className="w-full flex flex-col justify-start items-start gap-5">
      <div>
        <h3 className="text-gray-700 text-lg font-semibold font-['Figtree']">{t("dashboard.develop.entityPreferencesPreview.t-01")}</h3>
        <p className="mt-2 text-gray-700 text-sm font-normal font-['Figtree']">{t("dashboard.develop.entityPreferencesPreview.des-01")}</p>
      </div>

      <ServiceAvatarUpdate
            data={entityPreferencesData}
            user_identification={customer_identification}
            entity_identification={user.entityIdentification}
            onChangeNewLogo={() => { return null }}
          />

      <UpdateEntity data={entityDataValues}/>

    </div>
  )
}

export default EntityPreferencesPreview