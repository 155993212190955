import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import CustomPagination from "./CustomPagination";

interface Props {
  pageCount: number;
  currentPage: number;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  hrefBuilder?: (
    pageIndex: number,
    pageCount: number,
    selectedPage: number
  ) => void;
  onPageChange: (page: number) => void;
}

const Pagination = ({
  pageCount,
  pageRangeDisplayed,
  onPageChange,
  currentPage,
}: Props) => {
  const handlePageChange = (selectedItem: { selected: number }) => {
    onPageChange(selectedItem.selected);
  };

  return (
    <CustomPagination
      previousLabel={<FaChevronLeft />}
      nextLabel={<FaChevronRight />}
      pageCount={pageCount}
      onPageChange={handlePageChange}
      pageRangeDisplayed={pageRangeDisplayed || 2}
      breakLabel={"..."}
      pageLinkClassName=""
      previousClassName=""
      nextClassName=""
      disabledClassName={
        "cursor-not-allowed disabled-pagination bg-gray-100 text-gray-500"
      }
      breakClassName={"break-me"}
      containerClassName={"react-paginate"}
      activeClassName={""}
      forcePage={currentPage}
    />
  );
};

export default Pagination;
