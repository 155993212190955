import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { Link, useNavigate } from "react-router-dom";
import HttpService from "../../services/http";
import useAuth from "../../hooks/useAuth";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";

const schema = Joi.object({
  user_email: Joi.string().trim().required().label("Email"),
}).required();

export const Timer = ({
  setShowTimer,
  ttl,
}: {
  setShowTimer: (bool: boolean) => void;
  ttl: number;
}) => {
  const [remain, setRemain] = useState(ttl);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemain(remain - 1);

      if (remain <= 0) setShowTimer(false);
    }, 1000);

    return () => clearInterval(interval);
  }, [remain, setShowTimer, ttl]);

  return <span>{remain} seconds</span>;
};

const ForgotPassword = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  const from = "/control-panel";

  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user.isAuthenticated) navigate(from);

    return () => {
      cleaner = false;
    };
  }, [auth, from, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const ForgotPasswordMutation = useMutation<
    void,
    AxiosError<any, any>,
    string,
    any
  >(
    (user_email) =>
      HttpService.get(
        `/auth/reset-link?email_address=${encodeURIComponent(user_email)}`
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setShowTimer(false);
      },
    }
  );

  const onSubmit = (data: any) => {
    ForgotPasswordMutation.mutate(data.user_email, {
      onSuccess: (res) => {
        toast.success(t("forgotPassword.msg1"));

        setShowTimer(true);
      },
    });
  };

  return (
    <div className="auth-page min-vh-100 d-flex align-items-center">
      <Helmet>
        <title>Forgot password | Super User Manager</title>
        <meta
          name="description"
          content="Super User Manager - Forgot password"
        />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/forgot-password`}
        />
      </Helmet>

      <div className="flex-col justify-start items-center gap-8 inline-flex">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="self-stretch flex-col justify-start items-center gap-6 flex mb-8">
            <div className="flex-col justify-start items-center gap-2 flex">
              <div className="text-center text-gray-700 text-3xl font-bold font-['Figtree'] leading-9">
                {t("forgotPassword.title")}
              </div>
              <div className="text-gray-700 text-sm text-center font-normal font-['Figtree'] leading-tight">
                {t("forgotPassword.loginNote")}{" "}
              </div>
            </div>
          </div>

          <div className="self-stretch flex-col justify-start items-start gap-4 flex">
            <div
              className={`self-stretch bg-white rounded-md border ${
                Object.keys(errors).length > 0
                  ? "border-red-500"
                  : "border-gray-300"
              } flex-col justify-start items-start inline-flex`}
            >
              <div className="self-stretch px-[13px] py-[9px] bg-white rounded-md shadow justify-start items-center inline-flex">
                <input
                  type="email"
                  placeholder={t("forgotPassword.label1").toString()}
                  className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
                  {...register("user_email")}
                />
              </div>
            </div>

            {Object.keys(errors).length > 0 && (
              <div>
                {Object.keys(errors).map((key) => (
                  <p key={key} className="text-red-500 text-sm mb-0">
                    {errors[key]?.message?.toString()}
                  </p>
                ))}
              </div>
            )}

            <Button
              type="submit"
              className="w-full bg-sky-500 font-['Figtree']"
              disabled={ForgotPasswordMutation.isLoading || showTimer}
              isProcessing={ForgotPasswordMutation.isLoading}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              size={"sm"}
            >
              {showTimer ? (
                <Timer setShowTimer={setShowTimer} ttl={120} />
              ) : (
                t("forgotPassword.resetBtn")
              )}
            </Button>
          </div>
        </form>

        <div className="justify-start items-center gap-1 inline-flex">
          <div className="text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
            {t("resetPassword.loginNote")}
          </div>
          <div className="justify-start items-center flex">
            <Link
              to={"/login"}
              className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
            >
              {t("resetPassword.loginBtn")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
