import React from "react";
import { AuthContextType, StorageContextType } from "./types";

let AuthContext = React.createContext<AuthContextType>(null!);
let StorageContext = React.createContext<StorageContextType>(null!);

const Contexts = {
  AuthContext,
  StorageContext
};

export default Contexts;
