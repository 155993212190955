import { Button, Label, TextInput } from "flowbite-react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";

const schema = Joi.object({
  callback_url: Joi.string()
    .uri()
    .trim()
    .required()
    .messages({
      "string.uri":
        "Callback URL must be a valid url (exp: https://example.com)",
    })
    .label("Callback URL"),
}).required();

const EnterCallbackUrlModal = ({
  showModal,
  getCallBackUrlFromCustomer,
  callbackUrlLink,
  disabled,
  isLoading,
}: {
  showModal: boolean;
  getCallBackUrlFromCustomer: (url: string) => void;
  callbackUrlLink: string | null;
  disabled: boolean;
  isLoading: boolean;
}) => {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      callback_url: callbackUrlLink || "",
    },
  });

  const onSubmit = () => {
    const value = getValues();
    getCallBackUrlFromCustomer(value?.callback_url);
  };

  useEffect(() => {
    setValue("callback_url", callbackUrlLink || "");
  }, [callbackUrlLink, setValue]);

  return (
    <div>
      <div className="flex flex-col justify-start items-start gap-2">
        <h4 className="text-gray-700 text-xl font-bold font-['Figtree'] leading-7">
          {t("dashboard.features.loginTemplate.enterCallbackUrlModal.text1")}
        </h4>
        <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.features.loginTemplate.enterCallbackUrlModal.text2")}
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="my-4">
          <Label
            htmlFor="callback_url"
            className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.loginTemplate.enterCallbackUrlModal.text3")}
          </Label>
          <TextInput
            id="callback_url"
            type=""
            placeholder=""
            {...register("callback_url")}
            color={!!errors.callback_url && showModal ? "failure" : undefined}
            disabled={disabled || !showModal}
            helperText={
              showModal ? (errors.callback_url?.message as string) : ""
            }
          />
        </div>

        <div className="w-100">
          <Button
            type="submit"
            className="bg-sky-500 ml-auto mr-0"
            // size="lg"
            disabled={disabled || !showModal || isLoading}
            isProcessing={isLoading}
          >
            {t("dashboard.features.loginTemplate.enterCallbackUrlModal.text5")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EnterCallbackUrlModal;
