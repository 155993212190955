
export const loginFormObject = {
  "login_form": {
    "username": {
      "type": "string"
    },
    "password": {
      "type": "string"
    }
  }
}