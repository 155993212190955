import React from "react";
import ErrorPagesLayout from "../components/pages/error/ErrorPagesLayout";
import { Helmet } from "react-helmet-async";

const BrokenLinkError = () => {
  return (
    <ErrorPagesLayout>
      <Helmet>
        <title>Super User Manager - Broken link</title>
        <meta name="description" content="Your page is not found" />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/not-found`}
        />
      </Helmet>
      <h1 className="audiowide">Oh no... a broken link!</h1>
      <h4 className="prompt fw-bold">This link is no longer active</h4>
      <img
        title="Super User Manager"
        loading="lazy"
        src="/images/wrongLink.svg"
        alt="Super user manager - wrong link"
        className="my-5 d-block mx-auto"
        height={400}
      />
    </ErrorPagesLayout>
  );
};

export default BrokenLinkError;
