import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { useTranslation } from "react-i18next";

const LmcUserViewDashboardLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { processId } = useParams();
  const location = useLocation();
  const {
    state: { entity },
  } = useLmcUserView();

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full md:w-[400px] mx-auto bg-gray-900 flex flex-col justify-start items-center min-h-svh relative">
      {location.pathname.includes(`/process/${processId}/dashboard`) && (
        <header className="w-full h-[68px] pl-4 pr-5 py-3.5 bg-gray-800 md:rounded-tl-[32px] md:rounded-tr-[32px] flex justify-between items-center">
          <div className="flex justify-start items-center gap-[10px]">
            <div className="w-8 h-8 overflow-hidden">
              <img
                src={entity.entity_data?.entity_logo}
                alt={entity.entity_data?.entity_name}
                className="w-8 h-8 object-cover"
              />
            </div>
            <h5
              className="text-gray-200 text-1xl font-bold font-['Figtree'] leading-loose truncate text-ellipsis md:max-w-none max-w-[200px]"
              title={entity.entity_data?.entity_name}
            >
              {entity.entity_data?.entity_name}
            </h5>
          </div>
          <div className="w-[71.5px] text-sky-300 text-xs font-bold font-['Figtree'] leading-4">
            <a
              href={`${process.env.REACT_APP_LANDING_URL}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("lmcUser.key13")}
            </a>
          </div>
        </header>
      )}

      {children}

      {location.pathname.includes(`/process/${processId}/dashboard/reward`) && (
        <div className="w-full mt-auto mb-3 p-4">
          <div
            className="w-full h-11 bg-sky-400 hover:bg-sky-300 rounded-[100px] flex-col justify-center items-center gap-2 inline-flex cursor-pointer"
            onClick={handleGoBack}
          >
            <span className="text-center text-neutral-900 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("lmcUser.key14")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LmcUserViewDashboardLayout;
