import React from "react";
import { useLmcUserView } from "../hooks/useLmcUserView";
import { Navigate, useParams } from "react-router-dom";

const LmcPrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const params = useParams();
  const { state } = useLmcUserView();

  if (!state.auth.isAuthenticated)
    return <Navigate to={`/process/${params.processId}/login`} />;

  return <>{children}</>;
};

export default LmcPrivateRoute;
