import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

import { useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import useAuth from "./hooks/useAuth";
import { can, PermissionsMapper } from "./providers/AuthProvider";
import { useTranslation } from "react-i18next";
import useScrollToTop from "./hooks/useScrollTop";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi";

import Login from "./routes/auth/Login";
import Register from "./routes/auth/Register";
import ForgotPassword from "./routes/auth/ForgotPassword";
import ResetPassword from "./routes/auth/ResetPassword";
import LevelUpPassword from "./routes/auth/LevelUpPassword";
import RequireAuth from "./components/public/RequireAuth";
import Dashboard from "./routes/Dashboard";
import Layout from "./components/pages/dashboard/Layout";
import Logout from "./routes/auth/Logout";
import Profile from "./routes/Profile";
import ViewEntityUser from "./components/pages/dashboard/entities/ViewEntityUser";
import ServerError from "./routes/ServerError";
import NotFound from "./routes/NotFound";
import ErrorPagesLayout from "./components/pages/error/ErrorPagesLayout";
import Maintenance from "./routes/Maintenance";
import Users from "./routes/Users";
import Billing from "./components/pages/dashboard/Billing";
import Develop from "./components/pages/dashboard/develop/Develop";
import DashboardNotFound from "./components/pages/dashboard/DashboardNotFound";
import AskQuestionMessage from "./components/pages/dashboard/messages/AskQuestionMessage";
import SearchPage from "./routes/Search";
import Groups from "./routes/Groups";
import RegisterViaLink from "./routes/auth/RegisterViaLink";
import DevTool from "./components/pages/dashboard/devtool/DevTool";
import LmcPage from "./components/pages/dashboard/lmc/Index";
import LmcUserViewApp from "./components/pages/lmcUserVIew/Index";
import LoginViaLink from "./components/pages/dashboard/loginTemplate/LoginViaLink";
import LmcUserViewProvider from "./components/pages/lmcUserVIew/providers/Index";
import TempPart from "./components/pages/dashboard/loginTemplate/TempPart";
import AuthLayout from "./components/pages/auth/AuthLayout";
import UserTracker from "./components/pages/dashboard/entities/UserTracker";

import "./i18n";
import { useCookies } from "react-cookie";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies(["NEXT_LOCALE"]);

  useScrollToTop();

  useEffect(() => {
    const fallBackLng = "en-US";

    if (searchParams.has("language")) {
      const urlLocale = searchParams.get("language");

      let toSetLng = ["en-US", "fr-FR", "fr-CH", "en-CH", "en", "fr"].includes(
        urlLocale as string
      )
        ? urlLocale
        : fallBackLng;

      i18n.changeLanguage(toSetLng as string);
    } else {
      const persistedLocale = cookies.NEXT_LOCALE;

      document.documentElement.lang = persistedLocale || i18n.language;

      i18n.changeLanguage(persistedLocale || i18n.language);
    }
  }, [searchParams, i18n, cookies.NEXT_LOCALE]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Navigate to={"control-panel"} />} />

          <Route
            path="user/:entity_identification/registration"
            element={<RegisterViaLink />}
          />

          {/* LMC user */}
          <Route
            path="process/:processId/*"
            element={
              <LmcUserViewProvider>
                <LmcUserViewApp />
              </LmcUserViewProvider>
            }
          />

          {/* Login to entity */}
          <Route
            path="login/:login_code"
            element={<LoginViaLink externalPage />}
          />

          {/* Auth Routes */}
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            {/* <Route path="home" element={<Home />} /> */}
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="level-up-password" element={<LevelUpPassword />} />
            <Route path="logout" element={<Logout />} />
          </Route>

          {/* Dashboard routes */}
          <Route
            path="control-panel"
            element={
              <RequireAuth>
                <Layout>
                  <Outlet />
                </Layout>
              </RequireAuth>
            }
          >
            <Route index element={<Dashboard />} />
            <Route
              path="users/:user_identification"
              element={<ViewEntityUser />}
            />
            <Route
              path="users/:user_identification/history"
              element={<UserTracker isRoute />}
            />
            <Route path="users" element={<Users />} />
            <Route path="groups" element={<Groups />} />
            <Route path="develop" element={<Develop />} />
            <Route path="profile" element={<Profile />} />
            <Route path="billing" element={<Billing />} />
            <Route path="lmc" element={<LmcPage />} />
            <Route path="login-template" element={<TempPart />} />
            {!user.isBasic && <Route path="devtool" element={<DevTool />} />}
            {user.isAuthenticated &&
              user.customer_permissions &&
              can(
                user.customer_permissions,
                PermissionsMapper.expert_support
              ) && <Route path="support" element={<AskQuestionMessage />} />}
            <Route path="search" element={<SearchPage />} />
            <Route path="*" element={<DashboardNotFound />} />
          </Route>

          {/* Error routes */}
          <Route
            element={
              <ErrorPagesLayout>
                <Outlet />
              </ErrorPagesLayout>
            }
          >
            <Route path="/server-error" element={<ServerError />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/maintenance" element={<Maintenance />} />
          </Route>

          <Route path="*" element={<Navigate to={"/not-found"} />} />
        </Routes>

        <ToastContainer
          icon={({ type }) => {
            if (type === "success")
              return (
                <HiOutlineCheckCircle className="text-emerald-600" size={50} />
              );
            if (type === "error")
              return <HiOutlineXCircle className="text-red-600" size={50} />;
            else return null;
          }}
        />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
