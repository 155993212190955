import { useTranslation } from "react-i18next";
import { AiOutlineLoading } from "react-icons/ai";
const LoadingPage = () => {
  const [t] = useTranslation();

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img
        src="https://cdn.1cdn.app/application/UMD/22103111455394_loadingsum.gif"
        alt="Super User Manager"
        width={150}
        height={150}
        title={"Super user manager"}
        className="img-fluid mb-3"
      />
      <span className="text-center flex flex-col items-center justify-center gap-2">
        {t("loadingPage.text")}
        <AiOutlineLoading className="h-6 w-6 animate-spin" />
      </span>
    </div>
  );
};

export default LoadingPage;
