import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Label, Select, TextInput } from "flowbite-react";
import CountryOptions from "../../../public/CountryOptions";
import moment from "moment";

const schema = Joi.object({
  new_user_user_name: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .optional()
    .allow(null, "")
    .label("Username"),

  new_user_first_name: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .required()
    .label("First name"),

  new_user_last_name: Joi.string()
    .trim()
    .min(3)
    .max(150)
    .optional()
    .required()
    .label("Last name"),

  new_user_birth_date: Joi.date()
    .optional()
    .allow(null, "")
    .label("Birth date"),

  new_user_country_code: Joi.string()
    .trim()
    .optional()
    .allow(null, "")
    .label("Country code"),
});

const ProfileForm = ({
  entity_identification,
  customerId,
  data,
}: {
  entity_identification: string;
  customerId: string;
  data: any;
}) => {
  const { t } = useTranslation();
  const { customer_identification } = useAuth();

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      new_user_first_name: data.user_first_name,
      new_user_last_name: data.user_last_name,
      new_user_birth_date: data.user_birth_date
        ? new Date(data.user_birth_date).toISOString().substring(0, 10)
        : "",
      new_user_country_code: data.user_country_code,
      new_user_user_name: data.user_user_name,
    },
  });

  const UpdateProfileMutation = useMutation<any, AxiosError<any, any>>(
    (dataObj: any) =>
      HttpService.patch(
        `/${customer_identification}/${entity_identification}/user/${customerId}/profile`,
        dataObj,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Profile updated");

        queryClient.invalidateQueries("profile");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        for (const [key, value] of Object.entries(err.response?.data.element))
          setError(key as any, { type: "custom", message: value as string });
      },
    }
  );

  const onSubmit = (data: any) => {
    UpdateProfileMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="grid grid-cols-12 gap-4">
        <div className="lg:col-span-4 sm:col-span-6 col-span-12">
          <div className="mb-4">
            <Label
              className="block text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3"
              htmlFor="new_user_first_name"
            >
              {t("dashboard.userProfile.profileForm.label-01")}
            </Label>
            <TextInput
              id="new_user_first_name"
              type="text"
              placeholder=""
              color={!!errors.new_user_first_name ? "failure" : ""}
              helperText={<>{errors.new_user_first_name?.message}</>}
              className="shadow rounded-md"
              {...register("new_user_first_name")}
            />
          </div>
        </div>
        <div className="lg:col-span-4 sm:col-span-6 col-span-12">
          <div className="mb-3">
            <Label
              className="block text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3"
              htmlFor="new_user_last_name"
            >
              {t("dashboard.userProfile.profileForm.label-02")}
            </Label>
            <TextInput
              id="new_user_last_name"
              type="text"
              placeholder=""
              color={!!errors.new_user_last_name ? "failure" : ""}
              helperText={<>{errors.new_user_last_name?.message}</>}
              className="shadow rounded-md"
              {...register("new_user_last_name")}
            />
          </div>
        </div>
        <div className="lg:col-span-4 sm:col-span-6 col-span-12">
          <div className="mb-3">
            <Label
              className="block text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3"
              htmlFor="new_user_user_name"
            >
              {t("dashboard.userProfile.profileForm.label-03")}
            </Label>
            <TextInput
              id="new_user_user_name"
              type="text"
              placeholder=""
              color={!!errors.new_user_user_name ? "failure" : ""}
              helperText={<>{errors.new_user_user_name?.message}</>}
              className="shadow rounded-md"
              {...register("new_user_user_name")}
            />
          </div>
        </div>
        <div className="lg:col-span-4 sm:col-span-6 col-span-12">
          <div>
            <Label
              className="block text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3"
              htmlFor="new_user_birth_date"
            >
              {t("dashboard.userProfile.profileForm.label-04")}
            </Label>
            <TextInput
              id="new_user_birth_date"
              type="date"
              placeholder=""
              color={!!errors.new_user_birth_date ? "failure" : ""}
              helperText={<>{errors.new_user_birth_date?.message}</>}
              max={moment().format("YYYY-MM-DD")}
              className="shadow rounded-md max-h-[42px]"
              {...register("new_user_birth_date")}
            />
          </div>
        </div>
        <div className="lg:col-span-4 sm:col-span-6 col-span-12">
          <div>
            <Label
              className="block text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3"
              htmlFor="new_user_country_code"
            >
              {t("dashboard.userProfile.profileForm.label-05")}
            </Label>
            <Select
              id="new_user_country_code"
              color={!!errors.new_user_country_code ? "failure" : ""}
              className="shadow rounded-md"
              {...register("new_user_country_code")}
            >
              <option value="">
                {t("dashboard.userProfile.profileForm.select-option")}
              </option>
              <CountryOptions />
            </Select>
            <p className="mt-2 text-sm text-red-500">
              {errors.new_user_country_code?.message as any}
            </p>
          </div>
        </div>
      </div>

      <div className="self-stretch h-px bg-gray-300 mt-5" />

      <div className="flex align-center justify-end mt-4">
        <Button
          type="submit"
          disabled={UpdateProfileMutation.isLoading}
          isProcessing={UpdateProfileMutation.isLoading}
          className="bg-sky-500 shadow"
        >
          {t("dashboard.userProfile.profileForm.btn-01")}
        </Button>
      </div>
    </form>
  );
};

export default ProfileForm;
