import CreateUserQrCode from "./CreateUserQrCode";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Modal } from "flowbite-react";
import { FaCopy } from "react-icons/fa6";

const AddUserByShareQrCodeModal = ({
  show,
  onHide,
  size,
  className,
  onBackClick,
}: {
  show: boolean;
  onHide: () => void;
  size?: "lg" | "sm" | "xl" | "2xl";
  className?: string | undefined;
  onBackClick: () => void;
}) => {
  const [t] = useTranslation();
  const {
    // customer_identification,
    user,
  } = useAuth();

  const code = `user/${user.entityIdentification}/registration`;

  // const [showMore, setShowMore] = useState(false);

  // const { isLoading, isError, isSuccess, refetch } = useQuery(
  //   "entity-owner-data",
  //   () =>
  //     HttpService.get(
  //       `/${customer_identification}/${user.entityIdentification}/entity/data`,
  //       {
  //         auth: HttpService.getToken(),
  //       }
  //     ),
  //   {
  //     onSuccess: (data) => {
  //       setShowMore(data.data.element.user_registration_link);
  //     },
  //     onError: (err: any) => {
  //       toast.error(err.response?.data?.message || err.message);
  //     },
  //   }
  // );

  // const UpdateShareState = useMutation<null, AxiosError<any, any>, any>(
  //   () =>
  //     HttpService.patch(
  //       `/${customer_identification}/${user.entityIdentification}/entity/update-registration-link`
  //     ),
  //   {
  //     onSuccess: (data: any) => {
  //       setShowMore(data.data.element.user_registration_link);
  //     },
  //     onError: (err) => {
  //       toast.error(err.response?.data?.message || err.message);
  //     },
  //   }
  // );

  // const handleUpdateShareCondition = () => {
  //   UpdateShareState.mutate(null);
  // };

  // let shareContent;

  // if (isLoading) shareContent = <p>LOading...</p>;

  // if (isError)
  //   shareContent = (
  //     <>
  //       <span>Failed to load data</span>
  //       <Button color="info" onClick={() => refetch()}>
  //         Reload
  //       </Button>
  //     </>
  //   );

  // if (isSuccess)
  //   shareContent = (
  //     <div className="share-condition g-3 mt-6">
  //       {UpdateShareState.isLoading ? (
  //         <p>Loading...</p>
  //       ) : (
  //         <div className="flex items-center gap-2">
  //           <label className="switch shrink-0">
  //             <input
  //               type="checkbox"
  //               onChange={handleUpdateShareCondition}
  //               checked={showMore}
  //               disabled={UpdateShareState.isLoading}
  //             />
  //             <div className="slider"></div>
  //           </label>
  //           <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
  //             {t("dashboard.addUserByShare.form-check")}
  //           </span>
  //         </div>
  //       )}
  //     </div>
  //   );

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_HOST_URL}/${code}`);

    toast.info("Destination copied!");
  };

  // const handleShareNatively = async (destination: string) => {
  //   try {
  //     const shareData = {
  //       title: "Registration",
  //       text: "Please fill out the form",
  //       url: destination,
  //     };

  //     await navigator.share(shareData);
  //     // dispatch(setToast({ content: 'Shared successfully', variant: 'success' }))
  //   } catch (err) {
  //     // dispatch(setToast({ content: `Not supported. Try in native device (android,ios,...)`, variant: 'danger' }))
  //   }
  // };

  const handleCloseModal = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onClose={handleCloseModal}
      size={size}
      className={className}
    >
      <Modal.Header className="border-b-0">
        <h3 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7 mb-2">
          {t("dashboard.addUserByShare.title")}
        </h3>
        <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.addUserByShare.text-02")}
        </p>
      </Modal.Header>

      <Modal.Body>
        <div className="flex flex-col gap-6 items-center justify-center">
          <div className="w-[291px] h-10 pl-4 pr-3 py-2.5 bg-gray-100 rounded-md justify-start items-center gap-3 inline-flex">
            <div
              title={`${process.env.REACT_APP_HOST_URL}/${code}`}
              className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight truncate ..."
            >
              {`${process.env.REACT_APP_HOST_URL}/${code}`}
            </div>
            <div className="text-gray-200 text-sm font-normal font-['Figtree'] leading-tight shrink-0">
              |
            </div>
            <div
              className="shrink-0 cursor-pointer"
              onClick={handleCopyAddress}
            >
              <FaCopy size={16} className="text-gray-400" />
            </div>
          </div>

          <CreateUserQrCode />
        </div>

        {/* {shareContent} */}
      </Modal.Body>
    </Modal>
  );
};

export default AddUserByShareQrCodeModal;
