import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useIsAuthenticate from "./hooks/useIsAuthenticate";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { LanguageChanger } from "../dashboard/Layout";
import { HiArrowLeft, HiEye, HiEyeOff } from "react-icons/hi";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../services/http";
import { toast } from "react-toastify";
import { Button, Label, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { lmcInputClass } from "./Index";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";

const LmcUserViewResetPassword = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { processId } = useParams();
  const [searchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticate();
  const {
    state: {
      entity: { entity_data },
    },
  } = useLmcUserView();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const resetPasswordMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/user/${entity_data?.entity_identification}/${searchParams.get(
          "user_identification"
        )}/${searchParams.get("token")}/password`,
        data
      ),
    {
      onSuccess: (res) => {
        toast.success(res.data.message || "Password reset successfully", {
          onClose(props) {
            navigate(`/process/${processId}/login`);
          },
        });
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const schema = Joi.object().keys({
    new_password: Joi.string()
      .pattern(new RegExp("^(?=.*\\d)(?=.*[a-zA-Z])(?=.*\\W)[^ ]{8,32}$"))
      .required()
      .messages({
        "string.pattern.base":
          "Password must be between 8 to 32 characters and contain at least one letter, one number, and one special character.",
      })
      .label(t("lmcUser.key53").toString()),
    new_confirm_password: Joi.string()
      .valid(Joi.ref("new_password"))
      .required()
      .messages({
        "any.only": "Passwords do not match",
      })
      .label(t("lmcUser.key55").toString()),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = (data: any) => {
    resetPasswordMutation.mutate(data);
  };

  useEffect(() => {
    if (isAuthenticated)
      navigate(`/process/${processId}/dashboard`, { replace: true });

    if (
      !searchParams.has("token") ||
      !searchParams.has("user_identification") ||
      (searchParams.has("token") &&
        searchParams.has("user_identification") &&
        (searchParams.get("token") === "" ||
          searchParams.get("user_identification") === ""))
    )
      navigate(`/process/${processId}/login`);
  }, [isAuthenticated, navigate, processId, searchParams]);

  return (
    <div className="w-full md:w-[400px] mx-auto bg-gray-900 flex flex-col justify-start items-center min-h-[100lvh] relative">
      <header className="w-full px-4 py-5 bg-gray-800 rounded-tl-[32px] rounded-tr-[32px] flex items-center justify-between">
        <div className="flex items-center gap-3" onClick={() => navigate(-1)}>
          <HiArrowLeft size={20} className="text-gray-400" />
          <span className="text-gray-400 text-base font-normal font-['Figtree'] leading-normal">
            {t("lmcUser.key26")}
          </span>
        </div>

        <div className="w-[226px] text-center text-zinc-200 text-xl font-semibold font-['Figtree'] leading-7">
          {t("lmcUser.key58")}
        </div>

        <LanguageChanger i18n={i18n} className="bg-transparent" />
      </header>

      <main className="w-full h-full p-4 gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full h-[calc(100lvh-100px)] flex flex-col relative"
        >
          <div className="w-full bg-gray-900 mb-4">
            <Label
              htmlFor="user_email"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key53")}
            </Label>
            <div className="relative">
              <TextInput
                id="new_password"
                type={showPassword ? "text" : "password"}
                className={lmcInputClass}
                placeholder={t("lmcUser.key54").toString()}
                color={!!errors.new_password ? "failure" : undefined}
                {...register("new_password")}
                helperText={errors.new_password?.message as string}
              />
              <button
                className="w-5 h-5 absolute right-2 top-3"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <HiEye color="#9CA3AF" size={20} />
                ) : (
                  <HiEyeOff color="#9CA3AF" size={20} />
                )}
              </button>
            </div>
          </div>

          <div className="w-full bg-gray-900 mb-4">
            <Label
              htmlFor="user_email"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key55")}
            </Label>
            <div className="relative">
              <TextInput
                id="new_confirm_password"
                type={showConfirmPassword ? "text" : "password"}
                className={lmcInputClass}
                placeholder={t("lmcUser.key54").toString()}
                color={!!errors.new_confirm_password ? "failure" : undefined}
                {...register("new_confirm_password")}
                helperText={errors.new_confirm_password?.message as string}
              />
              <button
                className="w-5 h-5 absolute right-2 top-3"
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {!showConfirmPassword ? (
                  <HiEye color="#9CA3AF" size={20} />
                ) : (
                  <HiEyeOff color="#9CA3AF" size={20} />
                )}
              </button>
            </div>
          </div>

          <div className="w-full max-w-[400px] px-4 mx-auto mt-auto fixed bottom-5 left-0 right-0">
            <Button
              // size="lg"
              className="w-full h-11 bg-sky-400 rounded-[100px] text-neutral-900"
              type="submit"
              disabled={resetPasswordMutation.isLoading}
              isProcessing={resetPasswordMutation.isLoading}
            >
              {t("lmcUser.key56")}
            </Button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default LmcUserViewResetPassword;
