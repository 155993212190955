import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import useAuth from "../hooks/useAuth";
import HttpService from "../services/http";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../hooks/useWindowSize";
import { FaCopy, FaPencil, FaPlus, FaTrash } from "react-icons/fa6";
import {
  Button,
  Label,
  Modal,
  Table,
  TableHead,
  Textarea,
  TextInput,
} from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const schema = Joi.object({
  group_name: Joi.string().trim().min(2).max(20).required().label("Group name"),
  group_description: Joi.string()
    .trim()
    .min(2)
    .max(300)
    .required()
    .label("Group description"),
});

export const Copy = ({ target }: { target: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(target);

    toast.info("Copied");

    setIsCopied(true);
  };

  useEffect(() => {
    let tF: any;

    if (isCopied) {
      tF = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(tF);
    };
  }, [isCopied]);

  return (
    <React.Fragment>
      {target}
      <button onClick={handleCopyToClipboard} className="inline-flex ml-2">
        <FaCopy color={isCopied ? "#5CAEF9" : "#9CA3AF"} />
      </button>
    </React.Fragment>
  );
};

const Groups = () => {
  const [t] = useTranslation();
  const [size] = useWindowSize();
  const { user, customer_identification, userEndedSubscription } = useAuth();

  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);
  const [toUpdateGroupId, setToUpdateGroupId] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    "groups",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/group`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const CreateGroupMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.post(
        `${customer_identification}/${user.entityIdentification}/group`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Group created successfully");
        reset();
        setShowCreateGroupModal(false);
        queryClient.invalidateQueries("groups");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const DeleteGroupMutation = useMutation<string, AxiosError<any, any>, any>(
    (groupId) =>
      HttpService.delete(
        `/${customer_identification}/${user.entityIdentification}/group/${groupId}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        setOpenModal(false);
        setToUpdateGroupId("");

        toast.success("Group deleted successfully");
        queryClient.invalidateQueries("groups");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateGroupMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/group/${data.group_identification}`,
        {
          group_name: data.group_name,
          group_description: data.group_description,
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Group updated successfully");
        setShowUpdateGroupModal(false);
        queryClient.invalidateQueries("groups");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleShowAddGroup = () => {
    reset({
      group_name: "",
      group_description: "",
    });
    setShowCreateGroupModal(true);
  };

  const handleDeleteGroup = () => {
    DeleteGroupMutation.mutate(toUpdateGroupId);
  };

  const handleUpdateGroup = (groupId: string) => {
    queryClient
      .fetchQuery(["group", groupId], () =>
        HttpService.get(
          `/${customer_identification}/${user.entityIdentification}/group/${groupId}`,
          {
            auth: HttpService.getToken(),
          }
        )
      )
      .then((res) => {
        reset(res.data.element);
        setToUpdateGroupId(groupId);
        setShowUpdateGroupModal(true);
      })
      .catch((err: AxiosError<any, any>) => {
        setToUpdateGroupId("");
        setShowUpdateGroupModal(false);
        toast.error(err.response?.data?.message || err.message);
      });
  };

  const onSubmit = (values: any) => {
    CreateGroupMutation.mutate(values);
  };

  const onSubmitUpdate = (values: any) => {
    UpdateGroupMutation.mutate({
      ...values,
      group_identification: toUpdateGroupId,
    });
  };

  let content;

  if (isLoading) content = <div className="p-4">Loading...</div>;

  if (isError)
    content = (
      <div className="p-4">
        {" "}
        <Button color="info" onClick={() => refetch()} size="sm">
          {t("dashboard.groupList.btn-01")}
        </Button>
      </div>
    );

  if (isSuccess)
    content = (
      <React.Fragment>
        <div className="overflow-x-auto">
          <Table striped>
            <TableHead>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                #
              </Table.HeadCell>
              <Table.HeadCell
                style={{ width: 350 }}
                className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5"
              >
                {size > 600 ? t("dashboard.groupList.item-01") : "ID"}
              </Table.HeadCell>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {t("dashboard.groupList.item-02")}
              </Table.HeadCell>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {/* {t("dashboard.groupList.item-03")} */}
              </Table.HeadCell>
            </TableHead>

            <Table.Body>
              {res?.data.element.groups.length > 0 ? (
                res?.data.element.groups.map((item: any, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell className="px-3 py-2.5">{index + 1}</Table.Cell>
                    <Table.Cell
                      className="px-3 py-2.5 flex items-center gap-2"
                      style={{ minWidth: size < 600 ? 80 : "auto" }}
                    >
                      <Copy target={item.group_identification} />
                    </Table.Cell>
                    <Table.Cell
                      className="px-3 py-2.5"
                      style={{ width: "47%" }}
                    >
                      {item.group_name}
                    </Table.Cell>
                    <Table.Cell
                      className="px-3 py-2.5"
                      style={{ justifyContent: "start" }}
                    >
                      <div className="flex items-center gap-1">
                        <Button
                          color={""}
                          className="p-0"
                          onClick={() =>
                            handleUpdateGroup(item.group_identification)
                          }
                          disabled={userEndedSubscription}
                        >
                          <FaPencil className="text-gray-400" />
                        </Button>
                        <Button
                          color={""}
                          className="p-0"
                          onClick={() => {
                            setToUpdateGroupId(item.group_identification);
                            setOpenModal(true);
                          }}
                          disabled={userEndedSubscription}
                        >
                          <FaTrash className="text-gray-400" />
                        </Button>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell
                    className="px-3 py-2.5"
                    colSpan={4}
                    align="center"
                  >
                    {t("dashboard.groupList.text-01")}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>

        {/* Create group modal */}
        <Modal
          show={showUpdateGroupModal}
          onClose={() => setShowUpdateGroupModal(false)}
        >
          <Modal.Header className="border-0 text-zinc-900 text-lg font-semibold font-['Figtree'] leading-7">
            {t("dashboard.groupList.key1")}
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmitUpdate)}>
              <div className="mb-6">
                <Label
                  htmlFor="group_name"
                  value={t("dashboard.groupList.label-01").toString()}
                  className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
                />
                <TextInput
                  id="group_name"
                  color={!!errors.group_name ? "failure" : ""}
                  type="text"
                  {...register("group_name")}
                  helperText={<>{errors.group_name?.message as string}</>}
                />
              </div>

              <div className="mb-6">
                <Label
                  htmlFor="group_description"
                  value={t("dashboard.groupList.label-02").toString()}
                  className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
                />
                <Textarea
                  id="group_description"
                  color={!!errors.group_description ? "failure" : ""}
                  rows={6}
                  helperText={
                    <>{errors.group_description?.message as string}</>
                  }
                  {...register("group_description")}
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  disabled={UpdateGroupMutation.isLoading}
                  isProcessing={UpdateGroupMutation.isLoading}
                  processingSpinner={
                    <>
                      <AiOutlineLoading className="h-6 w-6 animate-spin" />
                    </>
                  }
                  className="bg-sky-500"
                >
                  {t("dashboard.groupList.btn-update")}
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Delete modal */}
        <Modal
          show={openModal}
          size="md"
          onClose={() => setOpenModal(false)}
          popup
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {t("dashboard.groupList.key3")}
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={handleDeleteGroup}>
                  {t("dashboard.groupList.key4")}
                </Button>
                <Button
                  color="gray"
                  onClick={() => {
                    setOpenModal(false);
                    setToUpdateGroupId("");
                  }}
                >
                  {t("dashboard.groupList.key5")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );

  return (
    <div className="groups-page">
      <Helmet>
        <title>Groups | Super User Manager</title>
        <meta name="description" content="List of groups" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/groups`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
            {t("dashboard.groupList.title-01")}
          </h1>
          <button
            className="pl-[11px] pr-[13px] py-[9px] bg-sky-500 rounded-md shadow justify-center items-center gap-2 flex"
            disabled={userEndedSubscription}
            onClick={handleShowAddGroup}
          >
            <div className="shrink-0">
              <FaPlus size={14} color="white" />
            </div>
            <div className="text-white text-sm font-medium font-['Inter'] leading-none">
              {t("dashboard.groupList.btn-03")}
            </div>
          </button>
        </div>

        {content}
      </div>

      {/* Create group modal */}
      <Modal
        show={showCreateGroupModal}
        onClose={() => setShowCreateGroupModal(false)}
      >
        <Modal.Header className="border-0 text-zinc-900 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.groupList.key2")}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <Label
                htmlFor="group_name"
                value={t("dashboard.groupList.label-01").toString()}
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
              />

              <TextInput
                id="group_name"
                placeholder={`${t("dashboard.groupList.placeholder-01")}`}
                color={!!errors.group_name ? "failure" : ""}
                type="text"
                {...register("group_name")}
                helperText={<>{errors.group_name?.message as string}</>}
              />
            </div>

            <div className="mb-6">
              <Label
                htmlFor="group_description"
                value={t("dashboard.groupList.label-02").toString()}
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
              />

              <Textarea
                id="group_description"
                placeholder={`${t("dashboard.groupList.placeholder-02")}`}
                color={!!errors.group_description ? "failure" : ""}
                rows={6}
                helperText={<>{errors.group_description?.message as string}</>}
                {...register("group_description")}
              />
            </div>

            <div className="flex justify-end">
              <Button
                type="submit"
                disabled={CreateGroupMutation.isLoading}
                isProcessing={CreateGroupMutation.isLoading}
                className="bg-sky-500"
                processingSpinner={
                  <>
                    <AiOutlineLoading className="h-6 w-6 animate-spin" />
                  </>
                }
              >
                {t("dashboard.groupList.btn-create")}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Groups;
