import { AxiosError } from "axios";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Label,
} from "recharts";
import useAuth from "../../../hooks/useAuth";
import HttpService from "../../../services/http";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";

const UsersStatsChart = () => {
  const { t } = useTranslation();
  const { customer_identification, user } = useAuth();

  const [itt, setItt] = useState(7);

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    ["statistics", `past_${itt}_days`],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/user-statistics?days=${itt}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleFetchStats = (itt: number) => {
    setItt(itt);
  };

  let content;

  if (isLoading) content = <span className="my-5">Loading...</span>;

  if (isError)
    content = (
      <Button onClick={() => refetch()}>
        {t("dashboard.usersStatsChart.btn-01")}
      </Button>
    );

  if (isSuccess) {
    if (res?.data?.element) {
      let originalData = res?.data?.element[0]?.user_creation_date_time;
      let normalizedArr = [];
      for (const key in originalData) {
        if (Object.prototype.hasOwnProperty.call(originalData, key)) {
          const element = originalData[key];

          normalizedArr.push({
            name: moment(key, "DD/MM/YYYY").format("YYYY/MM/DD"),
            users: element.length,
          });
        }
      }

      content = (
        <div
          style={{
            position: "relative",
            height: "250px",
          }}
          className="self-stretch"
        >
          <ResponsiveContainer>
            <AreaChart
              width={500}
              height={300}
              data={normalizedArr
                .sort((a, b) => moment(a.name).diff(b.name))
                .map((item) => {
                  return { ...item, name: moment(item.name).format("MM/DD") };
                })}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 10,
              }}
            >
              {/* <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#3FBDF1" stopOpacity={0.9} />
                      <stop offset="95%" stopColor="#fff" stopOpacity={0.1} />
                    </linearGradient>
                  </defs> */}
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                axisLine={false}
                style={{ fontSize: "12px" }}
              >
                <Label
                  value={t("dashboard.usersStatsChart.key3").toString()}
                  position={"insideBottom"}
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#374151",
                  }}
                  dy={15}
                />
              </XAxis>
              <YAxis
                axisLine={false}
                domain={([dataMin, dataMax]) => {
                  return [0, Math.ceil(dataMax + 0.5)];
                }}
                allowDecimals={false}
                style={{ fontSize: "12px" }}
              >
                <Label
                  value={t("dashboard.usersStatsChart.key4").toString()}
                  angle={-90}
                  position={"insideLeft"}
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#374151",
                  }}
                />
              </YAxis>
              <Tooltip />
              <Area
                type="monotone"
                dataKey="users"
                strokeWidth={1}
                stroke="#0EA5E9"
                fill="url('#colorUv')"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      );
    } else content = null;
  }

  return (
    <div className="w-full px-6 pt-6 pb-7 bg-white rounded-lg shadow flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch text-gray-700 text-2xl font-bold font-['Figtree'] md:leading-loose leading-none">
        {t("dashboard.usersStatsChart.key5")}
      </div>

      <div className="self-stretch justify-start items-start gap-4 inline-flex flex-col">
        <div className="self-stretch justify-start items-center gap-2 inline-flex flex-wrap">
          {[7, 30, 90].map((period: number) => (
            <div
              key={period}
              className={`grow shrink basis-0 px-2.5 py-1.5 rounded ${
                period === itt ? "bg-gray-100 " : "border border-gray-300"
              }  flex-col justify-center items-center gap-2 inline-flex`}
            >
              <button
                onClick={() => handleFetchStats(period)}
                className={`self-stretch text-center text-xs font-medium font-['Figtree'] leading-none ${
                  period === itt ? "text-sky-600" : " text-gray-700"
                }`}
                disabled={isLoading}
              >
                {t("dashboard.usersStatsChart.key1", { period: period })}
              </button>{" "}
            </div>
          ))}
        </div>

        <div className="justify-start items-start gap-4 flex">
          <div className="justify-start items-center gap-2 flex">
            <div className="w-2.5 h-2.5 bg-sky-500 rounded-full" />
            <div className="text-gray-700 text-xs font-normal font-['Figtree'] leading-none">
              {t("dashboard.usersStatsChart.key2")}
            </div>
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};

export default UsersStatsChart;
