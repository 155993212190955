import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError } from "axios";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Button, Label, Textarea } from "flowbite-react";

const schema = Joi.object({
  message: Joi.string().trim().min(5).max(3500).required().label("Message"),
});

const AskQuestionMessage = () => {
  const [t] = useTranslation();
  const {
    customer_identification,
    user: { entityIdentification },
  } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const { isSuccess, data: res } = useQuery(
    "profile",
    () =>
      HttpService.get(
        `/${customer_identification}/${entityIdentification}/user/${customer_identification}/profile`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const SendMessageMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.post(
        `/${customer_identification}/${entityIdentification}/user/message`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success(t("dashboard.message.toast-01"));
        reset({
          message: "",
        });
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onSubmit = (values: any) => {
    if (isSuccess)
      SendMessageMutation.mutate({
        message: values.message,
        customer_email: res?.data.element.user_email,
      });
  };

  return (
    <div className="message-page">
      <Helmet>
        <title>Message | Super User Manager</title>
        <meta
          name="description"
          content={t("dashboard.message.text-01") as string}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/messages`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] mb-5">
          Support
        </h1>

        <div className="w-full p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
          <h2 className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
            {t("dashboard.message.title")}
          </h2>
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight mb-5">
            {t("dashboard.message.text-02")}
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="mb-3">
              <Label
                htmlFor="message"
                className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("dashboard.message.label")}
              </Label>
              <Textarea
                id="message"
                color={!!errors.message ? "failure" : ""}
                rows={5}
                helperText={<>{errors.message?.message as string}</>}
                placeholder={t("dashboard.message.placeholder") as string}
                {...register("message")}
              />
            </div>

            <div className="h-px bg-gray-300 my-5" />

            <div className="flex justify-end">
              <Button
                type="submit"
                disabled={SendMessageMutation.isLoading}
                isProcessing={SendMessageMutation.isLoading}
                className="bg-sky-500"
              >
                {t("dashboard.message.btn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AskQuestionMessage;
