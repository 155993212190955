import { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   Row,
//   Spinner,
// } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import useAuth from "../../hooks/useAuth";
import { useMutation, useQueryClient } from "react-query";
import HttpService from "../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import BsInputPassword from "../../components/public/BsInputPassword";
import { Helmet } from "react-helmet-async";
import AuthLogoRedirect from "../../components/public/AuthLogoRedirect";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";
import { useTranslation } from "react-i18next";
import { Button, Card, Spinner } from "flowbite-react";

const schema = Joi.object({
  actual_password: Joi.string().trim().required().label("Current password"),
  new_password: Joi.string().trim().required().label("New password"),
  new_confirm_password: Joi.any()
    .valid(Joi.ref("new_password"))
    .required()
    .messages({ "any.only": "Those passwords didn't match. Try again." }),
});
const LevelUpPassword = () => {
  const localStorage = useAppLocalStorage();
  const [t] = useTranslation();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = "/control-panel";

  useEffect(() => {
    if (!auth.user.isAuthenticated) navigate("/");
  }, [auth, from, navigate, searchParams]);

  const [apiErrors, setApiErrors] = useState<any>({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const PasswordCheckMutation = useMutation<
    any,
    AxiosError<any, any>,
    { password: string; level?: string | null }
  >((data) => HttpService.post("/auth/check-password", data), {
    onError: (err) => {
      toast.error(err.response?.data?.message || err.message);

      setApiErrors({ ...apiErrors, new_password: err.response?.data?.message });
      setError("new_password", err.response?.data?.message);
    },
  });

  const ChangePasswordMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${auth.user.userIdentification}/${auth.user.entityIdentification}/user/${auth.user.userIdentification}/password`,
        {
          actual_password: data.actual_password,
          new_password: data.new_password,
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success(t("levelUpPassword.msg1"));

        setApiErrors({});
        reset();

        logoutDirectlyToLogin();
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        if (err.response?.data?.message === "Credential error.") {
          setApiErrors({
            ...apiErrors,
            actual_password: err.response?.data?.message,
          });
        } else {
          setApiErrors({
            ...apiErrors,
            new_password: err.response?.data?.message,
          });
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    const level = localStorage.getItem("_umd_password_level");

    PasswordCheckMutation.mutate(
      {
        password: data.new_password,
        level: level,
      },
      {
        onSuccess: () => {
          ChangePasswordMutation.mutate(data);
        },
      }
    );
  };

  const logoutDirectlyToLogin = () => {
    localStorage.removeItem("_umd_service");
    auth.logout();
    queryClient.clear();
    navigate("/login");
  };

  const formChangeHandler = () => {
    setApiErrors({});
  };

  return (
    <div className="auth-page min-vh-100 d-flex align-items-center">
      <Helmet>
        <title>Super User Manager - Password level up</title>
        <meta
          name="description"
          content="Super User Manager - Level up password"
        />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/level-up-password`}
        />
      </Helmet>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 justify-content-center">
          <div className="md:col-span-6 sm:col-span-8 col-span-12">
            <Card>
              <div className="title">
                <h1>{t("levelUpPassword.title")}</h1>
                <small>
                  {t("levelUpPassword.backNote")}{" "}
                  <Link to={from} className="text-black">
                    {t("levelUpPassword.backBtn")}
                  </Link>
                </small>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                onChange={formChangeHandler}
              >
                <BsInputPassword
                  label={t("levelUpPassword.label_1") as string}
                  isInvalid={
                    !!errors.actual_password || !!apiErrors.actual_password
                  }
                  errorMessage={
                    (errors.actual_password?.message as string) ||
                    apiErrors.actual_password
                  }
                  className={"mb-3"}
                  rhfControls={register("actual_password")}
                />

                <BsInputPassword
                  label={t("levelUpPassword.label_2") as string}
                  isInvalid={!!errors.new_password || !!apiErrors.new_password}
                  errorMessage={
                    (errors.new_password?.message as string) ||
                    apiErrors.new_password
                  }
                  className={"mb-3"}
                  rhfControls={register("new_password")}
                />

                <BsInputPassword
                  label={t("levelUpPassword.label_1") as string}
                  isInvalid={!!errors.new_confirm_password}
                  errorMessage={errors.new_confirm_password?.message as string}
                  className={"mb-3"}
                  rhfControls={register("new_confirm_password")}
                />

                <Button
                  color="info"
                  type="submit"
                  className="w-100 rounded-pill"
                  size="lg"
                  disabled={ChangePasswordMutation.isLoading}
                >
                  {ChangePasswordMutation.isLoading ? (
                    <Spinner />
                  ) : (
                    t("levelUpPassword.resetBtn")
                  )}
                </Button>
              </form>

              <AuthLogoRedirect
                description={t("levelUpPassword.brand.description")}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelUpPassword;
