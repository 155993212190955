import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { useForm } from "react-hook-form";
import {
  INormalizedLmcDataType,
  historyMessageCardFormProps,
  lmcOptionComponentProps,
} from "./types";
import LmcUtil from "./util";
import { Button, Label, Textarea, TextInput } from "flowbite-react";

const schema = Joi.object()
  .keys({
    historyEventNumber: Joi.number()
      .integer()
      .positive()
      .min(1)
      .max(100)
      .required()
      .label("Number of history events to show")
      .messages({
        "number.base":
          'The "number of history events to show" is not allowed to be empty',
      }),
    comment: Joi.string().max(100).optional().allow("", null).label("Comment"),
  })
  .required();

const HistoryMessageCard = ({
  label,
  optionData,
  onSubmitCardData,
  playgroundDataHandler,
  handleCancelEdit,
}: lmcOptionComponentProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm<historyMessageCardFormProps>({
    resolver: joiResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    if (optionData) {
      let denormalizedData = LmcUtil.unwrapper(
        "HIST_MESSAGE_CARD",
        optionData
      ) as historyMessageCardFormProps;

      reset({ ...denormalizedData });
    }
  }, [optionData, reset]);

  const onSubmit = (data: historyMessageCardFormProps) => {
    const normalizedData = LmcUtil.wrapper(
      "HIST_MESSAGE_CARD",
      data
    ) as INormalizedLmcDataType;

    onSubmitCardData("HIST_MESSAGE_CARD", normalizedData).then(() => {
      reset();
    });
  };

  useEffect(() => {
    playgroundDataHandler({
      type: "HIST_MESSAGE_CARD",
      data: LmcUtil.wrapper(
        "HIST_MESSAGE_CARD",
        getValues()
      ) as INormalizedLmcDataType,
    });

    watch((value, { name, type }) => {
      playgroundDataHandler({
        type: "HIST_MESSAGE_CARD",
        data: LmcUtil.wrapper(
          "HIST_MESSAGE_CARD",
          value
        ) as INormalizedLmcDataType,
      });
    });
  }, [getValues, playgroundDataHandler, watch]);

  return (
    <div className="lmc-card point-card w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
            {t("dashboard.features.lmc.historyMessageCard.label2")}
          </Label>
          <TextInput
            color={!!errors.historyEventNumber ? "failure" : ""}
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            placeholder={
              t(
                "dashboard.features.lmc.historyMessageCard.placeholder2"
              ) as string
            }
            min={1}
            helperText={<>{errors.historyEventNumber?.message}</>}
            {...register("historyEventNumber")}
          />
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        <div className="mb-3">
          <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
            {t("dashboard.features.lmc.historyMessageCard.label3")}
          </Label>
          <Textarea
            color={!!errors.comment ? "failure" : ""}
            rows={3}
            placeholder={
              t(
                "dashboard.features.lmc.historyMessageCard.placeholder3"
              ) as string
            }
            helperText={<>{errors.comment?.message}</>}
            {...register("comment")}
          />
        </div>

        <div className="mb-3 flex justify-end gap-4">
          <Button color={"light"} onClick={handleCancelEdit}>
            {t("dashboard.features.lmc.general.cancel")}
          </Button>
          <Button type="submit" className="bg-sky-500">
            {t("dashboard.features.lmc.general.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export const HistoryMessageCardUi = ({
  data,
}: {
  data: INormalizedLmcDataType | null;
}) => {
  const { t, i18n } = useTranslation();

  const samples = (lngCode: string) => {
    switch (lngCode) {
      case "en":
      case "en-US":
      case "en-EU":
      case "en-CH":
      default:
        return [
          {
            title: "Large dog food bag.",
            date: "May 19, 2024",
          },
          {
            title: "Visit every Thursday and Saturday.",
            date: "April 10, 2024",
          },
          {
            title: "No onions in meals.",
            date: "March 24, 2024",
          },
          {
            title: "Customer prefers organic products.",
            date: "March 14, 2024",
          },
        ];
      case "fr":
      case "fr-FR":
      case "fr-CH":
        return [
          {
            title: "Gros sac de nourriture pour chien.",
            date: "May 19, 2024",
          },
          {
            title: "Visite tous les jeudis et samedis.",
            date: "April 10, 2024",
          },
          {
            title: "Pas d'oignons dans les repas.",
            date: "March 24, 2024",
          },
          {
            title: "Le client préfère les produits bio.",
            date: "March 14, 2024",
          },
        ];
    }
  };

  return (
    <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
      <h4 className="text-2xl font-bold text-gray-800">
        {t("dashboard.features.loginTemplate.tempPart.btn-01")}
      </h4>

      <div className="self-stretch  bg-white rounded-2xl shadow flex-col justify-start items-start flex p-4">
        {/* <div className="self-stretch px-4 py-4 flex-col justify-start items-center gap-2.5 flex">
          <div className="self-stretch p-4 rounded-lg border border-dashed border-gray-300 flex-col justify-center items-center gap-3 flex">
            <div className="flex-col justify-center items-center gap-1 flex">
              <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.historyMessageCard.key1")}
              </div>
            </div>
            <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
              <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                <div className="text-center text-red-600 text-base font-semibold font-['Figtree'] leading-normal">
                  {t("dashboard.features.lmc.historyMessageCard.key2")}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="w-full justify-between items-start inline-flex border-b border-gray-300 pb-3 mb-3">
          <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
            {t("dashboard.features.lmc.historyMessageCard.key1")}
          </div>
          <div className="text-center text-red-600 text-base font-semibold font-['Figtree'] leading-normal">
            {t("dashboard.features.lmc.historyMessageCard.key2")}
          </div>
        </div>
        {/* {data?.options?.is_private_messages && ( */}
        <div className="self-stretch">
          {data &&
            samples(i18n.language)?.map((item, index) => (
              <div
                key={index}
                className={`border-b border-gray-300 pb-3 ${
                  5 !== index ? "mb-3" : "mb-2"
                }`}
              >
                <div className="self-stretch text-gray-700 mb-2">
                  {item.title}
                </div>
                <div className="text-gray-500 text-base font-semibold font-['Figtree'] leading-normal">
                  {item.date}
                </div>
              </div>
            ))}
        </div>

        <div className=" text-sky-600 text-base font-semibold font-['Figtree'] leading-normal">
          {t("dashboard.features.lmc.general.seeAll")}
        </div>
      </div>
    </div>
  );
};

export default HistoryMessageCard;
