import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { TypeModal } from "../entities/RoleTypeEditorNewVersion";
import { Dropdown, Modal } from "flowbite-react";
import { FaChevronDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

type TypeEditorType = {
  data: {
    type: string;
  };
  user_identification: string;
  customerId: string;
  entity_identification: string;
  list_type: string[];
  disabled: boolean;
};

export const TypeOptionsPart = ({
  data,
  customerId,
  entity_identification,
  user_identification,
  list_type,
  disabled,
}: TypeEditorType) => {
  const { t } = useTranslation();
  const [types, setTypes] = useState<string[] | []>([]);
  const [type, setType] = useState<string>("");
  const [showModalType, setShowModalType] = useState(false);

  useEffect(() => {
    setTypes(list_type);
    setType(data.type);
  }, [data, list_type]);

  const UpdateTypeMutation = useMutation<string, AxiosError<any, any>, any>(
    (type) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/user/${customerId}/type/${type}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleTypeManagement = (e: string) => {
    const optionValue = e;

    // Update user role here
    if (optionValue !== "addType") {
      UpdateTypeMutation.mutate(optionValue, {
        onSuccess(data, variables, context) {
          // toast.success("User's type updated");

          setType(optionValue);
        },
      });
    } else {
      // if it is a new role add it to the list of roles
      setShowModalType(true);
    }
  };

  let typeContent = (
    <Dropdown
      label=""
      renderTrigger={() => (
        <button className="group w-full justify-between items-center flex">
          <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-tight text-left md:text-nowrap">
            {type}
          </div>
          <div className="w-5 h-5 relative ml-1 group-hover:opacity-100 opacity-0">
            <FaChevronDown size={14} />
          </div>
        </button>
      )}
      placement="auto"
    >
      <div className="max-h-[200px] overflow-y-auto">
        {types.map((type: string, index: number) => (
          <Dropdown.Item
            key={index}
            value={type}
            onClick={() => handleTypeManagement(type)}
          >
            {type}
          </Dropdown.Item>
        ))}
      </div>
      <Dropdown.Divider />
      <Dropdown.Item
        className="text-sky-500"
        onClick={() => handleTypeManagement("addType")}
      >
        {t("dashboard.roleTypeEditorNewVersion.btn-03")}
      </Dropdown.Item>
    </Dropdown>
    // <select
    //   className="border-none bg-transparent p-0 focus:ring-0 text-gray-500 text-sm font-normal font-['Inter'] leading-tight"
    //   value={type}
    //   name="type"
    //   onChange={handleTypeManagement}
    //   disabled={disabled}
    // >
    //   {types?.map((type: string, index: number) => (
    //     <option key={index} value={type}>
    //       {type}
    //     </option>
    //   ))}
    //   {/* <option value="addType">Add new type</option> */}
    // </select>
  );

  return (
    <div className="role-option-part-drop-down">
      {typeContent}

      {/* Add user to group modal */}
      <Modal
        size="lg"
        show={showModalType}
        onClose={() => setShowModalType(false)}
        className="role-modal"
      >
        <Modal.Header className="border-b-0 text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.roleTypeEditorNewVersion.modal.header-02")}
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight mt-2">
            {t("dashboard.roleTypeEditorNewVersion.modal.description-02")}
          </p>
        </Modal.Header>

        <Modal.Body>
          <TypeModal
            types={types}
            setTypes={setTypes}
            setShowTypeModal={setShowModalType}
            mutation={UpdateTypeMutation}
            setType={setType}
            disabled={disabled}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
