import { Link } from "react-router-dom";

const DashboardNotFound = () => {
  return (
    <div className="grid grid-cols-12 px-8 py-[52px] flex-col justify-center items-center md:h-[calc(100vh-72px)]">
      <div className="md:col-span-6 col-span-12 h-full bg-white px-4 py-16 flex-col justify-center items-center gap-6 inline-flex">
        <div className="self-stretch flex-col justify-start items-center gap-2 flex">
          <div className="self-stretch text-center text-sky-600 text-sm font-semibold font-['Inter'] uppercase leading-tight tracking-tight">
            404 error
          </div>
          <div className="self-stretch text-center text-gray-900 text-5xl font-extrabold font-['Inter'] leading-[48px]">
            Page not found.
          </div>
          <div className="self-stretch text-center text-gray-500 text-base font-normal font-['Inter'] leading-normal">
            Sorry, we couldn’t find the page you’re looking for.
          </div>
        </div>
        <div className="justify-start items-center inline-flex">
          <Link
            to={"/control-panel"}
            className="text-sky-600 text-base font-medium font-['Inter'] leading-normal"
          >
            Go back home →
          </Link>
        </div>
      </div>
      <div className="md:col-span-6 col-span-12 h-full px-4 py-16 bg-gray-300">
        <img
          className="w-full h-full"
          src="https://cdn.1cdn.app/application/UMD/23030509171580_UMD_Error404.svg"
          alt="not found"
        />
      </div>
    </div>
  );
};

export default DashboardNotFound;
