import { Button } from "flowbite-react";
import { useCallback, useMemo, useState } from "react";

interface Props {
  previousLabel?: React.ReactNode;
  nextLabel?: React.ReactNode;
  pageCount: number;
  onPageChange: (...args: any) => void;
  pageRangeDisplayed?: number;
  breakLabel?: string;
  pageLinkClassName?: string;
  previousClassName?: string;
  nextClassName?: string;
  disabledClassName?: string;
  breakClassName?: string;
  containerClassName?: string;
  activeClassName?: string;
  gap?: Record<string, string>;
  forcePage?: number;
}

const CustomPagination = ({
  previousLabel = "Prev",
  nextLabel = "Next",
  pageCount,
  onPageChange,
  pageRangeDisplayed = 2,
  breakLabel = "...",
  pageLinkClassName,
  previousClassName,
  nextClassName,
  disabledClassName,
  breakClassName,
  containerClassName,
  activeClassName,
  gap,
  forcePage,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(forcePage || 0);

  const handlePageClick = useCallback(
    (page: number) => {
      onPageChange({ selected: page });
      setCurrentPage(page);
    },
    [onPageChange]
  );

  const pages = useMemo(() => {
    const pages = [];
    const leftSide = pageRangeDisplayed / 2;
    const rightSide = pageRangeDisplayed - leftSide;

    for (let i = 0; i < pageCount; i++) {
      const label = i + 1;
      let className = pageLinkClassName;
      if (i === currentPage) className += ` ${activeClassName}`;

      if (
        i === 0 ||
        i === pageCount - 1 ||
        (i >= currentPage - leftSide && i <= currentPage + rightSide)
      ) {
        pages.push(
          <Button
            color={"light"}
            key={i}
            className={`md:inline-flex hidden ${
              i === currentPage
                ? "bg-sky-50 border border-sky-500 text-sky-600"
                : "bg-white border border-gray-300 text-gray-500"
            } flex-col justify-center items-center text-center text-sm font-medium font-['Inter'] leading-tight h-[45px] ${className}`}
            onClick={() => handlePageClick(i)}
            style={gap}
          >
            {label}
          </Button>

          //   <button
          //     key={i}
          //     onClick={() => handlePageClick(i)}
          //     className={className}
          //     style={gap}
          //   >
          //     {label}
          //   </button>
        );
      } else if (
        (i < currentPage - leftSide && i === 1) ||
        (i > currentPage + rightSide && i === pageCount - 2)
      ) {
        pages.push(
          <Button
            color={"light"}
            key={i}
            className={
              breakClassName +
              " h-[45px] flex-col justify-center items-center md:inline-flex hidden text-center text-sm font-medium font-['Inter'] leading-tight"
            }
            style={gap}
          >
            {breakLabel}
          </Button>
        );
      }
    }

    return pages;
  }, [
    pageRangeDisplayed,
    pageCount,
    pageLinkClassName,
    currentPage,
    activeClassName,
    gap,
    handlePageClick,
    breakClassName,
    breakLabel,
  ]);

  return (
    <div className={containerClassName}>
      <Button.Group className="mb-0">
        <Button
          color={"light"}
          onClick={() => handlePageClick(currentPage - 1)}
          className="flex flex-col items-center justify-center h-[45px]"
          disabled={currentPage === 0}
          style={gap}
        >
          {previousLabel}
        </Button>

        {pages}

        <Button
          color={"light"}
          disabled={currentPage === pageCount - 1}
          onClick={() => handlePageClick(currentPage + 1)}
          className="flex flex-col items-center justify-center h-[45px]"
        >
          {nextLabel}
        </Button>
      </Button.Group>

      {/* <button
        onClick={() => handlePageClick(currentPage - 1)}
        className={
          currentPage === 0
            ? `${previousClassName} ${disabledClassName}`
            : previousClassName
        }
        disabled={currentPage === 0}
        style={gap}
      >
        {previousLabel}
      </button>

      {pages}

      <button
        onClick={() => handlePageClick(currentPage + 1)}
        className={
          currentPage === pageCount - 1
            ? `${nextClassName} ${disabledClassName}`
            : nextClassName
        }
        disabled={currentPage === pageCount - 1}
      >
        {nextLabel}
      </button> */}
    </div>
  );
};

export default CustomPagination;
