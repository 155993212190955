import { useNavigate } from "react-router-dom";

const LmcInPageHeader = ({
  pageTitle,
  pageInfoDetails,
}: {
  pageTitle?: string;
  pageInfoDetails?: { title?: string; description: string };
}) => {
  const navigate = useNavigate();

  return (
    <>
      <header className="lmc-uv-page-header py-3 px-0">
        <div className="back-area flex items-center gap-4 px-0 text-white">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <path
              d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
              fill="white"
            />
          </svg>
          {pageTitle && <span className="block text-white">{pageTitle}</span>}
        </div>
      </header>
      {pageInfoDetails && (
        <div>
          <div className="lmc-uv-page-extra-infos text-center py-8 text-white">
            {pageInfoDetails.title && <h4>{pageInfoDetails.title}</h4>}
            {pageInfoDetails.description && (
              <p>{pageInfoDetails.description}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LmcInPageHeader;
