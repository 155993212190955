import { useMutation, useQueryClient } from "react-query";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import { AxiosError, AxiosResponse } from "axios";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { Button } from "flowbite-react";

const ProfileAvatar = ({
  avatar,
  entity_identification,
  customerId,
}: {
  avatar?: string;
  entity_identification: string;
  customerId: string;
}) => {
  const [t] = useTranslation();
  const { customer_identification } = useAuth();

  const inputFileRef = useRef<any>(null);

  const queryClient = useQueryClient();

  const CdnAvatarMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.post(
        `/${customer_identification}/${entity_identification}/cdn/avatar`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const ProfileAvatarUpdateMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.post(
        `/${customer_identification}/${entity_identification}/user/${customerId}/profile/avatar`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const ProfileAvatarDeleteMutation = useMutation<any, AxiosError<any, any>>(
    () =>
      HttpService.delete(
        `/${customer_identification}/${entity_identification}/user/${customerId}/profile/avatar`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleTriggerFileInput = () => {
    let inputFileElement = document.getElementById("avatarInput");

    inputFileRef.current.value = "";

    inputFileElement?.click();
  };

  const handleUploadAvatar = (files: any) => {
    if (files.length > 0) {
      if (files[0].size > 3 * 1048576) return toast.error("File too large");

      CdnAvatarMutation.mutate({ image: files[0] } as any, {
        onSuccess: (res: AxiosResponse) => {
          ProfileAvatarUpdateMutation.mutate(
            { avatar: res.data.element[0].cdnPath } as any,
            {
              onSuccess: () => {
                toast.success("Avatar updated");

                queryClient.invalidateQueries("profile");

                // closeRelatedModal(false);
                inputFileRef.current.value = "";
              },
            }
          );
        },
      });
    }
  };

  const handleDeleteProfile = () => {
    ProfileAvatarDeleteMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success("Avatar removed");

        queryClient.invalidateQueries("profile");
      },
    });
  };

  return (
    <div className="flex-col justify-start items-start gap-2 flex">
      <div className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
        {t("dashboard.profile.key4")}
      </div>
      <div className="justify-start items-center gap-5 inline-flex">
        <div className="w-12 h-12 relative bg-gray-100 rounded-3xl">
          <img
            title="Super User Manager"
            loading="lazy"
            src={avatar ? avatar : "/images/avatar.png"}
            alt="UMD user avatar"
            className="w-full h-full rounded-3xl object-cover"
          />
        </div>
        <div className="justify-start items-center gap-3 flex">
          <Button
            color={""}
            size={"sm"}
            className="p-0 bg-white rounded-md shadow border border-gray-300 justify-center items-center flex text-gray-700 text-sm font-medium font-['Inter'] leading-none"
            onClick={handleTriggerFileInput}
            isProcessing={ProfileAvatarUpdateMutation.isLoading}
            disabled={ProfileAvatarUpdateMutation.isLoading}
          >
            {t("dashboard.userProfile.profileAvatar.btn-01")}
          </Button>

          {avatar && (
            <Button
              color={""}
              size={"sm"}
              className="p-0 bg-white rounded-md shadow border border-gray-300 justify-center items-center flex text-gray-700 text-sm font-medium font-['Inter'] leading-none"
              onClick={handleDeleteProfile}
              isProcessing={ProfileAvatarDeleteMutation.isLoading}
              disabled={ProfileAvatarDeleteMutation.isLoading}
            >
              {t("dashboard.userProfile.profileAvatar.btn-02")}
            </Button>
          )}
        </div>
      </div>

      <input
        type={"file"}
        hidden
        id="avatarInput"
        accept=".jpg,.png,.jpeg"
        onChange={(e) => handleUploadAvatar(e.target.files)}
        ref={inputFileRef}
      />
    </div>
  );
};

export default ProfileAvatar;
