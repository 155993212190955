import { lmcOptionType } from "./types";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useRef, useState } from "react";
import QRCodeStyling from "livebook-qr-code";

const qrCode = new QRCodeStyling({
  width: 250,
  height: 250,
  type: "svg",
  qrOptions: { errorCorrectionLevel: "H" },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
});

const ResultLmcCard = ({
  lmc,
  preferencesData,
  processId,
}: {
  lmc: lmcOptionType;
  preferencesData: { [key: string]: any };
  processId: string;
}) => {
  const { t } = useTranslation();
  // const { user } = useAuth();
  // const queryClient = useQueryClient();

  const ref = useRef(null);
  const [isOpeningNewTab, setIsOpeningNewTab] = useState(false);

  // const UpdateEntityPreferences = useMutation<any, AxiosError<any, any>, any>(
  //   (data) =>
  //     HttpService.patch(`/entity/${user.entityIdentification}/lmc`, data, {
  //       auth: HttpService.getToken(),
  //     })
  // );

  const handleDownloadQrCode = () => {
    html2canvas(
      document.getElementById(`qr-${processId}`) as HTMLCanvasElement
    ).then((canvas) => {
      let anchor = document.createElement("a");
      anchor.download = `${lmc}_${processId}.jpeg`;
      anchor.href = canvas.toDataURL("image/jpeg");
      anchor.click();
      anchor.remove();
    });
    // qrCode.download({
    //   extension: "jpeg",
    //   name: `${lmc}_${processId}`,
    // });
  };

  // const handleAutoRefresh = (isChecked: boolean) => {
  //   if (preferencesData?.lmc_form) {
  //     const { lmc_form } = preferencesData;
  //     const { scanner_auto_refresh, ...rest2 } = lmc_form;

  //     UpdateEntityPreferences.mutate(
  //       {
  //         scanner_auto_refresh: isChecked,
  //         ...rest2,
  //       },
  //       {
  //         onSuccess(data, variables, context) {
  //           queryClient.invalidateQueries("entity_preferences");
  //         },
  //         onError(err: any) {
  //           toast.error(err.response?.data?.message || err.message);
  //         },
  //       }
  //     );
  //   }
  // };

  const hanldeOpenInNewTab = () => {
    setIsOpeningNewTab(true);

    html2canvas(document.getElementById(`qr-${processId}`) as HTMLCanvasElement)
      .then((canvas) => {
        setIsOpeningNewTab(false);

        canvas.toBlob(
          (blob) => blob && window.open(URL.createObjectURL(blob), "_blank")
        );
      })
      .catch((err) => {
        setIsOpeningNewTab(false);
      });
  };

  useEffect(() => {
    if (ref.current) qrCode.append(ref.current);
  }, []);

  const qrInitializer = useCallback(async () => {
    let targetSrc;

    try {
      await fetch(
        // preferencesData.cdn_logo_project_path ||
        process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL as string
      ).then((res) => {
        if (res.status === 200) {
          targetSrc =
            // preferencesData.cdn_logo_project_path ||
            process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL;
        }
      });
    } catch (error) {
      targetSrc = "/images/logo.png";
    }

    qrCode.update({
      data: `${process.env.REACT_APP_HOST_URL}/process/${processId}`,
      image: targetSrc,
      dotsOptions: {
        color: "#000000",
        type: "rounded",
      },
    });
  }, [processId]);

  useEffect(() => {
    qrInitializer();
  }, [qrInitializer]);

  return (
    <div className="w-full">
      {/* <div className="mb-3">
        <div className="flex items-center gap-2">
          <label className="switch shrink-0">
            <input
              type="checkbox"
              checked={preferencesData?.lmc_form?.scanner_auto_refresh}
              onChange={(e) => handleAutoRefresh(e.target.checked)}
            />
            <div className="slider"></div>
          </label>
          <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
            {t("dashboard.features.lmc.setting.sc.label")}
          </span>
        </div>
      </div> */}

      <center className="w-full mb-4">
        <div ref={ref} id={`qr-${processId}`} />
      </center>

      <div className="flex justify-center gap-3 lg:flex-row md:flex-col flex-row flex-wrap">
        <Button size={"sm"} color="light" onClick={handleDownloadQrCode}>
          {t("dashboard.features.lmc.qrModal.downloadQrBtn")}
        </Button>
        <Button
          size={"sm"}
          color="light"
          onClick={hanldeOpenInNewTab}
          isProcessing={isOpeningNewTab}
        >
          {t("dashboard.features.lmc.qrModal.openOtherTab")}
        </Button>
      </div>
    </div>
  );
};

export default ResultLmcCard;
