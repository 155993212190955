import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import {
  INormalizedLmcDataType,
  amountCardFormProps,
  lmcOptionComponentProps,
} from "./types";
import LmcUtil from "./util";
import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import { HiTrash } from "react-icons/hi";

const uniqueValues = (arr: Array<any>, key: string) => {
  const seen = new Set();
  for (const obj of arr) {
    const value = obj[key];
    if (value !== null && value !== "" && seen.has(value)) {
      return false;
    }
    seen.add(value);
  }
  return true;
};

const validatePrecision = (value: number, precision: number) => {
  if (typeof value !== "number") {
    throw new Error("Input value must be a number");
  }

  const stringValue = value.toString();
  const decimalIndex = stringValue.indexOf(".");

  if (decimalIndex === -1) {
    return true; // No decimal places
  }

  const decimalPlaces = stringValue.length - decimalIndex - 1;
  return decimalPlaces <= precision;
};

const atLeastOneItemIsFilled = (arr: Array<{ value: number }>) => {
  let hasOne = arr.find(
    (item) =>
      Object.keys(item).length > 0 && item.value > 0 && item.value !== null
  );

  return hasOne ? true : false;
};

const schema = Joi.object()
  .keys({
    values: Joi.array()
      .items(
        Joi.object({
          value: Joi.number()
            .custom((value, helper) => {
              if (!validatePrecision(value, 2)) {
                // Define the error message using helper.message()
                return helper.message(
                  "Number must have at most 2 decimal places" as any
                );
              }
              return value;
            })
            .positive()
            .min(1)
            .max(9999999999)
            // .required()
            .allow(null)
            .allow("")
            .optional()
            .label("Default amount"),
        })
      )
      .min(1)
      .max(3)
      .required()
      .custom((value, helper) => {
        if (!uniqueValues(value, "value")) {
          return helper.message(
            "Default amounts must contain unique values for default amount" as any
          );
        }
        return value;
      })
      .custom((value, helper) => {
        if (!atLeastOneItemIsFilled(value)) {
          return helper.message(
            "Default amounts must contain at least one value" as any
          );
        }
        return value;
      })
      .label("Default amounts")
      .messages({
        "array.includesRequiredKnowns":
          "Add at least one value to the quick access buttons",
      }),
    defaultValue: Joi.string().optional().label("Default value"),
    currency: Joi.string().required().label("Currency"),
    customValue: Joi.boolean().required().label("Custom value"),
    comment: Joi.string().max(100).optional().allow("", null).label("Comment"),
  })
  .required();

const AmountCard = ({
  label,
  optionData,
  onSubmitCardData,
  playgroundDataHandler,
  handleCancelEdit,
}: lmcOptionComponentProps) => {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    trigger,
  } = useForm<amountCardFormProps>({
    resolver: joiResolver(schema),
    // mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "values", // unique name for your Field Array
  });

  useEffect(() => {
    if (optionData) {
      let denormalizedData = LmcUtil.unwrapper(
        "AMOUNT_CARD",
        optionData
      ) as amountCardFormProps;

      reset({ ...denormalizedData });
    }
  }, [optionData, reset]);

  const onSubmit = (data: amountCardFormProps) => {
    const newData = {
      ...data,
      values: data.values
        .filter((item: any) => item.value !== null)
        .filter((item: any) => item.value !== "")
        .map((v) => v.value),
    };

    const normalizedData = LmcUtil.wrapper(
      "AMOUNT_CARD",
      newData
    ) as INormalizedLmcDataType;

    onSubmitCardData("AMOUNT_CARD", normalizedData).then(() => {
      reset();
    });
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      const newData = {
        ...value,
        values: value?.values
          ?.filter((item: any) => item.value !== null)
          ?.filter((item: any) => item.value !== "")
          ?.map((v) => v?.value),
      };

      playgroundDataHandler({
        type: "AMOUNT_CARD",
        data: LmcUtil.wrapper("AMOUNT_CARD", newData) as INormalizedLmcDataType,
      });
    });
  }, [playgroundDataHandler, watch]);

  return (
    <div className="lmc-card point-card w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <Label
            htmlFor={"defaultValue"}
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.amountCard.label3")}
          </Label>
          <Select
            color={!!errors.currency ? "failure" : ""}
            aria-label="Default select example"
            helperText={<>{errors.currency?.message}</>}
            {...register("currency")}
          >
            <option value="">
              {t("dashboard.features.lmc.amountCard.placeholder3")}
            </option>
            <option value="USD">
              {t("dashboard.features.lmc.amountCard.key2")}
            </option>
            <option value="EUR">
              {t("dashboard.features.lmc.amountCard.key3")}
            </option>
            {/* <option value="pound">
              {t("dashboard.features.lmc.amountCard.key4")}
            </option>
            <option value="frank">
              {t("dashboard.features.lmc.amountCard.key5")}
            </option> */}
          </Select>
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        {fields.map((item, index) => (
          <div className="mb-3" key={item.id}>
            <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
              {t("dashboard.features.lmc.amountCard.label1") as string}
            </Label>
            <div className="grid grid-cols-7">
              <div
                className={`${fields.length > 1 ? "col-span-6" : "col-span-7"}`}
              >
                <TextInput
                  helperText={<>{errors.values?.[index]?.value?.message}</>}
                  color={!!errors.values?.[index] ? "failure" : ""}
                  type="number"
                  min={1}
                  step=".01"
                  placeholder={
                    t("dashboard.features.lmc.pointCard.placeholder1") as string
                  }
                  {...register(`values.${index}.value`, {
                    onChange(event) {
                      trigger();
                    },
                  })}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </div>
              {fields.length > 1 && (
                <div className="col-span-1">
                  <Button
                    color={""}
                    className="text-gray-300"
                    onClick={() => remove(index)}
                  >
                    <HiTrash size={22} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}

        {watch("values")?.length < 3 && (
          <Button
            type="button"
            color="light"
            onClick={() => append({ value: null })}
            className="mb-3 w-full"
          >
            {t("dashboard.features.lmc.amountCard.key1")}
          </Button>
        )}

        {!!errors.values && (
          <span className="text-sm text-red-600 block mb-3">
            {errors.values?.message?.toString()}
          </span>
        )}

        <div className="mb-3">
          <div className="flex items-center gap-2">
            <label className="switch shrink-0">
              <input type="checkbox" {...register("customValue")} />
              <div className="slider"></div>
            </label>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.features.lmc.amountCard.label5")}
            </span>
          </div>
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        <div className="mb-3">
          <Label
            htmlFor={"comment"}
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.amountCard.label4")}
          </Label>
          <Textarea
            color={!!errors.comment ? "failure" : ""}
            rows={3}
            placeholder={
              t("dashboard.features.lmc.amountCard.placeholder4") as string
            }
            helperText={<>{errors.comment?.message}</>}
            {...register("comment")}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button color={"light"} onClick={handleCancelEdit}>
            {t("dashboard.features.lmc.general.cancel")}
          </Button>
          <Button type="submit" className="bg-sky-500">
            {t("dashboard.features.lmc.general.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export const AmountCardUi = ({
  data,
}: {
  data: INormalizedLmcDataType | null;
}) => {
  const { t } = useTranslation();

  const currencyMapper: { [key: string]: string } = {
    USD: "$",
    EUR: "€",
  };

  return (
    <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
      <h4 className="text-2xl font-bold text-gray-800">
        {t("dashboard.features.loginTemplate.tempPart.btn-01")}
      </h4>

      <div className="self-stretch  bg-white rounded-2xl shadow flex-col justify-start items-center gap-6 flex">
        {/* <div className="self-stretch px-4 pt-4 flex-col justify-start items-center gap-2.5 flex">
          <div className="self-stretch p-4 rounded-lg border border-dashed border-gray-300 flex-col justify-center items-center gap-3 flex">
            <div className="flex-col justify-center items-center gap-1 flex">
              <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.amountCard.key6")}
              </div>
              <div className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                $2
              </div>
            </div>
            <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
              <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                <div className="text-center text-red-600 text-base font-semibold font-['Figtree'] leading-normal">
                  {t("dashboard.features.lmc.amountCard.key7")}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="self-stretch px-4 pb-5 flex-col justify-center items-center gap-3 flex pt-4">
          <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
            {t("dashboard.features.lmc.amountCard.key8")}
          </div>
          {data?.options?.custom_value && (
            <div className="self-stretch h-11 flex-col justify-center items-center gap-4 flex">
              <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                  <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("dashboard.features.lmc.amountCard.key9")}
                  </div>
                </div>
              </div>
            </div>
          )}
          {data?.pre_defined_value?.values?.map((v, index) => {
            if (v > 0 && v <= 9999999999)
              return (
                <div
                  key={index}
                  className="self-stretch h-11 flex-col justify-center items-center gap-4 flex"
                >
                  <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                      <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                        {data?.pre_defined_value?.currency &&
                          currencyMapper[data?.pre_defined_value?.currency]}
                        {new Intl.NumberFormat().format(v)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            else return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default AmountCard;
