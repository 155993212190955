import React from "react";
import { useTranslation } from "react-i18next";

const LmcRewardPercentage = ({ percent = 0 }: { percent?: number }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full py-20 px-4 flex flex-col justify-start items-center gap-4">
      <h4 className="w-[158px] text-center text-gray-50 text-xl font-semibold font-['Figtree'] leading-7">
        {t("lmcUser.key45")}
      </h4>
      <h2 className="text-center text-emerald-300 text-6xl font-extrabold font-['Figtree'] leading-[60px]">{`-${percent}%`}</h2>
    </div>
  );
};

export default LmcRewardPercentage;
