import { Button, Modal } from "flowbite-react";
import { HiExclamation } from "react-icons/hi";


const ConfirmModal = ({
  show,
  onClose,
  title,
  description,
  cancelBtnName,
  cancelBtnFn,
  confirmBtnName,
  confirmBtnFn,
  disabled = false,
  isLoading = false,
  ConfirmBtnColor = "blue"
}: {
  show: boolean;
  onClose: (value: boolean) => void;
  title: string;
  description: string;
  cancelBtnName:  string;
  cancelBtnFn:  (value: boolean) => void;
  confirmBtnName:  string;
  confirmBtnFn:  () => void;
  disabled?: boolean;
  isLoading?: boolean;
  ConfirmBtnColor?: "red" | "blue";
}) => {

  return (
    <Modal size={"lg"} dismissible show={show} onClose={() => onClose(false)}>
    <Modal.Body >
      <div className="w-full flex flex-col md:flex-row justify-start items-center md:items-start gap-4">
        <div className="w-10 h-10 bg-amber-100 text-amber-600 rounded-3xl flex justify-center items-center">
          <HiExclamation size={20} />
        </div>
        <div className="w-[calc(100%-56px)] flex flex-col justify-start items-center md:items-start gap-2">
          <h3 className="text-center md:text-left text-gray-900 text-lg font-semibold font-['Figtree'] leading-7">{title}</h3>
          <p className="text-center md:text-left text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">{description}</p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className="py-3 bg-gray-50 border-none">
      <div className="w-full flex flex-col-reverse md:flex-row justify-center md:justify-end items-center gap-3">
        <Button
          color="light"
          className="w-full md:w-fit"
          onClick={() => cancelBtnFn(false)}
          disabled={isLoading}
        >
          {cancelBtnName}
        </Button>          
        <Button
          color={`${ConfirmBtnColor === "red" ? "failure" : "blue"}`}
          className={`w-full md:w-fit ${ConfirmBtnColor === "red" ? "" : "bg-sky-500 hover:bg-sky-700"}`}
          onClick={confirmBtnFn}
          disabled={disabled || isLoading}
          isProcessing={isLoading}
        >
          {confirmBtnName}
        </Button>          
      </div>
    </Modal.Footer>
  </Modal>
  )
}

export default ConfirmModal