import { Button, Spinner } from "flowbite-react";
import { UseMutationResult, UseQueryResult } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../../public/ConfirmModal";
import { HiClipboardCopy } from "react-icons/hi";

export const renderHiddenToken = (token: string) => {
  let hiddenToken = token;
  let toReplace = hiddenToken.slice(1, -1);

  toReplace = toReplace.replace(/[a-z0-9]/gi, "*");

  return token.charAt(0) + toReplace + token.charAt(token.length - 1);
};

const AccessTokenBox = ({
  UpdateTokenMutation,
  query,
  disabled,
}: {
  UpdateTokenMutation: UseMutationResult<
    any,
    AxiosError<any, any>,
    any,
    unknown
  >;
  query: UseQueryResult<any, AxiosError<any, any>>;
  disabled: boolean;
}) => {
  const [t] = useTranslation();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showToken, setShowToken] = useState(false);

  const handleCopyToClipboard = (token: string) => {
    window.navigator.clipboard.writeText(token);

    toast.info("Copied");
  };

  const handleRegenerateToken = () => {
    setShowConfirmModal(true);
  };

  let content;

  if (query.isLoading) content = <Spinner />;

  if (query.isError)
    content = (
      <Button color="light" onClick={() => query.refetch()}>
        {t("dashboard.develop.accessTokenBox.btn-03")}
      </Button>
    );

  if (query.isSuccess)
    content = (
      <>
        <h5
          className="text-dl-blue fw-bold mb-3 access-token-box-1"
          onMouseDown={() => setShowToken(true)}
          onMouseUp={() => setShowToken(false)}
          style={{ cursor: "grab" }}
        >
          {showToken
            ? `${query?.data?.data.element.access_token}`
            : renderHiddenToken(query?.data?.data.element.access_token)}
        </h5>
        <div
          className="d-flex align-items-center justify-content-center flex-wrap"
          style={{ gap: "10px" }}
        >
          <Button
            outline
            gradientDuoTone="cyanToBlue"
            onClick={() =>
              handleCopyToClipboard(query?.data?.data.element.access_token)
            }
          >
            <HiClipboardCopy className="me-2" />
            {t("dashboard.develop.accessTokenBox.btn-04")}
          </Button>
          <Button onClick={handleRegenerateToken} disabled={disabled}>
            <i className="bi bi-hand-index-thumb me-2"></i>
            {t("dashboard.develop.accessTokenBox.btn-05")}
          </Button>
        </div>

        <ConfirmModal
          show={showConfirmModal}
          cancelBtnFn={() => setShowConfirmModal(false)}
          onClose={() => setShowConfirmModal}
          confirmBtnFn={() => UpdateTokenMutation.mutate(undefined)}
          title={t("dashboard.develop.accessTokenBox.title")}
          description={t("dashboard.develop.accessTokenBox.text-01")}
          confirmBtnName={t("dashboard.develop.accessTokenBox.btn-01")}
          cancelBtnName={t("dashboard.develop.accessTokenBox.btn-02")}
        />
      </>
    );

  return (
    <div className="dl-shadow-box text-center accessTokenBox">
      <h3 className="mb-3 fw-bold">
        {t("dashboard.develop.accessTokenBox.title-01")}
      </h3>
      <img
        title="Super User Manager"
        loading="lazy"
        src="https://cdn.1cdn.app/application/UMD/umd_accsess_token.svg"
        className="img-fluid"
        alt="access token"
        width={185}
        height={150}
      />
      {content}
    </div>
  );
};

export default AccessTokenBox;
