import CodeSnippetPart from "./CodeSnippetPart";

const DevTool = () => {
  return (
    <div>
      <CodeSnippetPart />
    </div>
  );
};

export default DevTool;
