import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Contexts from "../contexts/index";
import useAppLocalStorage from "../hooks/useAppLocalStorage";
import { authProvider } from "../services/auth";
import HttpService from "../services/http";
import { IUser, initialState } from "../contexts/types";

export const PermissionsMapper = {
  users_count_25: "users_count_25",
  users_count_1000: "users_count_1000",
  users_count_5000: "users_count_5000",
  users_count_unlimited: "users_count_unlimited",
  edit_profile: "edit_profile",
  email_confirmation_and_validation: "email_confirmation_and_validation",
  user_preferences: "user_preferences",
  user_data: "user_data",
  avatar_hosting: "avatar_hosting",
  segmentation: "segmentation",
  toggle_service: "toggle_service",
  expert_support: "expert_support",
};

export const can = (permissions: string[], permission: string): boolean => {
  if (permissions.includes(permission)) return true;
  else return false;
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const localStorage = useAppLocalStorage();

  const [isLoading, setIsLoading] = React.useState(true);
  let [user, setUser] = React.useState<IUser>(initialState);
  let [customer_identification, setCustomer_identification] =
    React.useState<string>("");

  const [upgradeNow, setUpgradeNow] = React.useState(false);
  const [userEndedSubscription, setUserEndedSubscription] =
    React.useState(false);

  useEffect(() => {
    let item = false;

    let sessionData: any = localStorage.getItem("_umd_service");

    if (sessionData) {
      item = true;

      const { exp, rememberMe, ...rest } = JSON.parse(sessionData);

      if (item && new Date(exp) >= new Date()) {
        setIsLoading(false);

        return authProvider.login(() => {
          setUser({ ...rest, isAuthenticated: true });
          setCustomer_identification(rest.userIdentification);
        });
      } else {
        setIsLoading(false);

        <Navigate to={"/logout"} />;

        return;
      }
    } else {
      setIsLoading(false);
    }

    return () => {
      item = false;
    };
  }, [localStorage]);

  let login = (data: any) => {
    if (data.rememberMe) {
      let exp = new Date();
      exp.setDate(exp.getDate() + 7);

      localStorage.setItem("_umd_service", JSON.stringify({ ...data, exp }));
    } else {
      let exp = new Date();
      exp.setDate(exp.getDate() + 1);

      localStorage.setItem("_umd_service", JSON.stringify({ ...data, exp }));
    }

    HttpService.setToken(data);

    authProvider.login(() => {
      setUser({ ...data, isAuthenticated: true });
      setCustomer_identification(data.userIdentification);
    });

    setIsLoading(false);
    return;
  };

  let logout = () => {
    return authProvider.logout(() => {
      setUser(initialState);
    });
  };

  let onOffUpgradeNow = (onOff: boolean) => {
    return authProvider.onOffUpgradeNow(() => {
      setUpgradeNow(onOff);
    });
  };

  let onOffUserEndedSubscription = (onOff: boolean) => {
    return authProvider.onOffUserEndedSubscription(() => {
      setUserEndedSubscription(onOff);
    });
  };

  let value = {
    user,
    isLoading,
    customer_identification,
    upgradeNow,
    userEndedSubscription,
    setUser,
    login,
    logout,
    onOffUpgradeNow,
    onOffUserEndedSubscription,
  };

  return (
    <Contexts.AuthContext.Provider value={value}>
      {children}
    </Contexts.AuthContext.Provider>
  );
};

export default AuthProvider;
