export const initialState = {
  isAuthenticated: false,
  accessToken: "",
  entityIdentification: "",
  entityStatus: "",
  isBasic: false,
  requiredPasswordUpdate: false,
  userIdentification: "",
  userLastConnection: "",
  is_trial: false,
  trial_left_days: 0,
  loggedInAt: "",
} as IUser;

export interface IUser {
  isAuthenticated: boolean;
  accessToken: string;
  entityIdentification: string;
  entityStatus: string;
  requiredPasswordUpdate: boolean;
  userIdentification: string;
  userLastConnection: string;
  customer_top_plan: string;
  customer_permissions: string[];
  is_trial: boolean;
  trial_left_days: number;
  isBasic: boolean;
  exp: string;
  rememberMe: boolean;
  loggedInAt: string;
}

export interface AuthContextType {
  isLoading: boolean;
  user: IUser;
  customer_identification: string;
  upgradeNow: boolean;
  userEndedSubscription: boolean;
  setUser: (user: IUser) => void;
  login: (credentials: any) => void;
  logout: () => void;
  onOffUpgradeNow: (onOff: boolean) => void;
  onOffUserEndedSubscription: (onOff: boolean) => void;
}

export interface StorageContextType {
  setItem: (item: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  clear: () => void;
  length: () => number;
}
