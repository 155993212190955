import { useLmcUserView } from "./hooks/useLmcUserView";
import LmcRewardPercentage from "./components/lmcRewardPages/LmcRewardPercentage";
import LmcRewardMessage from "./components/lmcRewardPages/LmcRewardMessage";
import LmcRewardDeadline from "./components/lmcRewardPages/LmcRewardDeadline";
import LmcRewardCurrency from "./components/lmcRewardPages/LmcRewardCurrency";
import LmcRewardVolume from "./components/lmcRewardPages/LmcRewardVolume";
import LmcRewardPoint from "./components/lmcRewardPages/LmcRewardPoint";
import LmcRewardIncremental from "./components/lmcRewardPages/LmcRewardIncremental";
import { useTranslation } from "react-i18next";

const LmcUserReward = () => {
  const { t } = useTranslation();

  const {
    state: {
      entity: { lmc_data, process_data, entity_data },
    },
  } = useLmcUserView();

  let content;

  switch (lmc_data?.lmc_type) {
    case "PERCENTAGE_CARD":
      content = (
        <LmcRewardPercentage
          percent={parseFloat(lmc_data.lmc_value[0].value)}
        />
      );
      break;
    case "HIST_MESSAGE_CARD":
      content = <LmcRewardMessage messages={lmc_data.lmc_value} />;
      break;
    case "DEADLINE_CARD":
      content = (
        <LmcRewardDeadline
          percent={parseFloat(lmc_data.lmc_value[0].value)}
          expiryInfo={{
            deadline: lmc_data.deadline,
            creationDate: lmc_data.lmc_value[0].date,
          }}
        />
      );
      break;
    case "AMOUNT_CARD":
      content = (
        <LmcRewardCurrency amount={parseFloat(lmc_data.lmc_value[0].value)} />
      );
      break;
    case "VOLUME_CARD":
      content = (
        <LmcRewardVolume amount={parseInt(lmc_data.lmc_value[0].value)} />
      );
      break;
    case "INCREMENTAL_CARD":
      content = (
        <LmcRewardIncremental amount={parseInt(lmc_data.lmc_value[0].value)} />
      );
      break;
    case "POINT_CARD":
      content = (
        <LmcRewardPoint point={parseInt(lmc_data.lmc_value[0].value)} />
      );
      break;

    default:
      break;
  }

  if (
    entity_data &&
    lmc_data &&
    process_data &&
    process_data.lmc_type !== lmc_data.lmc_type
  ) {
    return (
      <div className="w-full flex flex-col justify-start items-center">
        <div className="w-full py-20 px-4 flex flex-col justify-start items-center gap-4">
          <h2 className="text-center text-gray-50 text-3xl font-semibold font-['Figtree'] leading-7">
            {t("lmcUser.key7")}
          </h2>
          <h3 className="text-center text-white text-xl font-extrabold font-['Figtree'] leading-[60px]">
            {t("lmcUser.key8")} {entity_data?.entity_name} {t("lmcUser.key9")}{" "}
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-start items-center">
      {content}
    </div>
  );
};

export default LmcUserReward;
