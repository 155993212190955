import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { useState } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Badge, Button, Spinner } from "flowbite-react";

const EntityStatusChanger = () => {
  const [t] = useTranslation();

  const { user, customer_identification, userEndedSubscription } = useAuth();

  const [isChecked, setIsChecked] = useState(false);

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    "entity-stats",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/status`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (payload) => {
        setIsChecked(
          payload.data.element.entityStatus === "SERVICE_OFF" ? false : true
        );
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateEntityStatusMutation = useMutation<
    any,
    AxiosError<any, any>,
    any
  >(
    (isDisable) =>
      HttpService.patch(
        `/${customer_identification}/${
          user.entityIdentification
        }/entity/status/${isDisable ? "SERVICE_OFF" : "SERVICE_ON"}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("entity");
        queryClient.invalidateQueries("entity-check");
        queryClient.invalidateQueries("entity-stats");

        res?.data?.element?.entityStatus === "SERVICE_OFF"
          ? toast.success(`Service is ON`)
          : toast.warning(`Service is OFF`);
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleToggleEntityStatus = () => {
    setIsChecked(!isChecked);

    UpdateEntityStatusMutation.mutate(isChecked);
  };

  const renderStatusUi = (status: string) => {
    const onOffButton = (
      <Button
        color="blue"
        className="bg-sky-500"
        onClick={handleToggleEntityStatus}
        disabled={userEndedSubscription}
      >
        {status === "SERVICE_ON"
          ? t("dashboard.entityStatusChanger.btn-05")
          : t("dashboard.entityStatusChanger.btn-06")}
      </Button>
    );
    switch (status) {
      case "NEW":
        return (
          <Button
            color="blue"
            className="bg-sky-500"
            disabled={userEndedSubscription}
          >
            <b>{t("dashboard.entityStatusChanger.text-01")}</b>{" "}
            {t("dashboard.entityStatusChanger.text-02")}
          </Button>
        );
      case "SERVICE_ON":
        return (
          <div className="flex flex-col justify-start items-start md:flex-row md:justify-end md:items-center gap-5">
            <Badge color="success" className="rounded-full">
              <div className="py-[2px] px-2 flex justify-center items-center gap-[6px]">
                <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full" />
                <span className="text-center text-sm font-medium font-['Inter']">
                  {t("dashboard.entityStatusChanger.text-05")}
                </span>
              </div>
            </Badge>
            {onOffButton}
          </div>
        );
      case "SERVICE_OFF":
        return (
          <div className="flex flex-col justify-start items-start md:flex-row md:justify-end md:items-center gap-5">
            <Badge color="failure" className="rounded-full">
              <div className="py-[2px] px-2 flex justify-center items-center gap-[6px]">
                <div className="w-1.5 h-1.5 bg-red-400 rounded-full" />
                <span className="text-center  text-sm font-medium font-['Inter']">
                  {t("dashboard.entityStatusChanger.text-06")}
                </span>
              </div>
            </Badge>
            {onOffButton}
          </div>
        );
      case "CANCELED":
        return (
          <Button
            color="blue"
            className="bg-sky-500"
            disabled={userEndedSubscription}
          >
            {t("dashboard.entityStatusChanger.btn-02")}
          </Button>
        );
      default:
        return (
          <Button
            color="blue"
            className="bg-sky-500"
            disabled={userEndedSubscription}
          >
            {t("dashboard.entityStatusChanger.btn-03")} <b>{status}</b>
          </Button>
        );
    }
  };

  let content;

  if (isLoading) content = <Spinner size="sm" />;

  if (isError)
    content = (
      <Button onClick={() => refetch()} size="sm">
        {t("dashboard.entityStatusChanger.btn-04")}
      </Button>
    );

  if (isSuccess) {
    content = renderStatusUi(res.data.element.entityStatus);
  }

  return (
    <div className="w-full p-5 bg-white rounded-lg shadow flex flex-col md:flex-row md:justify-between md:items-center gap-5">
      <div>
        <h3 className="text-gray-700 text-lg font-semibold font-['Figtree']">
          {t("dashboard.entityStatusChanger.title")}
        </h3>
        <p className="mt-2 text-gray-700 text-sm font-normal font-['Figtree']">
          {t("dashboard.entityStatusChanger.title-01")}
        </p>
      </div>
      {/* <div>
        {res?.data?.element?.entityStatus === "SERVICE_OFF" ? (
          <img
            title="Super User Manager"
            loading="lazy"
            src="https://cdn.1cdn.app/application/UMD/umd_service_is_off.svg"
            className="img-fluid"
            alt="service is off"
            style={{ marginBottom: "10px" }}
            width={251}
            height={150}
          />
        ) : (
          <img
            title="Super User Manager"
            loading="lazy"
            src="https://cdn.1cdn.app/application/UMD/umd_service_is_on.svg"
            alt="service is on"
            className="img-fluid"
            style={{ marginBottom: "10px" }}
            width={251}
            height={150}
          />
        )}
      </div> */}
      {content}
    </div>
  );
};

export default EntityStatusChanger;
