import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import { Button, Spinner } from "flowbite-react";
import LoginModal from "./LoginModal";
import PageNotWorking from "./PageNotWorking";

const LoginViaLink = ({ externalPage = false }: { externalPage?: boolean }) => {
  const [t] = useTranslation();
  const { login_code } = useParams();
  const [entity_data, setEntity_data] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const { isLoading, isError, isSuccess, refetch } = useQuery(
    "process-data",
    () => HttpService.get(`/entity/process/${login_code}`),
    {
      onSuccess({ data }) {
        setEntity_data({ ...data?.element?.entity_data });
      },
    }
  );

  useEffect(() => {
    if (isSuccess) setShowModal(true);
  }, [isSuccess]);

  const LoginUserMutation = useMutation<any, AxiosError<any, any>>(
    (credentials: any) =>
      HttpService.post(
        `/auth/${entity_data?.entity_identification}/login-page/login`,
        {
          user_email: credentials.user_email,
          user_password: credentials.user_password,
        }
      ),
    {
      onError: (err) => {
        localStorage.removeItem("_umd_credential");

        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleLoginViaLink = (loginData: any) => {
    const credentials: any = {
      user_email: loginData?.user_email,
      user_password: loginData?.user_password,
    };
    LoginUserMutation.mutate(credentials, {
      onSuccess: (res) => {
        window.location.replace(`${res?.data?.element?.loginFormCallback}`);
      },
    });
  };

  if (isLoading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center gap-1">
        {t("dashboard.features.loginTemplate.LoginViaLink.loading")}
        <Spinner size="sm" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="dl-shadow-box mb-5 code-gen">
        <span>{t("dashboard.features.loginTemplate.LoginViaLink.text1")}</span>
        <Button color="light" onClick={() => refetch()} className="ms-1">
          {t("dashboard.features.loginTemplate.LoginViaLink.text2")}
        </Button>
      </div>
    );
  }

  if (entity_data?.entity_status === "SERVICE_OFF") {
    return <PageNotWorking />;
  }

  return (
    <div className="w-full min-h-screen py-6 px-2 bg-gray-500 bg-opacity-75 flex justify-center items-start loginViaLink">
      {isSuccess && (
        <LoginModal
          showModal={showModal}
          serviceData={{
            name: entity_data?.entity_name,
            logoUrl: entity_data?.entity_logo,
          }}
          isRealLogin={true}
          handleLoginViaLink={handleLoginViaLink}
          externalPage={externalPage}
        />
      )}
    </div>
  );
};

export default LoginViaLink;
