import { useLocation, useNavigate, useParams } from "react-router-dom";
import useIsAuthenticate from "./hooks/useIsAuthenticate";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import SocialAuth from "./SocialAuth";
import { IResolveParams } from "reactjs-social-login";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../services/http";
import { toast } from "react-toastify";
import { LmcActionKind } from "./contexts";
import { HiArrowLeft, HiCalendar } from "react-icons/hi";
import { TextInput, Button, Label } from "flowbite-react";
import { lmcInputClass } from "./Index";
import moment from "moment";
import { LanguageChanger } from "../dashboard/Layout";
import { useTranslation } from "react-i18next";

type RegisterType = {
  user_user_name?: string;
  user_first_name?: string;
  user_last_name?: string;
  user_email: string;
  user_birth_date?: string;
  user_country_code?: string;
  user_password: string;
  user_confirm_password: string;
  user_role: string;
  user_type: string;
};

const LmcUserViewRegister = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { processId } = useParams();
  const isAuthenticated = useIsAuthenticate();
  const location = useLocation();

  const {
    state: {
      entity: { entity_data },
    },
    dispatch,
  } = useLmcUserView();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterType>({
    defaultValues: {
      user_user_name: "",
      user_first_name: "",
      user_last_name: "",
      user_email: location?.state?.user_email || "",
      user_birth_date: "",
      user_country_code: "",
      user_password: location?.state?.user_password || "",
      user_confirm_password: location?.state?.user_password || "",
      user_role: "default",
      user_type: "default",
    },
  });

  const birthdayRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register("user_birth_date");

  // const [toggleDateType, setToggleDateType] = useState<"text" | "date">("text");
  const [apiErrors, setApiErrors] = useState<{
    user_birth_date: string;
    user_email: string;
    user_first_name: string;
    user_last_name: string;
    user_user_name: string;
  }>();

  const RegisterUserMutation = useMutation<any, AxiosError<any, any>, any>(
    (data: any) =>
      HttpService.post(
        `/auth/${entity_data?.entity_identification}/register`,
        data
      ),
    {
      onError(error, variables, context) {
        setApiErrors(error.response?.data.element);
        if (error?.response?.data?.code === "RSUSKO007") {
          setApiErrors((prev: any) => ({
            ...error?.response?.data?.message,
          }));

          for (const [key, value] of Object.entries(
            error?.response?.data?.message
          )) {
            setError(key as keyof RegisterType, {
              type: "manual",
              message: value as string,
            });
          }
        }

        toast.error(error.response?.data.message);
      },
    }
  );

  const socialAuthLoginMutation = useMutation<
    any,
    AxiosError<any, any>,
    { type: "login"; providerPayload: any }
  >((data) =>
    HttpService.post(
      `/auth/${entity_data?.entity_identification}/social/login`,
      data
    )
  );

  const handleLoginStartSocialAuth = () => {};

  const handleResolveSocialAuth = (
    provider: IResolveParams["provider"],
    data: IResolveParams["data"]
  ) => {
    const cpPayload = {
      access_token:
        provider === "google"
          ? data?.access_token
          : data?.authorization?.id_token,
      provider,
    };

    socialAuthLoginMutation.mutate(
      { type: "login", providerPayload: cpPayload },
      {
        onSuccess(data, variables, context) {
          dispatch({
            type: LmcActionKind.LOGIN,
            payload: data.data.element,
          });
        },
        onError(error, variables, context) {
          toast.error(error.response?.data.message || error.message);
        },
      }
    );
  };

  const handleRejectSocialAuth = (err: any) => {
    toast.error("Unexpected error happened. Try again later.");
  };

  useEffect(() => {
    if (isAuthenticated)
      navigate(`/process/${processId}/dashboard`, { replace: true });

    if (!location?.state?.user_email) navigate(`/process/${processId}/login`);
  }, [isAuthenticated, location?.state?.user_email, navigate, processId]);

  // function objectFilter = (obj: any, predicate: any): any =>
  //   Object.keys(obj)
  //         .filter( key => predicate(obj[key]) )
  //         .reduce( (res: any, key: any) => (res[key] = obj[key], res), {} );

  const objectFilter = (
    obj: Record<string, unknown>,
    predicate: (value: unknown) => boolean
  ): Record<string, unknown> =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ({ ...res, [key]: obj[key] }), {});

  const onSubmit = (data: RegisterType) => {
    const filtered = objectFilter(data, (item) => item !== "");
    RegisterUserMutation.mutate(filtered, {
      onSuccess(data, variables, context) {
        dispatch({
          type: LmcActionKind.LOGIN,
          payload: { ...data.data.element, password: filtered.user_password },
        });
      },
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full md:w-[400px] mx-auto bg-gray-900 flex flex-col justify-start items-center min-h-screen">
      <header className="w-full h-[204px] px-4 pt-5 pb-8 bg-gray-800 rounded-[32px] rounded-tr-[32px] flex-col justify-between items-center inline-flex">
        <div className="w-full flex justify-between items-center gap-3 cursor-pointer">
          <div className="flex items-center gap-3" onClick={handleGoBack}>
            <HiArrowLeft size={20} className="text-gray-400" />
            <span className="text-gray-400 text-base font-normal font-['Figtree'] leading-normal">
              {t("lmcUser.key26")}
            </span>
          </div>
          <LanguageChanger i18n={i18n} className="bg-transparent" />
        </div>
        <div className="flex-col justify-end items-center inline-flex">
          <div className="w-16 h-16 overflow-hidden">
            <img
              src={entity_data?.entity_logo}
              alt={entity_data?.entity_name}
              className="w-16 h-16 object-cover"
            />
          </div>
          <h1 className="mt-3 text-gray-200 text-2xl font-bold font-['Figtree'] ">
            {t("lmcUser.key27")} {entity_data?.entity_name}
          </h1>
        </div>
      </header>

      <main className="w-full p-4 flex flex-col justify-start items-center gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col justify-start items-center gap-6"
          autoComplete="off"
        >
          <div className="w-full">
            <Label
              htmlFor="user_first_name"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key28")}
            </Label>
            <TextInput
              id="user_first_name"
              type="text"
              placeholder={t("lmcUser.key29").toString()}
              className={lmcInputClass}
              color={
                !!errors.user_first_name || !!apiErrors?.user_first_name
                  ? "failure"
                  : undefined
              }
              {...register("user_first_name")}
              helperText={
                errors.user_first_name?.message || apiErrors?.user_first_name
              }
            />
          </div>

          <div className="w-full">
            <Label
              htmlFor="user_last_name"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key30")}
            </Label>
            <TextInput
              id="user_last_name"
              type="text"
              placeholder={t("lmcUser.key31").toString()}
              className={lmcInputClass}
              color={
                !!errors.user_last_name || !!apiErrors?.user_last_name
                  ? "failure"
                  : undefined
              }
              {...register("user_last_name")}
              helperText={
                errors.user_last_name?.message || apiErrors?.user_last_name
              }
            />
          </div>

          <div className="w-full">
            <Label
              htmlFor="user_user_name"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key32")}
            </Label>
            <TextInput
              id="user_user_name"
              type="text"
              placeholder={t("lmcUser.key33").toString()}
              className={lmcInputClass}
              color={
                !!errors.user_user_name || !!apiErrors?.user_user_name
                  ? "failure"
                  : undefined
              }
              {...register("user_user_name")}
              helperText={
                errors.user_user_name?.message || apiErrors?.user_user_name
              }
            />
          </div>

          <div className="w-full">
            <Label
              htmlFor="user_birth_date"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key34")}
            </Label>
            <div className="relative">
              <TextInput
                {...rest}
                id="user_birth_date"
                // type={toggleDateType}
                type={"date"}
                placeholder={t("lmcUser.key35").toString()}
                className={lmcInputClass}
                ref={(e) => {
                  ref(e);
                  birthdayRef.current = e;
                }}
                color={
                  !!errors.user_birth_date || !!apiErrors?.user_birth_date
                    ? "failure"
                    : undefined
                }
                // onFocus={() => setToggleDateType("date")}
                // {...register(
                //   "user_birth_date"
                //   , {
                //   onBlur(event) {
                //     setToggleDateType("text");
                //   },
                // }
                // )}
                helperText={
                  errors.user_birth_date?.message || apiErrors?.user_birth_date
                }
                max={moment().format("YYYY-MM-DD")}
              />
              <button
                className="w-5 h-5 absolute right-2 top-3"
                type="button"
                onClick={() => {
                  birthdayRef.current?.focus();
                  birthdayRef.current?.showPicker();
                }}
              >
                <HiCalendar size={20} color="white" />
              </button>
            </div>
          </div>

          <Button
            className="w-full h-11 bg-sky-400 rounded-[100px] text-neutral-900"
            type="submit"
          >
            {t("lmcUser.key36")}
          </Button>
        </form>

        <div className="my-4">
          <p className="text-center text-gray-200 text-xs font-bold font-['Figtree'] uppercase leading-none">
            {t("lmcUser.key37")}
          </p>
        </div>

        <div className="w-full flex-col justify-between items-center flex">
          <SocialAuth
            className="w-full"
            providers={["Google", "Apple"]}
            onLoginStartSocialAuthStart={handleLoginStartSocialAuth}
            onResolveSocialAuth={handleResolveSocialAuth}
            onRejectSocialAuth={handleRejectSocialAuth}
            providersIcon={{
              Apple: (
                <div className="bg-gray-700 rounded-[100px] px-[10px] w-full h-11 flex flex-row justify-center items-center cursor-pointer relative mb-4">
                  <img
                    src="../../images/apple.svg"
                    alt="Sign up with Google"
                    className="w-6 h-6 absolute top-[10px] left-[10px]"
                  />
                  <p className="text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("lmcUser.key60")}
                  </p>
                </div>
              ),
              Google: (
                <div className="bg-gray-700 rounded-[100px] px-[10px] w-full h-11 flex flex-row justify-center items-center cursor-pointer relative mb-4">
                  <img
                    src="../../images/google.svg"
                    alt="Sign up with Google"
                    className="w-6 h-6 absolute top-[10px] left-[10px]"
                  />
                  <p className="text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("lmcUser.key38")}
                  </p>
                </div>
              ),
            }}
          />
        </div>
      </main>
    </div>
  );
};

export default LmcUserViewRegister;
