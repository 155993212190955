import { createContext } from "react";

type lmcOptionType =
  | "POINT_CARD"
  | "HIST_MESSAGE_CARD"
  | "PERCENTAGE_CARD"
  | "INCREMENTAL_CARD"
  | "AMOUNT_CARD"
  | "DEADLINE_CARD"
  | "VOLUME_CARD";

interface INormalizedLmcDataType {
  lmc_type: lmcOptionType;
  scanner_auto_refresh: boolean;
  login_form: {
    user_first_name: boolean;
    user_last_name: boolean;
    user_birth_date: boolean;
    user_country_code: boolean;
    user_username: boolean;
  };
  pre_defined_value: {
    type: string;
    values: Array<number> | [];
    default: number | string;
    currency: null | string;
  };
  comment: string;
  options: {
    [key: string]: any;
  };
}

interface IEntity {
  entity_name: string;
  entity_description: string;
  entity_identification: string;
  entity_status: string;
  entity_logo: string;
}

interface ILmc {
  lmc_value: Array<{ date: string; value: string }>;
  deadline: string;
  lmc_postfix: string;
  lmc_type: string;
}

interface IProfile {
  user_name?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  birth_date?: string | null;
  country_code?: string | null;
  last_connection: string;
  avatar: string;
  is_active: boolean;
  creation_date_time: string | null;
  phone: string | null;
  user_account_status: string | null;
}

type EntityType = {
  process_data: INormalizedLmcDataType | null;
  entity_data: IEntity | null;
  lmc_data: ILmc | null;
};

type AuthType = {
  isAuthenticated: boolean;
  userIdentification: string | null;
  userRole: string | null;
  userLastConnection: string | null;
  requiredPasswordUpdate: boolean | false;
  userIsActive: boolean | false;
  lmcOptionRewardDetail: { [key: string]: any } | null;
  profile: IProfile;
  loggedInAt: string;
  password: string;
};

export enum LmcActionKind {
  ENTITY = "ENTITY",
  LOGIN = "LOGIN",
  LMCDATA = "LMCDATA",
}

export interface ILmcUserViewContext {
  entity: EntityType;
  auth: AuthType;
}

export type DispatchType = {
  type: LmcActionKind;
  payload: any;
};

export const LmcUserViewContext = createContext<{
  state: ILmcUserViewContext;
  dispatch: React.Dispatch<DispatchType>;
}>(null!);
