import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError, AxiosResponse } from "axios";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { useTranslation } from "react-i18next";
import { FaX } from "react-icons/fa6";
import { Button } from "flowbite-react";

type ManageCustomerGroupsType = {
  data: string[] | [];
  user_identification: string;
  customerId: string;
  entity_identification: string;
};

const schema = Joi.object({
  group_identification: Joi.string().trim().required().label("Group"),
});

const AddUserToGroupForm = ({
  customerId,
}: {
  customerId: string | undefined;
}) => {
  const [t] = useTranslation();
  const { user, customer_identification } = useAuth();

  const { register, handleSubmit } = useForm({
    resolver: joiResolver(schema),
  });

  const queryClient = useQueryClient();

  const { data: groupsRes } = useQuery(
    "groups",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/group`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const AddUserToSpecifiedGroup = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/user/${customerId}/group/${data.group_identification}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        toast.success(res.data.message);

        queryClient.invalidateQueries(["entity_user", customerId]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onSubmit = (values: any) => {
    AddUserToSpecifiedGroup.mutate(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="self-stretch justify-start items-start gap-3 flex">
        <select
          aria-label="ownerGroups"
          className="w-full pl-[17px] pr-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center inline-flex"
          {...register("group_identification")}
        >
          <option value="">
            {t("dashboard.entities.manageCustomerGroups.selectText")}
          </option>
          {groupsRes?.data.element.groups.map((gr: any, index: number) => (
            <option value={gr.group_identification} key={index}>
              {gr.group_name}
            </option>
          ))}
        </select>

        <Button
          className="bg-sky-500 h-[44px] px-[17px] py-[9px]rounded-md shadow justify-center items-center inline-flex"
          type="submit"
          disabled={
            groupsRes?.data.element.groups.length <= 0 ||
            AddUserToSpecifiedGroup.isLoading
          }
          isProcessing={AddUserToSpecifiedGroup.isLoading}
        >
          {t("dashboard.entities.manageCustomerGroups.add")}
        </Button>
      </div>
    </form>
  );
};

const ManageCustomerGroups = ({
  data,
  customerId,
  user_identification,
  entity_identification,
}: ManageCustomerGroupsType) => {
  const [t] = useTranslation();

  const queryClient = useQueryClient();

  const DeleteGroupFromUserMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    (groupId) =>
      HttpService.delete(
        `/${user_identification}/${entity_identification}/user/${customerId}/group/${groupId}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("User is deleted from group");
        queryClient.invalidateQueries(["entity_user", customerId]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleRemoveGroupFromUser = (groupName: string) => {
    queryClient
      .fetchQuery("groups", () =>
        HttpService.get(
          `/${user_identification}/${entity_identification}/group`,
          {
            auth: HttpService.getToken(),
          }
        )
      )
      .then((res) => {
        let group_identification = res.data.element.groups.find(
          (item: any) => item.group_name === groupName
        ).group_identification;

        if (group_identification)
          DeleteGroupFromUserMutation.mutate(group_identification);
        else toast.error("Group id not valid");
      })
      .catch((err: any) => {
        toast.error(err.response.data.message || err.message);
      });
  };

  return (
    <div className="w-full">
      <div className="self-stretch flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.entities.manageCustomerGroups.title")}
        </div>

        {data.length > 0 && (
          <div className="justify-start items-start gap-2 inline-flex flex-wrap">
            {data.map((g: string, index: number) => (
              <div
                key={index}
                className="px-2.5 py-0.5 bg-gray-100 rounded-xl justify-center items-center gap-0.5 flex"
              >
                <div className="text-center text-gray-800 text-sm font-medium font-['Inter'] leading-tight">
                  {g}
                </div>

                <button onClick={() => handleRemoveGroupFromUser(g)}>
                  <FaX size={11} className="text-gray-400" />
                </button>
              </div>
            ))}
          </div>
        )}

        <AddUserToGroupForm customerId={customerId} />
      </div>
    </div>
  );
};

export default ManageCustomerGroups;
