import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
  const localStorage = useAppLocalStorage();

  const auth = useAuth();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    localStorage.removeItem("_umd_credential");
    localStorage.removeItem("_umd_oauth_credential");
    localStorage.removeItem("_umd_service");
    localStorage.removeItem("umd_users_list_limit");

    auth.logout();

    queryClient.clear();

    navigate("/");
  }, [auth, navigate, queryClient, localStorage]);

  return null;
};

export default Logout;
