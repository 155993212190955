import React, { useReducer } from "react";
import {
  DispatchType,
  ILmcUserViewContext,
  LmcActionKind,
  LmcUserViewContext,
} from "../contexts";

const lmcReducer = (
  state: ILmcUserViewContext,
  action: DispatchType
): ILmcUserViewContext => {
  const { type, payload } = action;

  switch (type) {
    case LmcActionKind.ENTITY:
      return {
        ...state,
        entity: { ...payload },
      };

    case LmcActionKind.LOGIN:
      return {
        ...state,
        auth: {
          isAuthenticated: true,
          loggedInAt: new Date().toISOString(),
          ...payload,
        },
      };

    case LmcActionKind.LMCDATA:
      return {
        ...state,
        auth: {
          ...state.auth,
          profile: { ...payload.user },
        },
        entity: {
          ...state.entity,
          lmc_data: { ...payload.data },
        },
      };
    default:
      return state;
  }
};

const LmcUserViewProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(lmcReducer, initialState);

  return (
    <LmcUserViewContext.Provider value={{ state, dispatch }}>
      {children}
    </LmcUserViewContext.Provider>
  );
};

const initialState: ILmcUserViewContext = {
  auth: {
    isAuthenticated: false,
    profile: {
      avatar: "",
      email: "",
      is_active: false,
      last_connection: "",
      birth_date: null,
      country_code: null,
      first_name: null,
      last_name: null,
      user_name: null,
      creation_date_time: null,
      phone: null,
      user_account_status: null,
    },
    lmcOptionRewardDetail: null,
    requiredPasswordUpdate: false,
    userIdentification: null,
    userIsActive: false,
    userLastConnection: null,
    userRole: null,
    loggedInAt: "",
    password: "",
  },
  entity: {
    entity_data: null,
    process_data: null,
    lmc_data: null,
  },
};

export default LmcUserViewProvider;
