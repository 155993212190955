import { Button } from "flowbite-react";
import useAuth from "../../../../hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
import { useTranslation } from "react-i18next";

const WebsiteCondition = () => {
  const [t] = useTranslation();
  const { customer_identification, user, setUser, userEndedSubscription } =
    useAuth();
  const localStorage = useAppLocalStorage();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isSuccess,
    data: res,
  } = useQuery(
    ["user-preferences", customer_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdatePreferencesMutation = useMutation<any, AxiosError<any, any>, any>(
    (json) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/preferences`,
        { user_data: json },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        user.isBasic === true
          ? toast.success("Advanced dashboard")
          : toast.success("Basic dashboard");

        queryClient.invalidateQueries([
          "user-preferences",
          customer_identification,
        ]);

        setUser({ ...user, isBasic: !user.isBasic });

        let sessionData: any = localStorage.getItem("_umd_service");

        if (sessionData) {
          const newAuthData = {
            ...JSON.parse(sessionData),
            isBasic: !user.isBasic,
          };

          localStorage.setItem("_umd_service", JSON.stringify(newAuthData));
        }
      },
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  return (
    <div className="flex justify-center">
      {user.isBasic === true ? (
        <Button
          color="gray"
          disabled={
            isLoading ||
            UpdatePreferencesMutation.isLoading ||
            userEndedSubscription
          }
          onClick={() =>
            isSuccess &&
            UpdatePreferencesMutation.mutate({
              ...res?.data.element.user_data_preferences,
              is_basic: !user.isBasic,
            })
          }
          isProcessing={UpdatePreferencesMutation.isLoading}
        >
          {t("dashboard.develop.websiteCondition.btn-01")}
        </Button>
      ) : (
        <Button
          color="gray"
          disabled={
            isLoading ||
            UpdatePreferencesMutation.isLoading ||
            userEndedSubscription
          }
          onClick={() =>
            isSuccess &&
            UpdatePreferencesMutation.mutate({
              ...res?.data.element.user_data_preferences,
              is_basic: !user.isBasic,
            })
          }
          isProcessing={UpdatePreferencesMutation.isLoading}
        >
          {t("dashboard.develop.websiteCondition.btn-02")}
        </Button>
      )}
    </div>
  );
};

export default WebsiteCondition;
