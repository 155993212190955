import React from "react";
// import './errorPages.css';

const ErrorPagesLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center error-pages">
      <div className="text-center">{children}</div>
    </div>
  );
};

export default ErrorPagesLayout;
