import useAuth from "../../../hooks/useAuth";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient, UseQueryResult } from "react-query";
import { JsonEditor } from "jsoneditor-react";
import { toast } from "react-toastify";
import HttpService from "../../../services/http";
import { AxiosError } from "axios";
import Ajv from "ajv";
import ace from "brace";
import { useTranslation } from "react-i18next";
import { Button, Select } from "flowbite-react";

import "brace/mode/json";
import "brace/theme/github";

import "jsoneditor-react/es/editor.min.css";
import { HiPlusCircle, HiTrash } from "react-icons/hi";
import ConfirmModal from "../../public/ConfirmModal";

const ajv = new Ajv({ allErrors: true, verbose: true });

export const ControlledJsonEditor = ({
  value,
  onChange,
}: {
  value: any;
  onChange: (vale: any) => void;
}) => {
  const jsonEditorRef = useRef<any>();

  // useEffect(() => {
  //   const editor =
  //     jsonEditorRef &&
  //     jsonEditorRef.current &&
  //     jsonEditorRef.current.jsonEditor;
  //   if (editor && value) {
  //     editor.update(value);
  //   }
  // }, [jsonEditorRef, value]);

  const onResize = useCallback((newHeight: number) => {
    const editor = jsonEditorRef?.current?.jsonEditor;

    if (editor) editor.resize();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (onResize) {
        onResize(entries[0].contentRect.height);
      }
    });

    if (
      jsonEditorRef.current &&
      jsonEditorRef.current.htmlElementRef &&
      React.isValidElement(jsonEditorRef.current.htmlElementRef)
    )
      resizeObserver.observe(jsonEditorRef.current.htmlElementRef);

    return () => resizeObserver.disconnect();
  }, [onResize]);

  return (
    <JsonEditor
      ref={jsonEditorRef}
      value={value}
      onChange={onChange}
      schema={{}}
      mode={"code"}
      search={true}
      ajv={ajv}
      ace={ace}
      theme="ace/theme/github"
    />
  );
};

const UserEntityData = ({
  entity_identification,
  customerId,
  isEditable = false,
  query,
  isUser,
}: {
  entity_identification: string;
  customerId: string;
  isEditable?: boolean;
  isUser: boolean;
  query: UseQueryResult<any, AxiosError<any, any>>;
}) => {
  const { t } = useTranslation();
  const { customer_identification, user, userEndedSubscription } = useAuth();

  const inputTextRef = useRef<HTMLInputElement | null>(null);

  const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
  const [isNewKeyProcess, setIsNewKeyProcess] = useState<boolean>(false);
  const [selectOptionValue, setSelectOptionValue] = useState<string>("");
  const [inputTextValue, setInputTextValue] = useState<string>("");
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const clientQuery = useQueryClient();

  const useGetJsonKeyValues = useMutation<any, AxiosError<any, any>, string>(
    (key: string) =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customerId}/data/${key}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess(data, variables, context) {
        setUpdatedData(
          variables === ""
            ? {}
            : data?.data?.element?.user_data[variables] ?? {}
        );
      },

      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateDataMutation = useMutation<any, AxiosError<any, any>, any>(
    (json) =>
      HttpService.patch(
        `/${customer_identification}/${entity_identification}/user/${customerId}/data/${selectOptionValue}`,
        { user_data: json },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Data updated successfully");

        clientQuery.invalidateQueries(["user-data", customerId]);

        setInputTextValue("");
        setSelectOptionValue("");
        setIsNewKeyProcess(false);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const createNewKeyMutation = useMutation<
    any,
    AxiosError<any, any>,
    { key: string; value: Record<string, any> }
  >(
    ({ key, value }) =>
      HttpService.post(
        `/${customer_identification}/${entity_identification}/user/${customerId}/data/${key}`,
        { user_data: value },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Data updated successfully");

        clientQuery.invalidateQueries(["user-data", customerId]);

        setInputTextValue("");
        setSelectOptionValue("");
        setIsNewKeyProcess(false);
        setUpdatedData({});
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const deleteKeyMutation = useMutation<any, AxiosError<any, any>, string>(
    (key) =>
      HttpService.delete(
        `/${customer_identification}/${entity_identification}/user/${customerId}/data/${key}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Data updated successfully");

        clientQuery.invalidateQueries(["user-data", customerId]);

        setInputTextValue("");
        setSelectOptionValue("");
        setIsNewKeyProcess(false);
        setUpdatedData({});
        setShowConfirm(false);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleChangeJson = (v: any) => {
    setUpdatedData(v);
  };

  const sanitizeAndTrim = (text: string, allowedChars = "_-") => {
    // 1. Trim leading and trailing whitespace
    const trimmedText = text
      // .trim()
      .replace(/\s+/g, " ")
      .replace(/[^\w\s-_]/g, "");
    // const trimmedText = text;

    // 2. Create a regular expression to match disallowed characters
    const disallowedChars = `[^a-zA-Z0-9${allowedChars}\\s]`; // Exclude allowed chars and whitespace
    const regex = new RegExp(disallowedChars, "g");

    // 3. Replace disallowed characters with an empty string
    const sanitizedText = trimmedText.replace(regex, "");

    return sanitizedText;
  };

  const handleAddNewKey = (value: string) => {
    if (value === "") {
      setInputTextValue("");
      setUpdatedData({});
    } else {
      console.log(value);

      setInputTextValue(sanitizeAndTrim(value));
    }
  };

  const handleUpdateData = () => {
    if (typeof updatedData !== "object")
      return toast.error(t("dashboard.userEntityData.key8"));

    if (isNewKeyProcess && inputTextValue)
      return createNewKeyMutation.mutate({
        key: inputTextValue,
        value: updatedData,
      });

    if (!isNewKeyProcess && selectOptionValue)
      UpdateDataMutation.mutate(updatedData);
  };

  const handleDeleteKEy = () => {
    deleteKeyMutation.mutate(selectOptionValue);
  };

  useEffect(() => {
    if (selectOptionValue !== "") useGetJsonKeyValues.mutate(selectOptionValue);
    else setUpdatedData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionValue]);

  useEffect(() => {
    inputTextRef.current?.focus();
  }, [isNewKeyProcess]);

  let content;

  if (query.isLoading) content = <p>Loading...</p>;

  if (query.isError)
    content = (
      <Button onClick={() => query.refetch()}>
        {t("dashboard.entityData.btn-01")}
      </Button>
    );

  if (query.isSuccess)
    content = (
      <React.Fragment>
        <div className="grid grid-cols-12 xl:gap-3 gap-6 items-center mb-6">
          <div className="xl:col-span-8 col-span-12">
            {isNewKeyProcess ? (
              <div
                className={`xl:grow w-full h-[38px] px-[17px] py-[9px] rounded-md shadow border-2 ${
                  isNewKeyProcess
                    ? "border-sky-500 caret-sky-500"
                    : "border-sky-100"
                } justify-between items-center inline-flex`}
              >
                <input
                  type="text"
                  className="grow md:max-w-full sm:w-max w-[200px] border-0 bg-transparent shadow-none outline-none focus:ring-0"
                  onChange={(e) => handleAddNewKey(e.target.value)}
                  value={inputTextValue}
                  ref={inputTextRef}
                  onKeyDown={(e) => handleAddNewKey(e.currentTarget.value)}
                />
                <div className="justify-end items-center gap-6 flex">
                  <button
                    onClick={() => {
                      setInputTextValue("");
                      setSelectOptionValue("");
                      setIsNewKeyProcess(false);
                    }}
                    className="text-sky-500 text-sm font-semibold font-['Figtree'] leading-tight"
                  >
                    {t("dashboard.userEntityData.key3")}
                  </button>
                  {/* <button
                  onClick={handleAddNewKey}
                  className="text-sky-500 text-sm font-semibold font-['Figtree'] leading-tight"
                >
                  {t("dashboard.userEntityData.key4")}
                </button> */}
                </div>
              </div>
            ) : (
              <Select
                className="self-stretch h-[38px]"
                onChange={(e) => setSelectOptionValue(e.target.value)}
                value={selectOptionValue}
              >
                <option value={""}>{t("dashboard.userEntityData.key5")}</option>
                {query?.data?.data?.element?.user_data.map((key: string) => (
                  <option key={key}>{key}</option>
                ))}
              </Select>
            )}
          </div>

          <div className="xl:col-span-4 col-span-12 flex items-center justify-between gap-4">
            <button
              disabled={!selectOptionValue}
              onClick={() => setShowConfirm(true)}
              className={`w-full min-h-[38px] px-1 py-[9px] bg-white rounded-md shadow border disabled:text-gray-300 disabled:border-gray-100 text-gray-700 border-gray-300 justify-center items-center gap-[7px] inline-flex text-sm font-medium font-['Inter'] leading-tight`}
            >
              <HiTrash size={22} className="shrink-0" />
              {t("dashboard.userEntityData.key1")}
            </button>

            <button
              onClick={() => {
                setSelectOptionValue("");
                setInputTextValue("");
                setUpdatedData({});
                setIsNewKeyProcess(true);
              }}
              className="shrink w-full min-h-[38px] px-1 py-[9px] bg-sky-500 disabled:bg-gray-300 rounded-md shadow justify-center items-center gap-[7px] inline-flex text-white text-sm font-medium font-['Inter'] leading-tight"
              disabled={isNewKeyProcess}
            >
              <HiPlusCircle size={22} className="shrink-0" />
              {t("dashboard.userEntityData.key2")}
            </button>
          </div>
        </div>

        {isEditable ? (
          <React.Fragment>
            {(selectOptionValue && useGetJsonKeyValues.isSuccess) ||
            (isNewKeyProcess && inputTextValue) ? (
              <ControlledJsonEditor
                // value={query?.data?.data?.element?.user_data}
                value={updatedData}
                onChange={handleChangeJson}
              />
            ) : null}
          </React.Fragment>
        ) : (
          <code>{JSON.stringify(query?.data?.data?.element?.user_data)}</code>
        )}

        <ConfirmModal
          cancelBtnName={t("dashboard.userEntityData.key3")}
          cancelBtnFn={() => setShowConfirm(false)}
          onClose={() => setShowConfirm(false)}
          confirmBtnFn={handleDeleteKEy}
          show={showConfirm}
          isLoading={deleteKeyMutation.isLoading}
          ConfirmBtnColor="blue"
          confirmBtnName={t("dashboard.userEntityData.key1")}
          title={t("dashboard.userEntityData.key6")}
          description={t("dashboard.userEntityData.key7")}
        />
      </React.Fragment>
    );

  if (user.isBasic) return null;

  return (
    <div className={`edit-preferences ${isEditable ? "p-0" : ""}`}>
      <h2 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
        {t("dashboard.userEntityData.title-user")}
      </h2>

      <p className="text-gray-900 text-base font-normal font-['Figtree'] leading-normal">
        {isUser
          ? t("dashboard.userEntityData.text-user-02")
          : t("dashboard.userEntityData.text-owner-02")}
        {/* &nbsp;
        {t("dashboard.userEntityData.text-03")} */}
      </p>

      <div className={isEditable ? "py-6" : ""}>{content}</div>

      <div className="flex justify-end">
        {isEditable &&
          ((selectOptionValue && useGetJsonKeyValues.isSuccess) ||
            (isNewKeyProcess && inputTextValue)) && (
            <Button
              onClick={handleUpdateData}
              disabled={
                UpdateDataMutation.isLoading ||
                userEndedSubscription ||
                !updatedData
              }
              isProcessing={UpdateDataMutation.isLoading}
              className="bg-sky-500"
            >
              {t("dashboard.userEntityData.btn-03")}
            </Button>
          )}
      </div>
    </div>
  );
};

export default UserEntityData;
