import { Button, Spinner } from "flowbite-react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import HttpService from "../../../../services/http";
import { useEffect, useState } from "react";
import LoginModal from "./LoginModal";
import EnterCallbackUrlModal from "./EnterCallbackUrlModal";
import { loginFormObject } from "./types";
import { HiArrowLeft } from "react-icons/hi";
import { Helmet } from "react-helmet-async";

const TempPart = () => {
  const [t] = useTranslation();
  const { customer_identification, user, userEndedSubscription } = useAuth();
  const [showMore, setShowMore] = useState(false);
  const [showLoginTemplate, setShowLoginTemplate] = useState(false);
  const [showCallbackUrlModal, setCallbackUrlModal] = useState(false);
  const [callbackUrlLink, setCallbackUrlLink] = useState<string | null>(null);
  const [code, setCode] = useState<string>();
  const [serviceData, setServiceData] = useState<any>({ name: "", logo: "" });
  const [entityData, setEntityData] = useState<any>({});

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_HOST_URL}/${code}`);

    toast.info("Destination copied!");
  };

  const entityOwnerData = useQuery(
    "entity-owner-data",
    (): any =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/data`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (data) => {
        setEntityData(data?.data?.element);
        if (data?.data?.element?.is_login_form_active) {
          setShowMore(data?.data?.element?.is_login_form_active);
          UpdateShareState.mutate(null);
        } else setShowMore(false);
      },
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateEntityOwnerData = useMutation<null, AxiosError<any, any>, any>(
    (isActive: boolean) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/entity/data`,
        {
          entity_data: {
            ...entityData,
            is_login_form_active: isActive,
          },
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (data: any) => {
        UpdateShareState.mutate(null);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const entityPreferencesQuery = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const updateEntityPreferences = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        setShowMore(true);
        UpdateEntityOwnerData.mutate(true);
        setLoginFormObject.mutate(loginFormObject);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const setLoginFormObject = useMutation<any, AxiosError<any, any>, any>(
    (formObject) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/entity/login-form`,
        formObject,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const entityQuery = useQuery(
    ["entities", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      enabled: entityPreferencesQuery.isSuccess,
    }
  );

  useEffect(() => {
    if (entityQuery.isSuccess && entityPreferencesQuery.isSuccess) {
      setServiceData({
        name: entityQuery?.data?.data?.element?.entity_name || "",
        logoUrl:
          entityPreferencesQuery?.data?.data?.element?.cdn_logo_project_path ||
          "",
      });
      setCallbackUrlLink(
        entityPreferencesQuery?.data?.data?.element?.login_form_callback_link ||
          null
      );
    }
  }, [
    entityPreferencesQuery.isSuccess,
    entityQuery.isSuccess,
    entityQuery?.data?.data?.element?.entity_name,
    entityPreferencesQuery?.data?.data,
    setCallbackUrlLink,
  ]);

  const UpdateShareState = useMutation<null, AxiosError<any, any>, any>(
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/process`,
        {
          headers: {
            authorization: `basic ${user.accessToken}`,
          },
        }
      ),
    {
      onSuccess: (data: any) => {
        setCode(`login/${data.data.element.process_identification}`);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleUpdateShareCondition = () => {
    if (!showMore) {
      setCallbackUrlModal(true);
      setShowMore(true);
      UpdateEntityOwnerData.mutate(true);
    } else {
      setShowMore(false);
      UpdateEntityOwnerData.mutate(false);
    }
  };

  const getCallBackUrlFromCustomer = (url: string) => {
    if (url) {
      setCallbackUrlLink(url);
      updateEntityPreferences.mutate({
        entity_data_preferences: {
          ...entityPreferencesQuery?.data?.data?.element,
          login_form_callback_link: url,
        },
        //delete_keys: [],
      });
    }
  };

  if (entityOwnerData.isLoading) {
    return (
      <div className="w-full p-5 mx-auto flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (entityOwnerData.isError) {
    return (
      <div className="w-full p-5 mx-auto flex justify-start items-center gap-3">
        <span>Failed to load data</span>
        <Button onClick={() => entityOwnerData.refetch()}>Reload</Button>
      </div>
    );
  }

  return (
    <div className="flex justify-start items-start">
      <Helmet>
        <title>Login template | Super User Manager</title>
        <meta
          name="description"
          content="Register to the easiest and most secure way to manage your users."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/login-template`}
        />
      </Helmet>

      <div
        className={`${
          showLoginTemplate ? "hidden md:flex" : "flex"
        } w-full md:w-96 p-4 lg:p-6 h-[calc(100vh-64px)] flex-col justify-start items-start gap-6 inline-flex bg-white`}
      >
        <div className="w-full flex justify-between lg:justify-start items-center">
          <h5 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
            {t("dashboard.features.loginTemplate.tempPart.header")}
          </h5>
          <p
            className="md:hidden text-sky-600 text-sm font-semibold font-['Figtree'] leading-tight"
            onClick={() => setShowLoginTemplate(true)}
          >
            {t("dashboard.features.loginTemplate.tempPart.btn-04")}
          </p>
        </div>
        <div className="flex items-center gap-2">
          {UpdateShareState.isLoading ||
          UpdateEntityOwnerData.isLoading ||
          updateEntityPreferences.isLoading ||
          setLoginFormObject.isLoading ? (
            <div className="h-6 w-11 flex justify-center items-center">
              <Spinner size="sm" className="flex items-center" />
            </div>
          ) : (
            <label className="switch shrink-0">
              <input
                type="checkbox"
                onChange={handleUpdateShareCondition}
                checked={showMore}
                disabled={UpdateShareState.isLoading || userEndedSubscription}
              />
              <div className="slider"></div>
            </label>
          )}
          <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
            {t("dashboard.features.loginTemplate.tempPart.title-01")}
          </span>
        </div>

        {showMore &&
          !UpdateShareState.isLoading &&
          !UpdateEntityOwnerData.isLoading &&
          !updateEntityPreferences.isLoading &&
          !setLoginFormObject.isLoading && (
            <div className="w-full pl-4 py-2.5 bg-white rounded-md shadow border border-gray-300 justify-between items-center inline-flex">
              <span className="truncate">
                {`${process.env.REACT_APP_HOST_URL}/${code}`}
              </span>

              <Button
                color="light"
                onClick={handleCopyAddress}
                size={"sm"}
                className="border-0 text-sky-600 rounded-none"
                disabled={userEndedSubscription}
              >
                {t("dashboard.features.loginTemplate.tempPart.btn-03")}
              </Button>
            </div>
          )}

        {/* enter callback url modal */}
        <EnterCallbackUrlModal
          showModal={showCallbackUrlModal || showMore}
          getCallBackUrlFromCustomer={getCallBackUrlFromCustomer}
          callbackUrlLink={callbackUrlLink}
          disabled={userEndedSubscription}
          isLoading={updateEntityPreferences.isLoading}
        />
      </div>
      <div
        className={`${
          !showLoginTemplate ? "hidden md:flex" : "flex"
        } w-full md:w-[calc(100%-384px)] self-stretch p-4 pt-6 lg:p-6 bg-gray-100 flex-col justify-start items-center gap-6 inline-flex`}
      >
        <div
          className="md:hidden self-start flex justify-start items-center gap-2"
          onClick={() => setShowLoginTemplate(false)}
        >
          <HiArrowLeft size={20} className="text-gray-500" />
          <span className="text-gray-500 text-sm font-semibold font-['Figtree'] leading-tight">
            {t("dashboard.features.loginTemplate.tempPart.btn-05")}
          </span>
        </div>
        <h3 className="w-full text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.features.loginTemplate.tempPart.btn-01")}
        </h3>

        {/* show login template Modal */}
        <LoginModal
          showModal={showCallbackUrlModal || showMore}
          serviceData={serviceData}
          isRealLogin={false}
        />
      </div>
    </div>
  );
};

export default TempPart;
