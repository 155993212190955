import { AxiosError } from "axios";
import { Button, Spinner } from "flowbite-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import HttpService from "../../../services/http";
import { useTranslation } from "react-i18next";

const EntityStatus = () => {
  const [t] = useTranslation();
  const { user, customer_identification } = useAuth();

  const queryClient = useQueryClient();

  const UpdateEntityStatusMutation = useMutation<
    any,
    AxiosError<any, any>,
    any
  >(
    (status) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/entity/status/${status}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("entity-stats");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    "entity-stats",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/status`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (result) => {
        let status = result?.data?.element?.entityStatus;

        if (status !== "SERVICE_ON" && status === "NEW")
          return UpdateEntityStatusMutation.mutate("SERVICE_ON");

        if (status === "CANCELED" || status === "SERVICE_ON") {
          if (status !== "SERVICE_ON")
            UpdateEntityStatusMutation.mutate("SERVICE_ON");
        } else if (status === "REJECTED" || status === "SERVICE_OFF")
          if (status !== "SERVICE_OFF")
            UpdateEntityStatusMutation.mutate("SERVICE_OFF");
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const statsIndicators: {
    [key: string]: {
      bgColor: string;
      blinkerColor: string;
      textColor: string;
      text: string;
    };
  } = {
    NEW: {
      bgColor: "bg-emerald-100",
      blinkerColor: "bg-emerald-400",
      textColor: "text-emerald-800",
      text: t("dashboard.entityStatus.text-01"),
    },
    SERVICE_ON: {
      bgColor: "bg-emerald-100",
      blinkerColor: "bg-emerald-400",
      textColor: "text-emerald-800",
      text: t("dashboard.entityStatus.text-02"),
    },
    SERVICE_OFF: {
      bgColor: "bg-red-100",
      blinkerColor: "bg-red-400",
      textColor: "text-red-800",
      text: t("dashboard.entityStatus.text-03"),
    },
    CANCELED: {
      bgColor: "bg-yellow-100",
      blinkerColor: "bg-yellow-400",
      textColor: "text-yellow-800",
      text: t("dashboard.entityStatus.text-04"),
    },
  };

  let content;
  if (isLoading) content = <Spinner size="sm" />;

  if (isError)
    content = (
      <Button color="light" onClick={() => refetch()} size="sm">
        {t("dashboard.entityStatus.btn-01")}
      </Button>
    );

  if (isSuccess) content = res?.data?.element?.entityStatus;

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow flex-col justify-start items-center gap-5 flex">
      <div className="self-stretch justify-between items-center flex flex-wrap gap-3">
        <div className="text-gray-700 text-2xl font-bold font-['Figtree'] md:leading-loose leading-none">
          {t("dashboard.entityStatus.title")}
        </div>
        <div className="flex-col justify-start items-start inline-flex">
          <div
            className={`pl-2 pr-3 py-0.5 ${statsIndicators[content]?.bgColor} rounded-xl justify-center items-center gap-1.5 inline-flex`}
          >
            <div className="w-2 h-2 relative">
              <div
                className={`w-1.5 h-1.5 left-[1px] top-[1px] absolute animate-ping ${statsIndicators[content]?.blinkerColor} rounded-full`}
              />
            </div>
            <div
              className={`text-center ${statsIndicators[content]?.textColor} text-sm font-medium font-['Inter'] leading-tight`}
            >
              {statsIndicators[content]?.text}
            </div>
          </div>
        </div>
      </div>
      {content === "SERVICE_OFF" ? (
        <img
          title="Super User Manager"
          loading="lazy"
          src="https://cdn.1cdn.app/application/UMD/umd_service_is_off.svg"
          alt="service is off"
          className="my-auto lg:w-full"
        />
      ) : (
        <img
          title="Super User Manager"
          loading="lazy"
          src="https://cdn.1cdn.app/application/UMD/umd_service_is_on.svg"
          alt="service is on"
          className="my-auto max-w-[350px]"
        />
      )}
    </div>
  );
};

export default EntityStatus;
