import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RoleModal } from "../entities/RoleTypeEditorNewVersion";
import { useTranslation } from "react-i18next";
import { Dropdown, Modal } from "flowbite-react";
import { FaChevronDown } from "react-icons/fa6";

type RoleEditorType = {
  data: {
    role: string;
  };
  user_identification: string;
  customerId: string;
  entity_identification: string;
  list_role: string[];
  disabled: boolean;
};

export const RoleOptionsPart = ({
  data,
  customerId,
  entity_identification,
  user_identification,
  list_role,
  disabled,
}: RoleEditorType) => {
  const { t } = useTranslation();

  const [roles, setRoles] = useState<string[] | []>([]);
  const [role, setRole] = useState<string>("");
  const [showModalRole, setShowModalRole] = useState(false);

  const UpdateRoleMutation = useMutation<string, AxiosError<any, any>, any>(
    (role) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/user/${customerId}/role/${role}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  useEffect(() => {
    setRoles(list_role);
    setRole(data.role);
  }, [data, list_role]);

  const handleRoleManagement = (e: string) => {
    const optionValue = e;

    // Update user role here
    if (optionValue !== "addRole") {
      UpdateRoleMutation.mutate(optionValue, {
        onSuccess(data, variables, context) {
          // toast.success("User role updated");

          setRole(optionValue);
        },
      });
    } else {
      // if it is a new role add it to the list of roles
      setShowModalRole(true);
    }
  };

  let roleContent = (
    <Dropdown
      label=""
      renderTrigger={() => (
        <button className="group w-full justify-between items-center flex">
          <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-tight text-left md:text-nowrap">
            {role}
          </div>
          <div className="w-5 h-5 relative ml-1 group-hover:opacity-100 opacity-0">
            <FaChevronDown size={14} />
          </div>
        </button>
      )}
      className=""
      placement="auto"
    >
      <div className="max-h-[200px] overflow-y-auto">
        {roles.map((role: string, index: number) => (
          <Dropdown.Item
            key={index}
            value={role}
            onClick={() => handleRoleManagement(role)}
          >
            {role}
          </Dropdown.Item>
        ))}
      </div>
      <Dropdown.Divider />
      <Dropdown.Item
        className="text-sky-500"
        onClick={() => handleRoleManagement("addRole")}
      >
        {t("dashboard.roleTypeEditorNewVersion.btn-02")}
      </Dropdown.Item>
    </Dropdown>
  );

  return (
    <div className="role-option-part-drop-down">
      {roleContent}

      <Modal
        size="lg"
        show={showModalRole}
        onClose={() => setShowModalRole(false)}
        className="role-modal"
      >
        <Modal.Header className="border-b-0 text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.roleTypeEditorNewVersion.modal.header-01")}
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight mt-2">
            {t("dashboard.roleTypeEditorNewVersion.modal.description-01")}
          </p>
        </Modal.Header>
        <Modal.Body>
          <RoleModal
            roles={roles}
            setRoles={setRoles}
            setShowRoleModal={setShowModalRole}
            mutation={UpdateRoleMutation}
            setRole={setRole}
            disabled={disabled}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
