import React from "react";
import { Helmet } from "react-helmet-async";

const Maintenance = () => {
  return (
    <>
      <Helmet>
        <title>Super User Manager - Maintenance</title>
        <meta
          name="description"
          content="The easiest and most secure way to manage your users."
        />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/maintenance`}
        />
      </Helmet>
      <h1 className="audiowide">503</h1>
      <h4 className="prompt">
        We are updating our system.Please try again in some minutes.
      </h4>
      <img
        title="Super User Manager"
        loading="lazy"
        src="/images/503.svg"
        alt="CDN"
        className="img-fluid my-5 d-block mx-auto"
      />
    </>
  );
};

export default Maintenance;
