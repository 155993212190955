import { useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { useMutation } from "react-query";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import BsInputPassword from "../../../public/BsInputPassword";
import { AxiosError } from "axios";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "flowbite-react";
import { BiCheck, BiX } from "react-icons/bi";
import PasswordValidator from "../../../public/PasswordValidator";

const schema = Joi.object({
  actual_password: Joi.string().trim().required().label("Current password"),
  new_password: Joi.string().trim().required().label("Password"),
  new_confirm_password: Joi.any()
    .valid(Joi.ref("new_password"))
    .required()
    .messages({ "any.only": "Password must match" }),
});

const ProfilePasswordChange = () => {
  const { t } = useTranslation();
  const localStorage = useAppLocalStorage();

  const { customer_identification, user } = useAuth();

  const [show, setShow] = useState(false);
  const [apiError, setApiError] = useState<any>({});
  const [validatorCurrentPassword, setValidatorCurrentPassword] =
    useState<string>("");

  const rules: Record<string, any> = {
    //
    level1: [
      {
        regex: "^.{6,32}$",
        message: t("ruleValidator.key1", { min: 6, max: 32 }),
      },
    ],
    //
    level2: [
      {
        regex: "^.{8,32}$",
        message: t("ruleValidator.key1", { min: 8, max: 32 }),
      },
    ],
    // Minimum 8 characters and maximum 32 characters, at least one uppercase letter, one lowercase letter and one number and only alpha-numeric characters
    level3: [
      {
        regex: "^.{8,32}$",
        message: t("ruleValidator.key1", { min: 8, max: 32 }),
      },
      {
        regex: "(?=.*[A-Z])",
        message: t("ruleValidator.key2"),
      },
      {
        regex: "(?=.*[a-z])",
        message: t("ruleValidator.key3"),
      },
      { regex: "(?=.*\\d)", message: t("ruleValidator.key4") },
      { regex: "[A-Za-z\\d]+$", message: t("ruleValidator.key5") },
    ],
    // Minimum 8 characters and maximum 32 characters, at least one letter, one number and one special character ( @$!%*#?&_.,+=:;<>€£()/- )
    level4: [
      {
        regex: "^.{8,32}$",
        message: t("ruleValidator.key1", { min: 8, max: 32 }),
      },
      { regex: "(?=.*[A-Za-z])", message: t("ruleValidator.key6") },
      { regex: "(?=.*\\d)", message: t("ruleValidator.key4") },
      {
        regex: "[@$!%*#?&_.,+=:;<>€£()/-]+",
        message: t("ruleValidator.key7", {
          chars: "[@$!%*#?&_.,+=:;<>€£()/-]",
        }),
      },
    ],
    // Minimum 8 characters and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character ( @$!%*#?&_.,+=:;<>€£()/- )
    level5: [
      {
        regex: "^.{8,32}$",
        message: t("ruleValidator.key1", { min: 8, max: 32 }),
      },
      {
        regex: "(?=.*[A-Z])",
        message: t("ruleValidator.key2"),
      },
      {
        regex: "(?=.*[a-z])",
        message: t("ruleValidator.key3"),
      },
      { regex: "(?=.*\\d)", message: t("ruleValidator.key4") },
      {
        regex: "[@$!%*#?&_.,+=:;<>€£()/-]+",
        message: t("ruleValidator.key7", {
          chars: "[@$!%*#?&_.,+=:;<>€£()/-]",
        }),
      },
    ],
    // Minimum 10 and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character ( @$!%*#?&_.,+=:;<>€£()/- )
    level6: [
      {
        regex: "^.{10,32}$",
        message: t("ruleValidator", { min: 10, max: 32 }),
      },
      {
        regex: "(?=.*[A-Z])",
        message: t("ruleValidator.key2"),
      },
      {
        regex: "(?=.*[a-z])",
        message: t("ruleValidator.key3"),
      },
      { regex: "(?=.*\\d)", message: t("ruleValidator.key4") },
      {
        regex: "[@$!%*#?&_.,+=:;<>€£()/-]+",
        message: t("ruleValidator.key7", {
          chars: "[@$!%*#?&_.,+=:;<>€£()/-]",
        }),
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const PasswordCheckMutation = useMutation<
    any,
    AxiosError<any, any>,
    { password: string; level?: string | null }
  >((data) => HttpService.post("/auth/check-password", data), {
    onError: (err) => {
      toast.error(err.response?.data?.message || err.message);

      setApiError({ ...apiError, new_password: err.response?.data?.message });
      setError("new_password", err.response?.data?.message);
    },
  });

  const UpdatePasswordMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/password`,
        {
          actual_password: data.actual_password,
          new_password: data.new_password,
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Password updated");
        setApiError(null);
        reset();
        setValidatorCurrentPassword("");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setApiError(err.response?.data.element);
      },
    }
  );

  const onSubmit = (values: any) => {
    const level = localStorage.getItem("_umd_password_level");

    PasswordCheckMutation.mutate(
      {
        password: values.new_password,
        level: level,
      },
      {
        onSuccess: () => {
          UpdatePasswordMutation.mutate(values);
        },
      }
    );
  };

  return (
    <div className="px-5 pt-5 pb-8 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
      <div className="self-stretch h-14 flex-col justify-start items-start gap-2 flex">
        <div className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.profile.key9")}
        </div>
        <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.profile.key10")}
        </div>
      </div>
      <div className="w-full flex-col justify-start items-start gap-2 flex">
        <div className="w-full justify-start items-start gap-3 flex flex-wrap">
          <div className="justify-start items-start gap-5 flex">
            <div className="md:w-[318px] w-full flex-col justify-start items-start inline-flex">
              <div className="self-stretch px-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-start items-center inline-flex">
                <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
                  •••••••••••
                </div>
              </div>
            </div>
          </div>
          <Button
            className="bg-sky-500 p-0"
            type="submit"
            onClick={() => setShow(true)}
          >
            {t("dashboard.profile.key11")}
          </Button>
        </div>
      </div>

      <Modal show={show} onClose={() => setShow(false)} size={"md"}>
        <Modal.Header className="border-b-0">
          {t("dashboard.profile.key11")}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BsInputPassword
              label={
                t("dashboard.userProfile.changePasswordForm.label-01") as string
              }
              isInvalid={
                !!errors.actual_password || !!apiError?.actual_password
              }
              errorMessage={
                (errors.actual_password?.message as string) ||
                apiError?.actual_password
              }
              className={"w-full mb-4"}
              rhfControls={register("actual_password")}
            />

            <BsInputPassword
              label={
                t("dashboard.userProfile.changePasswordForm.label-02") as string
              }
              isInvalid={!!errors.new_password || !!apiError?.new_password}
              // errorMessage={
              //   (errors.new_password?.message as string) ||
              //   apiError?.new_password
              // }
              className={`w-full ${
                validatorCurrentPassword.length > 0 ? "mb-2" : "mb-4"
              }`}
              rhfControls={register("new_password", {
                onChange(event) {
                  setValidatorCurrentPassword(event.target.value);
                },
              })}
            />
            {/* Password validator */}
            <PasswordValidator
              className={
                validatorCurrentPassword.length > 0 ? "mb-3 text-xs" : "hidden"
              }
              ruleSet={
                rules[localStorage.getItem("_umd_password_level") as string]
              }
              passwordValue={validatorCurrentPassword}
              passedRuleClassName="flex items-center text-green-500"
              failedRuleClassName="flex items-center text-red-500"
              passIcon={<BiCheck size={16} />}
              failIcon={<BiX size={16} />}
            />

            <BsInputPassword
              label={
                t("dashboard.userProfile.changePasswordForm.label-03") as string
              }
              isInvalid={!!errors.new_confirm_password}
              errorMessage={errors.new_confirm_password?.message as string}
              className={"w-full mb-4"}
              rhfControls={register("new_confirm_password")}
            />

            <div className="flex items-center justify-around gap-4">
              <Button
                color="light"
                type="button"
                className="p-0 w-[50%] shadow"
                onClick={() => setShow(false)}
              >
                {t("dashboard.profile.key12")}
              </Button>

              <Button
                type="submit"
                className="p-0 w-[50%] bg-sky-500 shadow"
                disabled={UpdatePasswordMutation.isLoading}
                isProcessing={UpdatePasswordMutation.isLoading}
              >
                {t("dashboard.userProfile.changePasswordForm.btn-01")}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfilePasswordChange;
