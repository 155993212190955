import { HiGift, HiStar } from "react-icons/hi";
import { useLmcUserView } from "../../hooks/useLmcUserView";
import React from "react";
import { useTranslation } from "react-i18next";

const LmcRewardPoint = ({ point = 0 }: { point?: number }) => {
  const { t } = useTranslation();

  const {
    state: { entity },
  } = useLmcUserView();

  let boxs = [];

  for (let index = 0; index < entity.process_data?.options?.pack; index++) {
    boxs.push(index);
  }

  return (
    <div className="w-full md:py-20 py-10 px-4 flex flex-col justify-start items-center gap-20">
      <div className="flex flex-col justify-start items-center gap-4">
        <h4 className="w-[158px] text-center text-gray-50 text-xl font-semibold font-['Figtree'] leading-7">
          {t("lmcUser.key46")}
        </h4>
        <h2 className="text-center text-emerald-300 text-6xl font-extrabold font-['Figtree'] leading-[60px]">
          {point}
        </h2>
      </div>

      <div className="flex flex-col justify-start items-center gap-4">
        <p className="w-[158px] text-center text-gray-50 text-base font-normal font-['Figtree'] leading-normal">
          {t("lmcUser.key47")}
        </p>
        <div className="w-[168px] h-[60px] bg-gray-800 rounded flex justify-center items-center gap-2">
          <HiGift size={20} className="text-sky-400" />
          <span className="text-sky-400 text-sm font-medium font-['Figtree'] leading-tight">{`You have ${Math.floor(
            point / entity.process_data?.options?.pack
          )} gifts`}</span>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-3">
          {boxs.map((box, index) => (
            <div
              key={index}
              className="h-6 w-6 bg-gray-800 rounded-sm flex justify-center items-center"
            >
              {point >= entity.process_data?.options?.pack ? (
                <React.Fragment>
                  {index <
                  point -
                    Math.floor(point / entity.process_data?.options?.pack) *
                      entity.process_data?.options?.pack ? (
                    <HiStar size={20} className="text-yellow-500" />
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {index < point ? (
                    <HiStar size={20} className="text-yellow-500" />
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LmcRewardPoint;
