import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation } from "react-query";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Button, Label, Modal, Textarea, TextInput } from "flowbite-react";

type propTypes = {
  show: boolean;
  ctrFn: Dispatch<SetStateAction<boolean>>;
  toSendMsgUserId: string;
  entity_identification: string;
  entity_user_identification: string;
};

type sendMsgFormType = {
  subject: string;
  content: string;
};

const SendPersonalMessage = ({
  show,
  ctrFn,
  entity_user_identification,
  toSendMsgUserId,
  entity_identification,
}: propTypes) => {
  const { t } = useTranslation();

  const schema = Joi.object()
    .keys({
      subject: Joi.string()
        .max(100)
        .optional()
        .allow(null, "")
        .label(t("dashboard.entities.viewEntityUser.sendMsg.inp1Label")),
      content: Joi.string()
        .min(5)
        .max(3000)
        .required()
        .label(t("dashboard.entities.viewEntityUser.sendMsg.inp2Label")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<sendMsgFormType>({
    resolver: joiResolver(schema),
  });

  const sendMsgToUserMutation = useMutation<any, AxiosError<any, any>, any>(
    (data: any) =>
      HttpService.post(
        `/${entity_user_identification}/${entity_identification}/user/${toSendMsgUserId}/message`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Message is sent.");

        reset();

        ctrFn(false);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onSubmit = (data: sendMsgFormType) => {
    sendMsgToUserMutation.mutate(data);
  };

  return (
    <Modal show={show} onClose={() => ctrFn(false)}>
      <Modal.Header className="border-0 p-4">
        {t("dashboard.entities.viewEntityUser.sendMsg.modal-title")}
      </Modal.Header>
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <Label
              value={t(
                "dashboard.entities.viewEntityUser.sendMsg.inp1Label"
              ).toString()}
              className="block mb-3"
            />

            <TextInput
              color={!!errors.subject ? "failure" : ""}
              type="text"
              placeholder={
                t(
                  "dashboard.entities.viewEntityUser.sendMsg.inp1Placeholder"
                ) as string
              }
              helperText={<>{errors?.subject?.message}</>}
              {...register("subject")}
            />
          </div>
          <div className="mb-3">
            <Label
              value={t(
                "dashboard.entities.viewEntityUser.sendMsg.inp2Label"
              ).toString()}
              className="block mb-3"
            />

            <Textarea
              color={!!errors.content ? "failure" : ""}
              rows={8}
              placeholder={
                t(
                  "dashboard.entities.viewEntityUser.sendMsg.inp2Placeholder"
                ) as string
              }
              helperText={
                <>
                  <p>
                    {t("dashboard.entities.viewEntityUser.sendMsg.inp2text")}
                  </p>
                  {errors?.content?.message}
                </>
              }
              {...register("content")}
            />
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              className="bg-sky-500"
              isProcessing={sendMsgToUserMutation.isLoading}
              disabled={sendMsgToUserMutation.isLoading}
            >
              {t("dashboard.entities.viewEntityUser.sendMsg.submitBtn")}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SendPersonalMessage;
