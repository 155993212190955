import { useLmcUserView } from "./hooks/useLmcUserView";
import { Link } from "react-router-dom";
import {
  // useMutation,
  useQuery,
} from "react-query";
import HttpService from "../../../services/http";
import { LmcActionKind } from "./contexts";
import { Button, Spinner } from "flowbite-react";
import LmcVerifyEmail from "./components/LmcVerifyEmail";
import React, { useCallback, useEffect, useRef, useState } from "react";
import QRCodeStyling from "livebook-qr-code";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// import { AxiosError } from "axios";

// const popover = (
//   <Popover id="popover-qr" className="border-0 shadow-lg">
//     <Popover.Header as="h3" className="border-0 bg-transparent">
//       Show QR to Vendor
//     </Popover.Header>
//     <Popover.Body>
//       Display this QR code to the vendor to use the rewards program service.
//     </Popover.Body>
//   </Popover>
// );

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  type: "svg",
  qrOptions: { errorCorrectionLevel: "H" },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
});

const LmcDashboard = () => {
  const { t } = useTranslation();

  const {
    state: { auth, entity },
    dispatch,
  } = useLmcUserView();

  const ref = useRef(null);

  const [userInfo, setUserInfo] = useState({
    user_email: "",
    user_identification: "",
  });

  const [isQrCodeValid, setIsQrCodeValid] = useState(true);
  const [showConfirmEmail, setShowConfirmEmail] = useState<boolean>(false);

  // const LoginLmcUserMutation = useMutation<any, AxiosError<any, any>, any>(
  //   (data) =>
  //     HttpService.post(
  //       `/auth/${entity.entity_data?.entity_identification}/login`,
  //       data
  //     )
  // );

  const { isLoading, isError, isSuccess, data, refetch } = useQuery(
    "lmc-user-profile",
    () =>
      HttpService.get(
        `/entity/${entity?.entity_data?.entity_identification}/process/${auth.userIdentification}/LMC/data`
      ),
    {
      onSuccess({ data }) {
        // LoginLmcUserMutation.mutate(
        //   {
        //     user_email: data?.element?.user?.email,
        //     user_password: auth.password,
        //   },
        //   {
        //     onError(error, variables, context) {
        //       const code = error.response?.data?.code;

        //       if (code && code === "RSUSOK006")
        //         toast.warn("Your email address is not verified.");

        //       toast.error(error.response?.data.message || error.message);
        //     },
        //   }
        // );

        dispatch({
          type: LmcActionKind.LMCDATA,
          payload: data.element,
        });

        if (!data?.element?.user?.is_active) {
          setUserInfo({
            user_email: data?.element?.user?.email,
            user_identification: auth?.userIdentification || "",
          });
        }

        setShowConfirmEmail(!data?.element?.user?.is_active);
      },
    }
  );

  useEffect(() => {
    if (ref.current && isSuccess) qrCode.append(ref.current);
  }, [isSuccess, showConfirmEmail]);

  // Check either CDN is ok for qrCode
  const qrInitializer = useCallback(async () => {
    let targetSrc;

    try {
      await fetch(
        entity?.entity_data?.entity_logo ||
          (process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL as string)
      ).then((res) => {
        if (res.status === 200) {
          targetSrc =
            entity.entity_data?.entity_logo ||
            process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL;
        }
      });
    } catch (error) {
      targetSrc = "/images/logo.png";
    }

    if (isSuccess)
      qrCode.update({
        data: isQrCodeValid
          ? JSON.stringify({
              userIdentification:
                auth.userIdentification || userInfo.user_identification,
              exp: moment(auth.loggedInAt).add(5, "minutes"),
            })
          : t("lmcUser.key3").toString(),
        image: targetSrc,
        dotsOptions: {
          color: "#000000",
          type: "rounded",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
  }, [
    auth.loggedInAt,
    auth.userIdentification,
    entity.entity_data?.entity_logo,
    isQrCodeValid,
    isSuccess,
    t,
    userInfo.user_identification,
  ]);

  useEffect(() => {
    qrInitializer();
  }, [qrInitializer, showConfirmEmail]);

  useEffect(() => {
    let to = setInterval(() => {
      if (isSuccess) {
        setIsQrCodeValid(
          moment(auth.loggedInAt).add(5, "minutes").isAfter(moment())
        );
      }
    }, 1000);

    return () => {
      clearInterval(to);
    };
  }, [auth.loggedInAt, isSuccess]);

  useEffect(() => {
    if (!isQrCodeValid)
      toast.warn("The QR code is expired, Please reload to obtain a new one.");
  }, [isQrCodeValid]);

  let content;

  if (isLoading) content = <Spinner size="sm" />;

  if (isError)
    content = (
      <div className="w-full p-4 flex flex-col justify-start items-center gap-3">
        <h5 className="text-gray-200">{t("lmcUser.key4")} </h5>
        <Button onClick={() => refetch()}>{t("lmcUser.key2")}</Button>
      </div>
    );

  if (isSuccess)
    content = (
      <React.Fragment>
        <div className="w-full px-4 flex my-auto flex-col justify-start items-center">
          <h5 className="w-full mt-16 mb-8 text-center text-gray-200 text-2xl font-bold font-['Figtree'] ">
            {entity?.process_data?.comment || t("lmcUser.key39")}
          </h5>

          <center className="bg-white mb-4">
            {/* <QRCode
            id={`qr-${auth.userIdentification}`}
            ecLevel={"M"}
            enableCORS={true}
            size={230}
            fgColor={"#000000"}
            bgColor={"#FFFFFF"}
            logoWidth={50}
            logoHeight={50}
            logoOpacity={0.5}
            removeQrCodeBehindLogo={true}
            qrStyle={"squares"}
            logoImage={
              entity.entity_data?.entity_logo ||
              process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL
            }
            // eyeRadius={[
            //   [0, 10, 0, 10],
            //   [10, 0, 10, 0],
            //   [10, 0, 10, 0],
            // ]}
            // value={`${process.env.REACT_APP_HOST_URL}/${auth.userIdentification}`}
            value={JSON.stringify({
              userIdentification: auth.userIdentification,
            })}
            quietZone={20}
          /> */}
            <div
              ref={ref}
              id={`qr-${
                auth.userIdentification || userInfo.user_identification
              }`}
            />
          </center>
        </div>
        {data.data.element.data?.lmc_type &&
          data.data.element.data.lmc_value[0].value && (
            <center className="w-full mt-auto p-4 mb-3">
              {isQrCodeValid ? (
                <Link
                  to={`reward?lmc_type=${data.data.element.data?.lmc_type}`}
                  className="flex text-center text-neutral-900 text-sm font-semibold font-['Figtree'] leading-tight w-full h-11 bg-sky-400 hover:bg-sky-300 rounded-[100px] flex-col items-center justify-center"
                >
                  {t("lmcUser.key5")}
                </Link>
              ) : (
                <Button
                  disabled={!isQrCodeValid || isLoading}
                  className="text-center text-neutral-900 text-sm font-semibold font-['Figtree'] leading-tight w-full h-11 bg-sky-400 hover:bg-sky-300 rounded-[100px] "
                >
                  {t("lmcUser.key6")}
                </Button>
              )}
            </center>
          )}
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {showConfirmEmail ? (
        <LmcVerifyEmail
          userEmail={userInfo.user_email}
          userId={userInfo.user_identification}
          showConfirmEmailModal={showConfirmEmail}
          handleLogin={() => refetch()}
          isLoading={isLoading}
        />
      ) : (
        content
      )}
    </React.Fragment>
  );
};

export default LmcDashboard;
