import { useEffect, useState } from "react";
import { renderHiddenToken } from "./AccessTokenBox";
import { UseMutationResult, UseQueryResult } from "react-query";
import { toast } from "react-toastify";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import {
  HiClipboardCheck,
  HiDocumentDuplicate,
  HiIdentification,
  HiKey,
} from "react-icons/hi";
import { Spinner } from "flowbite-react";
import ConfirmModal from "../../../public/ConfirmModal";

const SwaggerInfos = ({
  query,
  disabled,
  UpdateTokenMutation,
}: {
  query: UseQueryResult<any, AxiosError<any, any>>;
  disabled: boolean;
  UpdateTokenMutation: UseMutationResult<
    any,
    AxiosError<any, any>,
    any,
    unknown
  >;
}) => {
  const [t] = useTranslation();
  const localStorage = useAppLocalStorage();
  const [swaggerInfos, setSwaggerInfos] = useState<{
    username: string;
    password: string;
    entity_identification: string;
  }>();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    let clientCredentials = localStorage.getItem("_umd_service");

    if (clientCredentials) {
      const { userIdentification, entityIdentification } =
        JSON.parse(clientCredentials);

      setSwaggerInfos({
        username: userIdentification,
        entity_identification: entityIdentification,
        password: query?.data?.data.element.access_token,
      });
    }
  }, [localStorage, query?.data?.data.element.access_token]);

  const handleCopyCredentials = (
    item: "username" | "password" | "entity_identification"
  ) => {
    if (swaggerInfos) {
      window.navigator.clipboard.writeText(swaggerInfos[item]);

      toast.info(`${item.replace("_", " ")} copied`);
    }
  };

  const UpdateTokenHandler = () => {
    UpdateTokenMutation.mutate(undefined);
    setOpenModal(false);
  };

  return (
    <div className="flex flex-col items-start justify-start gap-8">
      <div className="">
        <h3 className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.develop.swaggerInfos.header")}
        </h3>
        <small className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.develop.swaggerInfos.text-01")}
        </small>
      </div>

      <ul className="w-full flex flex-col items-start justify-start gap-4">
        <li className="w-full flex flex-wrap justify-between items-center gap-4">
          <div className="flex justify-start items-center gap-2 text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
            <HiClipboardCheck size={20} />
            <span>{t("dashboard.develop.swaggerInfos.item-03")}</span>
          </div>
          <div
            className="flex flex-row-reverse md:flex-row justify-start items-center gap-2"
            onDoubleClick={() => handleCopyCredentials("entity_identification")}
          >
            <HiDocumentDuplicate
              size={20}
              className="text-gray-400 cursor-pointer"
              onClick={() => handleCopyCredentials("entity_identification")}
            />
            <span className="text-gray-700 text-sm font-bold font-['Roboto Mono'] leading-tight">
              {swaggerInfos?.entity_identification}
            </span>
          </div>
        </li>
        <li className="py-4 w-full flex flex-wrap justify-between items-center border-y border-solid gap-4">
          <div className="flex justify-start items-center gap-2 text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
            <HiIdentification size={20} />
            <span>{t("dashboard.develop.swaggerInfos.item-01")}</span>
          </div>
          <div
            className="flex flex-row-reverse md:flex-row justify-start items-center gap-2"
            onDoubleClick={() => handleCopyCredentials("username")}
          >
            <HiDocumentDuplicate
              size={20}
              className="text-gray-400 cursor-pointer"
              onClick={() => handleCopyCredentials("username")}
            />
            <span className="text-gray-700 text-sm font-bold font-['Roboto Mono'] leading-tight">
              {swaggerInfos?.username}
            </span>
          </div>
        </li>
        <li className="w-full flex flex-wrap justify-between items-center gap-4">
          <div className="flex justify-start items-center gap-2 text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
            <HiKey size={20} />
            <span>{t("dashboard.develop.swaggerInfos.item-02")}</span>
          </div>
          <div
            className="flex justify-start flex-row-reverse md:flex-row items-center gap-2"
            onDoubleClick={() => handleCopyCredentials("password")}
          >
            {!disabled && (
              <p
                className="mr-3 text-sky-600 hover:text-sky-400 text-sm font-semibold font-['Figtree'] leading-tight cursor-pointer"
                onClick={() => setOpenModal(true)}
              >
                {UpdateTokenMutation.isLoading ? (
                  <Spinner size={"sm"} className="mr-1" />
                ) : null}
                {t("dashboard.develop.swaggerInfos.btn-01")}
              </p>
            )}
            <HiDocumentDuplicate
              size={20}
              className="text-gray-400 cursor-pointer"
              onClick={() => handleCopyCredentials("password")}
            />
            <span className="text-gray-700 text-sm font-bold font-['Roboto Mono'] leading-tight">
              {swaggerInfos &&
                renderHiddenToken(swaggerInfos?.password as string)}
            </span>
          </div>
        </li>
      </ul>

      <div className="w-full md:w-fit">
        <a
          href={process.env.REACT_APP_UMD_SWAGGER_DOMAIN}
          target={"_blank"}
          rel="noreferrer"
          className={`w-full md:w-fit px-[17px] py-[9px] bg-sky-500 hover:bg-sky-800 rounded-md shadow justify-center items-center inline-flex ${
            disabled ? "pe-none" : ""
          }`}
        >
          <span className="text-white text-sm font-normal font-['Lexend'] leading-tight">
            {t("dashboard.develop.swaggerInfos.item-04")}
          </span>
        </a>
      </div>

      <ConfirmModal
        show={openModal && !UpdateTokenMutation.isLoading}
        onClose={setOpenModal}
        title={t("dashboard.develop.swaggerInfos.text-02")}
        description={t("dashboard.develop.swaggerInfos.text-03")}
        cancelBtnName={t("dashboard.develop.swaggerInfos.btn-02")}
        cancelBtnFn={setOpenModal}
        confirmBtnName={t("dashboard.develop.swaggerInfos.btn-03")}
        confirmBtnFn={UpdateTokenHandler}
        disabled={disabled}
      />
    </div>
  );
};

export default SwaggerInfos;
