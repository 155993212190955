import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Timer } from "../../../../routes/auth/ForgotPassword";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import { Button, Modal } from "flowbite-react";
import { useLmcUserView } from "../hooks/useLmcUserView";

const LmcVerifyEmail = ({
  userId,
  showConfirmEmailModal,
  userEmail,
  userPassword,
  handleLogin,
  isLoading,
}: {
  userId: string;
  showConfirmEmailModal: boolean;
  userEmail: string;
  userPassword?: string;
  handleLogin: (data: { user_email: string; user_password: string }) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  const [showTimer, setShowTimer] = useState(false);

  const {
    state: {
      entity: { entity_data },
    },
  } = useLmcUserView();

  const ResendEmailVerification = useMutation<any, AxiosError<any, any>>(
    () =>
      HttpService.post(
        `/link-registration/${entity_data?.entity_identification}/${userId}/resend-validation-email`
      ),
    {
      onSuccess: () => {
        toast.success(t("register.msg"));

        setShowTimer(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setShowTimer(false);
      },
    }
  );

  return (
    <Modal show={showConfirmEmailModal} size={"lg"} className="">
      <Modal.Body className=" flex-col bg-gray-800 rounded-md justify-center items-center gap-5 inline-flex">
        <img
          title="Super User Manager"
          loading="lazy"
          src="https://cdn.1cdn.app/application/UMD/umd_verify_your_email.svg"
          alt="Super User Manager"
          className=""
          width={217}
        />
        <div className="flex-col justify-center items-center gap-2 inline-flex">
          <h2 className="text-center text-gray-200 text-2xl font-bold font-['Figtree'] leading-loose">
            {t("register.confirmEmailModal.text_1")}
          </h2>
          {/* <p className="text-center text-gray-200 text-sm font-normal font-['Figtree'] leading-tight" >
            {t("register.confirmEmailModal.text_2")}
          </p> */}
          <p className="text-center text-gray-200 text-sm font-normal font-['Figtree'] leading-tight">
            {t("register.confirmEmailModal.text_3")} <b> {userEmail}</b>{" "}
            {t("register.confirmEmailModal.text_4")}
          </p>
        </div>

        <div className="flex justify-center items-center gap-3">
          <Button
            color="dark"
            className="bg-gray-700 rounded-[100px] text-gray-200"
            onClick={() =>
              userEmail && ResendEmailVerification.mutate(undefined)
            }
            disabled={showTimer}
            isProcessing={ResendEmailVerification.isLoading}
          >
            {showTimer ? (
              <span className="text-muted ms-2">
                <Timer setShowTimer={setShowTimer} ttl={120} />{" "}
                {t("register.confirmEmailModal.text_5")}
              </span>
            ) : (
              t("register.confirmEmailModal.btn_2")
            )}
          </Button>

          <Button
            className="bg-sky-400 rounded-[100px] text-neutral-900"
            onClick={() =>
              handleLogin({
                user_email: userEmail,
                user_password: userPassword || "",
              })
            }
            disabled={isLoading}
            isProcessing={isLoading}
          >
            {t("register.confirmEmailModal.btn_1")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LmcVerifyEmail;
