import React, { useCallback, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { Link, Outlet } from "react-router-dom";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import moment from "moment";
import { AxiosError } from "axios";
import AddUserAutomaticallyModal from "./AddUserAutomaticallyModal";
import AddUserManuallyModal from "./AddUserManuallyModal";
import AddUserByShareQrCodeModal from "./AddUserByShareQrCodeModal";
import { useTranslation } from "react-i18next";
import { RoleOptionsPart } from "./RoleOptionsPart";
import { TypeOptionsPart } from "./TypeOptionPart";
import { Button, Dropdown, Table, Tooltip } from "flowbite-react";
import { FaArrowRight, FaCopy, FaUserPlus } from "react-icons/fa6";
import { AiOutlineLoading } from "react-icons/ai";
import Pagination from "../../../public/Pagination";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";

type User = {
  id: number;
  user_identification: string;
  user_email: string;
  user_is_active: boolean;
  user_role: string;
  user_type: string;
  user_creation_date_time: string;
  user_account_status: string;
};

enum AddUserActionKind {
  AU = "AU",
  MA = "MA",
  SH = "SH",
  DEFAULT = "DEFAULT",
}

interface AddUserAction {
  type: AddUserActionKind;
}

interface AddUserActionState {
  au: boolean;
  ma: boolean;
  sh: boolean;
}

const addUserActionReducer = (
  state: AddUserActionState,
  action: AddUserAction
) => {
  const { type } = action;

  switch (type) {
    case AddUserActionKind.AU:
      return {
        au: true,
        ma: false,
        sh: false,
      };
    case AddUserActionKind.MA:
      return {
        au: false,
        ma: true,
        sh: false,
      };
    case AddUserActionKind.SH:
      return {
        au: false,
        ma: false,
        sh: true,
      };
    default:
      return {
        au: false,
        ma: false,
        sh: false,
      };
  }
};

const PointerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.67187 1.9115C6.52893 1.37804 5.9806 1.06146 5.44713 1.2044C4.91366 1.34734 4.59708 1.89568 4.74002 2.42914L4.99884 3.39507C5.14178 3.92853 5.69012 4.24512 6.22359 4.10217C6.75705 3.95923 7.07363 3.4109 6.93069 2.87743L6.67187 1.9115Z"
        fill="#BAE6FD"
      />
      <path
        d="M2.42923 4.73993C1.89577 4.59699 1.34743 4.91357 1.20449 5.44704C1.06155 5.9805 1.37813 6.52884 1.9116 6.67178L2.87752 6.9306C3.41099 7.07354 3.95932 6.75696 4.10227 6.22349C4.24521 5.69003 3.92862 5.14169 3.39516 4.99875L2.42923 4.73993Z"
        fill="#BAE6FD"
      />
      <path
        d="M11.2427 4.17152C11.6332 3.781 11.6332 3.14784 11.2427 2.75731C10.8522 2.36679 10.219 2.36679 9.82847 2.75731L9.12136 3.46442C8.73084 3.85494 8.73084 4.48811 9.12136 4.87863C9.51189 5.26916 10.1451 5.26916 10.5356 4.87863L11.2427 4.17152Z"
        fill="#BAE6FD"
      />
      <path
        d="M4.17162 11.2426L4.87872 10.5355C5.26925 10.145 5.26925 9.5118 4.87872 9.12127C4.4882 8.73075 3.85503 8.73075 3.46451 9.12127L2.7574 9.82838C2.36688 10.2189 2.36688 10.8521 2.7574 11.2426C3.14793 11.6331 3.78109 11.6331 4.17162 11.2426Z"
        fill="#BAE6FD"
      />
      <path
        d="M7.37154 6.07155C7.00012 5.92298 6.57591 6.01005 6.29304 6.29292C6.01018 6.57578 5.92311 7 6.07167 7.37141L10.0717 17.3714C10.2179 17.737 10.5651 17.9828 10.9586 17.9992C11.352 18.0155 11.7185 17.7995 11.8946 17.4472L13.2741 14.6882L16.293 17.7071C16.6836 18.0977 17.3167 18.0977 17.7073 17.7071C18.0978 17.3166 18.0978 16.6834 17.7073 16.2929L14.6883 13.274L17.4474 11.8945C17.7996 11.7183 18.0157 11.3519 17.9993 10.9585C17.9829 10.565 17.7372 10.2178 17.3715 10.0715L7.37154 6.07155Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

const FormIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.4142 2.58579C16.6332 1.80474 15.3668 1.80474 14.5858 2.58579L7 10.1716V13H9.82842L17.4142 5.41421C18.1953 4.63316 18.1953 3.36683 17.4142 2.58579Z"
        fill="#BAE6FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 4.89543 2.89543 4 4 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6H4V16H14V12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12V16C16 17.1046 15.1046 18 14 18H4C2.89543 18 2 17.1046 2 16V6Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.5858 4.58579C13.3668 3.80474 14.6331 3.80474 15.4142 4.58579C16.1952 5.36683 16.1952 6.63316 15.4142 7.41421L12.4142 10.4142C11.6331 11.1953 10.3668 11.1953 9.58577 10.4142C9.19524 10.0237 8.56208 10.0237 8.17156 10.4142C7.78103 10.8047 7.78103 11.4379 8.17156 11.8284C9.73365 13.3905 12.2663 13.3905 13.8284 11.8284L16.8284 8.82843C18.3905 7.26633 18.3905 4.73367 16.8284 3.17157C15.2663 1.60948 12.7337 1.60948 11.1716 3.17157L9.67156 4.67157C9.28103 5.0621 9.28103 5.69526 9.67156 6.08579C10.0621 6.47631 10.6952 6.47631 11.0858 6.08579L12.5858 4.58579Z"
        fill="#BAE6FD"
      />
      <path
        d="M7.58579 9.58579C8.36683 8.80474 9.63316 8.80474 10.4142 9.58579C10.8047 9.97631 11.4379 9.97631 11.8284 9.58579C12.219 9.19526 12.219 8.5621 11.8284 8.17157C10.2663 6.60948 7.73367 6.60948 6.17157 8.17157L3.17157 11.1716C1.60948 12.7337 1.60948 15.2663 3.17157 16.8284C4.73367 18.3905 7.26633 18.3905 8.82843 16.8284L10.3284 15.3284C10.719 14.9379 10.719 14.3047 10.3284 13.9142C9.9379 13.5237 9.30474 13.5237 8.91421 13.9142L7.41421 15.4142C6.63316 16.1953 5.36684 16.1953 4.58579 15.4142C3.80474 14.6332 3.80474 13.3668 4.58579 12.5858L7.58579 9.58579Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

const UsersList = () => {
  const [t] = useTranslation();
  const { user, customer_identification, userEndedSubscription } = useAuth();

  const localStorage = useAppLocalStorage();

  // const [sorting, setSorting] = React.useState<SortingState>([]);
  const [limit, setLimit] = useState<10 | 30 | 50>(
    localStorage.getItem("umd_users_list_limit") &&
      [10, 30, 50].includes(
        Number(localStorage.getItem("umd_users_list_limit")) as any
      )
      ? (Number(localStorage.getItem("umd_users_list_limit")) as any)
      : 10
  );
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [sortBy] = useState<"CREATION_DATE" | "EMAIL" | "">("");
  const [state, dispatch] = useReducer(addUserActionReducer, {
    au: false,
    ma: false,
    sh: false,
  });

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    ["entity-users", [offset, limit, sortBy, sortOrder]],
    () =>
      HttpService.get(
        `/${customer_identification}/${
          user.entityIdentification
        }/entity/users?limit=${
          limit
          // limit < 50 ? limit + 1 : limit
        }&offset=${offset}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      onSuccess(data) {
        setLimit(data.data.element.pagination.limit ?? 10);
        setOffset(data.data.element.pagination.offset ?? 0);
      },
    }
  );

  const handleCopy = (identification: string) => {
    navigator.clipboard.writeText(identification);

    toast.info("Identification copied!");
  };

  const {
    data: resRole,
    isLoading: isLoadingRole,
    isError: isErrorRole,
    isSuccess: isSuccessRole,
    refetch: refetchRole,
  } = useQuery(
    ["roles_list", user.userIdentification],
    () =>
      HttpService.get(
        `/${user.userIdentification}/${user.entityIdentification}/entity/roles`,

        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const {
    data: resType,
    isLoading: isLoadingType,
    isError: isErrorType,
    isSuccess: isSuccessType,
    refetch: refetchType,
  } = useQuery(
    ["types_list", user.userIdentification],
    () =>
      HttpService.get(
        `/${user.userIdentification}/${user.entityIdentification}/entity/types`,

        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const columns: ColumnDef<User>[] = [
    {
      header: () => "#",
      accessorKey: "id",
      cell: (props) => {
        return (
          <Link
            to={
              props.row.original.user_identification !== customer_identification
                ? `${props.row.original.user_identification}`
                : `/control-panel/profile`
            }
            className="text-sky-500 underline"
          >
            {offset <= 0 ? props.row.index + 1 : offset + props.row.index + 1}
          </Link>
        );
      },
      size: 10,
      enableSorting: false,
    },
    {
      accessorKey: "user_identification",
      header: () => t("dashboard.usersList.item-15"),
      cell(props) {
        return (
          <div className="flex items-center gap-2 group h-4">
            <span>
              {props.row.original.user_identification.substring(0, 4) +
                "..." +
                props.row.original.user_identification.slice(
                  props.row.original.user_identification.length - 7
                )}
            </span>

            <button
              className="group-hover:opacity-100 opacity-0"
              onClick={() => handleCopy(props.row.original.user_identification)}
            >
              <FaCopy className="text-gray-400 cursor-pointer" />
            </button>
          </div>
        );
      },
      minSize: 150,
      enableResizing: false,
    },
    {
      accessorKey: "user_email",
      header: () => t("dashboard.usersList.item-01"),
      cell(props) {
        return (
          <div className="flex items-center gap-2 md:text-nowrap">
            <span
              className="shrink max-w-[300px] truncate"
              title={props.row.original.user_email}
            >
              {props.row.original.user_email}
            </span>

            {!props.row.original.user_is_active && (
              // <FaClock
              //   title={t("dashboard.usersList.item-12").toString()}
              //   className="text-gray-400 shrink-0"
              // />
              <div className="shrink-0 w-[86px] h-5 pl-2 pr-2.5 py-0.5 bg-amber-400/25 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-yellow-600 text-xs font-medium font-['Inter'] leading-none">
                  {t("dashboard.usersList.item-18")}
                </div>
              </div>
            )}
          </div>
        );
      },
      minSize: 330,
    },
    {
      accessorKey: "user_role",
      header: () => t("dashboard.usersList.item-03"),
      cell: (props) => {
        let content;

        if (isLoadingRole)
          content = <AiOutlineLoading className="h-6 w-6 animate-spin" />;

        if (isErrorRole)
          content = (
            <Button size="sm" color="light" onClick={() => refetchRole}>
              Reload
            </Button>
          );

        if (isSuccessRole)
          content = (
            <RoleOptionsPart
              data={{ role: props.row.original.user_role }}
              entity_identification={user.entityIdentification as string}
              user_identification={customer_identification}
              customerId={props.row.original.user_identification}
              list_role={resRole.data.element}
              disabled={userEndedSubscription}
            />
          );

        return content;
      },
      enableSorting: false,
      minSize: 150,
    },
    {
      accessorKey: "user_type",
      header: () => t("dashboard.usersList.item-04"),
      cell: (props) => {
        let content;

        if (isLoadingType)
          content = <AiOutlineLoading className="h-6 w-6 animate-spin" />;

        if (isErrorType)
          content = (
            <Button size="sm" color="light" onClick={() => refetchType}>
              Reload
            </Button>
          );

        if (isSuccessType)
          content = (
            <TypeOptionsPart
              data={{ type: props.row.original.user_type }}
              entity_identification={user.entityIdentification as string}
              user_identification={customer_identification}
              customerId={props.row.original.user_identification}
              list_type={resType.data.element}
              disabled={userEndedSubscription}
            />
          );

        return content;
      },
      enableSorting: false,
      minSize: 150,
    },
    {
      accessorKey: "user_creation_date_time",
      header: () => t("dashboard.usersList.item-05"),
      cell: (props) => (
        <span className="md:text-nowrap">
          {moment(props.row.original.user_creation_date_time)
            .utc()
            .format("MMMM D, YYYY | HH:mm")}
        </span>
      ),
      minSize: 200,
      enableResizing: false,
    },
    {
      accessorKey: "user_account_status",
      header: () => (
        <div className="text-center">{t("dashboard.usersList.item-02")}</div>
      ),
      cell: (props) => {
        if (props.row.original.user_account_status === "BANNED")
          return (
            <div className="flex items-end justify-center">
              <Tooltip content={t("dashboard.usersList.item-17")}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Ban">
                    <path
                      id="Icon"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.4766 14.8907C12.4958 15.5892 11.2959 16 10 16C6.68629 16 4 13.3137 4 10C4 8.70414 4.41081 7.50423 5.1093 6.52339L13.4766 14.8907ZM14.8908 13.4765L6.52354 5.1092C7.50434 4.41077 8.7042 4 10 4C13.3137 4 16 6.68629 16 10C16 11.2958 15.5892 12.4957 14.8908 13.4765Z"
                      fill="#F87171"
                    />
                  </g>
                </svg>
              </Tooltip>
            </div>

            // <div className="pl-2 pr-2.5 py-0.5 bg-red-100 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
            //   <div className="w-2 h-2 relative">
            //     <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-red-400 rounded-full" />
            //   </div>
            //   <div className="text-center text-red-800 text-xs font-medium font-['Inter'] leading-none">
            //     {t("dashboard.usersList.item-17")}
            //   </div>
            // </div>
          );

        if (props.row.original.user_account_status === "OK")
          return (
            <center>
              <Tooltip content={t("dashboard.usersList.item-11")}>
                <div className="p-2 bg-emerald-100 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
                  <div className="w-2 h-2 relative rounded-full flex flex-col items-center justify-center">
                    <div className="w-[10px] h-[10px] bg-emerald-400 rounded-full" />
                  </div>
                  {/* <div className="text-center text-emerald-800 text-xs font-medium font-['Inter'] leading-none">
                {t("dashboard.usersList.item-11")}
              </div> */}
                </div>
              </Tooltip>
            </center>
          );
      },
      enableSorting: false,
      minSize: 100,
      maxSize: 100,
    },
    {
      id: "actions",
      cell: (props) => {
        if (props.row.original.user_identification !== customer_identification)
          return (
            <Link to={`${props.row.original.user_identification}`} className="">
              <FaArrowRight />
            </Link>
          );
        else
          return (
            <Link to={`/control-panel/profile`}>
              <FaArrowRight />
            </Link>
          );
      },
      maxSize: 50,
    },
  ];

  const table = useReactTable({
    data: res?.data.element.users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // state: {
    //   sorting,
    // },
    // onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const handleShowRelatedAddUserType = (
    addUserType: "au" | "ma" | "sh" | null
  ) => {
    if (addUserType !== null || addUserType !== "") {
      switch (addUserType) {
        case "au":
          return dispatch({ type: AddUserActionKind.AU });
        case "ma":
          return dispatch({ type: AddUserActionKind.MA });
        case "sh":
          return dispatch({ type: AddUserActionKind.SH });
        default:
          return dispatch({ type: AddUserActionKind.DEFAULT });
      }
    }
  };

  const calculateCurrentPage = useCallback(() => {
    const totalItems = res?.data?.element?.pagination.total;

    if (limit <= 0) {
      throw new Error("Limit must be a positive integer.");
    }

    if (offset < 0) {
      throw new Error("Offset cannot be negative.");
    }

    // Ensure we don't have an invalid offset exceeding total items
    let newOffset = Math.min(offset, totalItems - 1);

    // Calculate the current page based on the offset and limit
    const currentPage = Math.floor(newOffset / limit);

    return currentPage;
  }, [limit, offset, res?.data?.element?.pagination.total]);

  const handlePageChange = (pageNumber: number) => {
    setOffset(pageNumber * limit);
  };

  let content;

  if (isLoading)
    content = (
      <Table.Row>
        <Table.Cell colSpan={8} align="center">
          <AiOutlineLoading className="h-6 w-6 animate-spin" />
        </Table.Cell>
      </Table.Row>
    );

  if (isError)
    content = (
      <Table.Row>
        <Table.Cell colSpan={8} align="center">
          <Button color="info" onClick={() => refetch()}>
            Reload
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  if (isSuccess)
    content = (
      <>
        {table.getRowModel().rows.map((row, i) => {
          if (i < limit) {
            return (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id} className="px-3 py-2.5">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          } else return null;
        })}
      </>
    );

  return (
    <div className="container mx-auto py-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.usersList.title")}
        </h1>
        <div className="flex flex-wrap items-md-center justify-end gap-4">
          {/* limit */}
          <Dropdown
            label={`${limit} ${t("dashboard.usersList.item-10")}`}
            dismissOnClick
            color={"light"}
            className="z-[1]"
            // size={"sm"}
          >
            {[10, 30, 50].map((item: any) => (
              <Dropdown.Item
                key={item}
                onClick={() => {
                  localStorage.setItem("umd_users_list_limit", item.toString());

                  setLimit(item);
                  setOffset(0);
                }}
              >
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown>

          {/* Sort */}
          <Dropdown
            label={
              !sortOrder
                ? t("dashboard.usersList.item-07")
                : sortOrder === "ASC"
                ? t("dashboard.usersList.item-08")
                : t("dashboard.usersList.item-09")
            }
            dismissOnClick
            color={"light"}
            // size={"sm"}
          >
            {["ASCENDING", "DESCENDING"].map((item: any) => (
              <Dropdown.Item
                key={item}
                onClick={() =>
                  setSortOrder(
                    item === "ASCENDING"
                      ? "ASC"
                      : item === "DESCENDING"
                      ? "DESC"
                      : ""
                  )
                }
              >
                {item === "ASCENDING"
                  ? t("dashboard.usersList.item-08")
                  : t("dashboard.usersList.item-09")}
              </Dropdown.Item>
            ))}
          </Dropdown>

          {/* Add user */}
          <Dropdown
            label=""
            dismissOnClick
            renderTrigger={() => (
              <button
                className="pl-[11px] pr-[13px] py-[9px] bg-sky-500 rounded-md shadow justify-center items-center gap-2 inline-flex"
                disabled={userEndedSubscription}
              >
                <div className="w-5 h-5 relative">
                  <FaUserPlus size={20} color="white" />
                </div>
                <div className="text-white text-sm font-medium font-['Inter'] leading-none">
                  {t("dashboard.usersList.key1")}
                </div>
              </button>
            )}
            className="bg-sky-500 rounded-md max-w-[350px] md:!left-[-25px] !left-[-10px]"
          >
            {!user?.customer_top_plan?.includes("FREE") && (
              <Dropdown.Item
                className="hover:bg-sky-blue focus:bg-sky-blue border-b border-gray-900/5 justify-start items-start gap-3 inline-flex"
                onClick={() => handleShowRelatedAddUserType("au")}
              >
                <div className="self-stretch py-2 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch justify-start items-start gap-3 inline-flex">
                    <div className="w-5 h-5 relative">
                      <PointerIcon />
                    </div>
                    <div className="text-white text-sm font-semibold font-['Figtree'] leading-tight">
                      {t("dashboard.usersList.addUserModal.title-01")}
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start gap-3 inline-flex">
                    <div className="w-[21px] text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                      {" "}
                    </div>
                    <div className="grow shrink basis-0 text-sky-100 text-xs text-left font-normal font-['Figtree'] leading-none">
                      {t("dashboard.usersList.addUserModal.text-01")}
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            )}
            <Dropdown.Item
              className="hover:bg-sky-blue focus:bg-sky-blue border-b border-gray-900/5 justify-start items-start gap-3 inline-flex"
              onClick={() => handleShowRelatedAddUserType("ma")}
            >
              <div className="self-stretch py-2 flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="w-5 h-5 relative">
                    <FormIcon />
                  </div>
                  <div className="text-white text-sm font-semibold font-['Figtree'] leading-tight">
                    {t("dashboard.usersList.addUserModal.title-02")}
                  </div>
                </div>
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="w-[21px] text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {" "}
                  </div>
                  <div className="grow shrink basis-0 text-sky-100 text-xs text-left font-normal font-['Figtree'] leading-none">
                    {t("dashboard.usersList.addUserModal.text-02")}
                  </div>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:bg-sky-blue focus:bg-sky-blue  justify-start items-start gap-3 inline-flex"
              onClick={() => handleShowRelatedAddUserType("sh")}
            >
              <div className="self-stretch py-2 flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="w-5 h-5 relative">
                    <LinkIcon />
                  </div>
                  <div className="text-white text-sm font-semibold font-['Figtree'] leading-tight">
                    {t("dashboard.usersList.addUserModal.title-03")}
                  </div>
                </div>
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="w-[21px] text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {" "}
                  </div>
                  <div className="grow shrink basis-0 text-sky-100 text-xs text-left font-normal font-['Figtree'] leading-none">
                    {t("dashboard.usersList.addUserModal.text-03")}
                  </div>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>

      <div className="dl-shadow-box p-0 mb-3">
        <div className="overflow-x-auto min-h-[340px]">
          <Table
            striped
            className="dl-table mb-0 users-list md:table-auto table-fixed"
          >
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <React.Fragment key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5"
                      style={{
                        width: `${header.getSize()}px`,
                        // header.id === "user_identification"
                        //   ? "230px"
                        //   : "auto",
                      }}
                    >
                      <React.Fragment>
                        {header.isPlaceholder ? null : (
                          <div
                          // className={
                          //   header.column.getCanSort()
                          //     ? "cursor-pointer select-none flex items-center gap-2"
                          //     : ""
                          // }
                          // onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {/* {{
                              asc: <FaSortUp />,
                              desc: <FaSortDown />,
                            }[header.column.getIsSorted() as string] ?? null} */}
                          </div>
                        )}
                      </React.Fragment>
                    </Table.HeadCell>
                  ))}
                </React.Fragment>
              ))}
            </Table.Head>
            <Table.Body>{content}</Table.Body>
          </Table>
        </div>

        <div className="flex justify-between items-center flex-wrap my-6 gap-6">
          <div>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.usersList.key2")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {offset + 1}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key3")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {res?.data.element.users.length
                ? res?.data.element.users.length + offset
                : 0}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key4")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {res?.data.element.pagination.total ?? 0}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key5")}
            </span>
          </div>

          {isSuccess && (
            <Pagination
              pageCount={res?.data?.element?.pagination.total_pages}
              currentPage={calculateCurrentPage()}
              onPageChange={handlePageChange}
            />
          )}
        </div>

        <AddUserAutomaticallyModal
          show={state.au}
          onBackClick={() => {
            dispatch({ type: AddUserActionKind.DEFAULT });
          }}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          size="lg"
          className="aum"
        />

        <AddUserManuallyModal
          show={state.ma}
          onBackClick={() => {
            dispatch({ type: AddUserActionKind.DEFAULT });
          }}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          size="2xl"
          className="aum"
        />

        <AddUserByShareQrCodeModal
          show={state.sh}
          onBackClick={() => {
            dispatch({ type: AddUserActionKind.DEFAULT });
          }}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          size="xl"
          className="aum"
        />

        <Outlet />
      </div>
    </div>
  );
};

export default UsersList;
