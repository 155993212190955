import {
  historyMessageCardFormProps,
  lmcFormValidTypes,
  lmcOptionType,
  pointCardFormProps,
  IncrementalCardFormProps,
  amountCardFormProps,
  deadlineCardFormProps,
  volumeCardFormProps,
  INormalizedLmcDataType,
} from "./types";

const wrapper = (type: lmcOptionType, data: lmcFormValidTypes) => {
  let typedData;

  switch (type) {
    case "POINT_CARD":
      typedData = data as pointCardFormProps;

      return {
        lmc_type: "POINT_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: {
          type: "POINT",
          values: typedData.values,
          //default: typedData.defaultValue,
          currency: null,
        },
        comment: typedData.comment,
        options: {
          pack: typedData.package,
          custom_value: typedData.customValue,
        },
      };
    case "HIST_MESSAGE_CARD":
      typedData = data as historyMessageCardFormProps;

      return {
        lmc_type: "HIST_MESSAGE_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: null,
        comment: typedData.comment,
        options: {
          // is_private_messages: typedData.private,
          nb_history_message: typedData.historyEventNumber,
        },
      };
    case "PERCENTAGE_CARD":
      typedData = data as pointCardFormProps;

      return {
        lmc_type: "PERCENTAGE_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: {
          type: "PERCENTAGE",
          values: typedData.values,
          //default: typedData.defaultValue,
          currency: null,
        },
        comment: typedData.comment,
        options: {
          custom_value: typedData.customValue,
        },
      };
    case "INCREMENTAL_CARD":
      typedData = data as IncrementalCardFormProps;

      return {
        lmc_type: "INCREMENTAL_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: null,
        comment: typedData.comment,
        options: {},
      };
    case "AMOUNT_CARD":
      typedData = data as amountCardFormProps;

      return {
        lmc_type: "AMOUNT_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: {
          type: "AMOUNT",
          values: typedData.values,
          //default: typedData.defaultValue,
          currency: typedData.currency,
        },
        comment: typedData.comment,
        options: {
          custom_value: typedData.customValue,
        },
      };
    case "DEADLINE_CARD":
      typedData = data as deadlineCardFormProps;

      return {
        lmc_type: "DEADLINE_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: {
          type: typedData.moneyType,
          values: typedData.moneyValues,
          //default: typedData.moneyDefaultValue,
          currency: typedData.moneyCurrency,
        },
        comment: typedData.comment,
        options: {
          custom_value: typedData.monyCustomValue,
          pre_defined_time: {
            values: typedData.timeValues,
            //default: typedData.timeDefaultValue,
            custom_value: typedData.timeCustomValue,
          },
        },
      };
    case "VOLUME_CARD":
      typedData = data as volumeCardFormProps;

      return {
        lmc_type: "VOLUME_CARD",
        scanner_auto_refresh: true,
        pre_defined_value: {
          type: "POINT",
          values: typedData.values,
          //default: typedData.defaultValue,
          currency: null,
        },
        comment: typedData.comment,
        options: {
          unit: typedData.unit,
          custom_value: typedData.customValue,
        },
      };
    default:
      return data;
  }
};

const unwrapper = (type: lmcOptionType, data: INormalizedLmcDataType) => {
  switch (type) {
    case "POINT_CARD":
      return {
        values: data.pre_defined_value.values.map((v) => {
          return { value: v };
        }),
        //defaultValue: data.pre_defined_value.default,
        package: data.options.pack,
        customValue: data.options.custom_value,
        comment: data.comment,
      } as pointCardFormProps;
    case "HIST_MESSAGE_CARD":
      return {
        // private: data.options.is_private_messages,
        historyEventNumber: data.options.nb_history_message,
        comment: data.comment,
      } as historyMessageCardFormProps;
    case "PERCENTAGE_CARD":
      return {
        values: data.pre_defined_value.values.map((v) => {
          return { value: v };
        }),
        // defaultValue: data.pre_defined_value.default,
        // package: data.options.pack,
        customValue: data.options.custom_value,
        comment: data.comment,
      } as pointCardFormProps;
    case "INCREMENTAL_CARD":
      return {
        comment: data.comment,
      } as IncrementalCardFormProps;
    case "AMOUNT_CARD":
      return {
        values: data.pre_defined_value.values.map((v) => {
          return { value: v };
        }),
        //defaultValue: data.pre_defined_value.default,
        currency: data.pre_defined_value.currency,
        customValue: data.options.custom_value,
        comment: data.comment,
      };
    case "DEADLINE_CARD":
      return {
        moneyType: data.pre_defined_value.type,
        moneyValues: data.pre_defined_value.values.map((v) => {
          return { value: v };
        }),
        //moneyDefaultValue: data.pre_defined_value.default,
        moneyCurrency: data.pre_defined_value.currency,
        monyCustomValue: data.options.custom_value,
        timeValues: data.options.pre_defined_time.values.map((v: number) => {
          return { value: v };
        }),
        //timeDefaultValue: data.options.pre_defined_time.default,
        timeCustomValue: data.options.pre_defined_time.custom_value,
        comment: data.comment,
      } as deadlineCardFormProps;
    case "VOLUME_CARD":
      return {
        values: data.pre_defined_value.values.map((v: number) => {
          return { value: v };
        }),
        //defaultValue: data.pre_defined_value.default,
        unit: data.options.unit,
        customValue: data.options.custom_value,
        comment: data.comment,
      } as volumeCardFormProps;
    default:
      return data;
  }
};

const uniqueValues = (arr: Array<any>, key: string) => {
  const seen = new Set();
  for (const obj of arr) {
    const value = obj[key];
    if (value !== null && value !== "" && seen.has(value)) {
      return false;
    }
    seen.add(value);
  }
  return true;
};

const validatePrecision = (value: number, precision: number) => {
  if (typeof value !== "number") {
    throw new Error("Input value must be a number");
  }

  const stringValue = value.toString();
  const decimalIndex = stringValue.indexOf(".");

  if (decimalIndex === -1) {
    return true; // No decimal places
  }

  const decimalPlaces = stringValue.length - decimalIndex - 1;
  return decimalPlaces <= precision;
};

const atLeastOneItemIsFilled = (arr: Array<{ value: number }>) => {
  let hasOne = arr.find(
    (item) =>
      Object.keys(item).length > 0 && item.value > 0 && item.value !== null
  );

  return hasOne ? true : false;
};

const LmcUtil = {
  wrapper,
  unwrapper,
  uniqueValues,
  validatePrecision,
  atLeastOneItemIsFilled,
};

export default LmcUtil;
