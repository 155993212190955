import "jsoneditor-react/es/editor.min.css";

import { AxiosError } from "axios";
import { ControlledJsonEditor } from "./UserEntityData";
import { useMutation, useQuery, useQueryClient } from "react-query";
import HttpService from "../../../services/http";
import { useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";

const UserEntityPreferences = ({
  entity_identification,
  customerId,
  isEditable = false,
  isUser,
}: {
  entity_identification: string;
  customerId: string;
  isEditable?: boolean;
  isUser: boolean;
}) => {
  const [t] = useTranslation();
  const { customer_identification, user, userEndedSubscription } = useAuth();

  const [updatedPreferences, setUpdatedPreferences] = useState({});

  const clientQuery = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    ["user-preferences", customerId],
    () =>
      HttpService.get(
        `/${customer_identification}/${entity_identification}/user/${customerId}/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdatePreferencesMutation = useMutation<any, AxiosError<any, any>, any>(
    (json) =>
      HttpService.patch(
        `/${customer_identification}/${entity_identification}/user/${customerId}/preferences`,
        { user_data: json },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Preferences are updated");

        clientQuery.invalidateQueries(["user-preferences", customerId]);
      },
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleChangeJson = (v: any) => {
    setUpdatedPreferences(v);
  };

  const handleUpdatePreferences = () => {
    UpdatePreferencesMutation.mutate(updatedPreferences);
  };

  let content;

  if (isLoading) content = <p>Loading...</p>;

  if (isError)
    content = (
      <Button onClick={() => refetch()}>
        {t("dashboard.userEntityPreferences.btn-01")}
      </Button>
    );

  if (isSuccess)
    content = (
      <>
        {isEditable ? (
          <ControlledJsonEditor
            value={res?.data.element.user_data_preferences}
            onChange={handleChangeJson}
          />
        ) : (
          <code>{JSON.stringify(res?.data.element.user_data_preferences)}</code>
        )}
      </>
    );

  if (isLoading) return <p>Loading...</p>;

  if (isError)
    return (
      <Button onClick={() => refetch()}>
        {t("dashboard.userEntityPreferences.btn-01")}
      </Button>
    );

  if (user.isBasic) return null;

  return (
    <div className={`edit-preferences ${isEditable ? "p-0" : ""}`}>
      <h2 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
        {isUser
          ? t("dashboard.userEntityPreferences.title-user")
          : t("dashboard.userEntityPreferences.title-owner")}
      </h2>

      <p className="text-gray-900 text-base font-normal font-['Figtree'] leading-normal">
        {isUser
          ? t("dashboard.userEntityPreferences.text-user-02")
          : t("dashboard.userEntityPreferences.text-owner-02")}
        &nbsp;
        {t("dashboard.userEntityPreferences.text-03")}
      </p>

      <div className={isEditable ? "py-6" : ""}>{content}</div>

      <div className="flex justify-end">
        {isEditable && (
          <Button
            onClick={handleUpdatePreferences}
            disabled={userEndedSubscription}
            isProcessing={UpdatePreferencesMutation.isLoading}
            className="bg-sky-500"
          >
            {t("dashboard.userEntityPreferences.btn-03")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserEntityPreferences;
