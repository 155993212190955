// import {
// useState,
// useEffect,
// } from "react";
// import CodeGen, { Language, RouteObject } from "../../../../services/codeGen";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";

const CodeSnippetPart = () => {
  // const [t] = useTranslation();

  // const localStorage = useAppLocalStorage();
  // const [lang, setLang] = useState<Language | "Choose language">(
  //   "Choose language"
  // );
  // const [code, setCode] = useState<string>("");

  // const [api, setApi] = useState<string>("Choose API");

  // const [routeData, setRouteData] = useState<RouteObject[]>([]);

  // useEffect(() => {
  //   let clientCredentials = localStorage.getItem("_umd_service");

  //   if (clientCredentials) {
  //     const { userIdentification, accessToken, entityIdentification } =
  //       JSON.parse(clientCredentials);

  //     setRouteData([
  //       {
  //         name: "POST - Login",
  //         url: `https://api.galloni-si.com/umd/api/auth/${entityIdentification}/login`,
  //         method: "post",
  //         headers: {
  //           Authorization:
  //             "Basic " + btoa(userIdentification + ":" + accessToken),
  //         },
  //         body: {
  //           user_email: "< Your mail >",
  //           user_password: "< Your password >",
  //         },
  //       },
  //       {
  //         name: "GET - Get Profile",
  //         url: `https://api.galloni-si.com/umd/api/user/${entityIdentification}/user_identification/profile`,
  //         method: "get",
  //         headers: {
  //           Authorization:
  //             "Basic " + btoa(userIdentification + ":" + accessToken),
  //         },
  //         body: {},
  //         params: {
  //           user_identification: "< User Identification >",
  //         },
  //       },
  //       {
  //         name: "PATCH - Update Profile",
  //         url: `https://api.galloni-si.com/umd/api/user/${entityIdentification}/user_identification/profile`,
  //         method: "patch",
  //         headers: {
  //           Authorization:
  //             "Basic " + btoa(userIdentification + ":" + accessToken),
  //         },
  //         body: {
  //           new_user_first_name: "< New User First Name >",
  //           new_user_last_name: "< New User Last Name >",
  //           new_user_birth_date: "< New User Birth Date >",
  //           new_user_country_code: "< New User Country Code >",
  //           new_user_user_name: "< New User username >",
  //         },
  //         params: {
  //           user_identification: "< User Identification >",
  //         },
  //       },
  //       {
  //         name: "DELETE - Remove User Profile Avatar",
  //         url: `https://api.galloni-si.com/umd/api/user/${entityIdentification}/user_identification/profile/avatar`,
  //         method: "delete",
  //         headers: {
  //           Authorization:
  //             "Basic " + btoa(userIdentification + ":" + accessToken),
  //         },
  //         body: {},
  //         params: {
  //           user_identification: "< User Identification >",
  //         },
  //       },
  //     ]);
  //   }
  // }, [localStorage]);

  // const handleChangeLanguage = (
  //   code: Language | "Choose language",
  //   api: string
  // ) => {
  //   setLang(code);

  //   if (api !== "Choose API") changeRoute(code, api);
  // };

  // function changeRoute(lng: Language | "Choose language", api: string) {
  //   let route = routeData.find((c) => c.name === api);

  //   if (route) {
  //     let code = CodeGen(route, lng);
  //     setCode(code);
  //   }
  //   setApi(api);
  // }

  // const handleCopyCode = () => {
  //   if (code && code !== "") navigator.clipboard.writeText(code);

  //   toast.info("Code copied");
  // };

  // const renderLangWithTR = (lang: Language | "Choose language") => {
  //   switch (lang) {
  //     case "Choose language":
  //       return t("dashboard.devTool.codeSnippetPart.dropDown-01");
  //     default:
  //       return lang;
  //   }
  // };

  // const renderApiWithTR = (api: string) => {
  //   switch (api) {
  //     case "Choose API":
  //       return t("dashboard.devTool.codeSnippetPart.dropDown-02");
  //     default:
  //       return api;
  //   }
  // };

  return (
    <div className="dl-shadow-box mb-5 code-gen">
      {/* <Row className="align-items-center mb-4 g-3">
        <Col xs={12} lg={6}>
          <h5>{t("dashboard.devTool.codeSnippetPart.header")}</h5>
          <p className="mb-0">
            {t("dashboard.devTool.codeSnippetPart.description-01")}
          </p>
          <p>{t("dashboard.devTool.codeSnippetPart.description-02")}</p>
        </Col>
        <Col xs={12} lg={6}>
          <div className="d-flex flex-md-row flex-column justify-content-end">
            <Dropdown className="me-3 mb-md-0 mb-3">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="primary"
              >
                {renderLangWithTR(lang)}
              </Dropdown.Toggle>

              <Dropdown.Menu variant="dark">
                {(Object.keys(Language) as Array<keyof typeof Language>).map(
                  (key) => {
                    return (
                      <Dropdown.Item
                        active={key === lang}
                        key={key}
                        onClick={() => handleChangeLanguage(Language[key], api)}
                      >
                        {key}
                      </Dropdown.Item>
                    );
                  }
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="primary"
              >
                {renderApiWithTR(api)}
              </Dropdown.Toggle>

              <Dropdown.Menu variant="dark">
                {routeData.map((route: RouteObject, key: number) => {
                  const RouteName: string = route.name || "Choose API";
                  return (
                    <Dropdown.Item
                      active={api === RouteName}
                      onClick={() => handleChangeLanguage(lang, RouteName)}
                      key={key + Math.random()}
                    >
                      {RouteName}
                    </Dropdown.Item>
                  );
                })}
               
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {lang !== "Choose language" && api !== "Choose API" && (
        <SyntaxHighlighter
          language={lang}
          style={docco}
          wrapLines={true}
          wrapLongLines={false}
          showLineNumbers={true}
          lineNumberStyle={{ color: "#ccc" }}
        >
          {code}
        </SyntaxHighlighter>
      )}

      {lang !== "Choose language" && api !== "Choose API" && (
        <div className="text-end">
          <Button variant="primary" onClick={handleCopyCode}>
            <svg
              width="15"
              className="me-2"
              height="24"
              viewBox="0 0 24 24"
              fill="#FFFFFF"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 20H5C3.67441 19.9984 2.40356 19.4711 1.46622 18.5338C0.528882 17.5964 0.00158786 16.3256 0 15L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L15 0C16.3256 0.00158786 17.5964 0.528882 18.5338 1.46622C19.4711 2.40356 19.9984 3.67441 20 5V15C19.9984 16.3256 19.4711 17.5964 18.5338 18.5338C17.5964 19.4711 16.3256 19.9984 15 20ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H5ZM24 19V6C24 5.73478 23.8946 5.48043 23.7071 5.29289C23.5196 5.10536 23.2652 5 23 5C22.7348 5 22.4804 5.10536 22.2929 5.29289C22.1054 5.48043 22 5.73478 22 6V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22H6C5.73478 22 5.48043 22.1054 5.29289 22.2929C5.10536 22.4804 5 22.7348 5 23C5 23.2652 5.10536 23.5196 5.29289 23.7071C5.48043 23.8946 5.73478 24 6 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19Z"
                fill="#FFFFFF"
              ></path>
            </svg>
            Copy code
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default CodeSnippetPart;
