import { useQuery } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { useEffect, useRef } from "react";
import QRCodeStyling from "livebook-qr-code";
import html2canvas from "html2canvas";

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  type: "svg",
  qrOptions: { errorCorrectionLevel: "H" },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
});

const CreateUserQrCode = () => {
  const [t] = useTranslation();
  const { user, customer_identification } = useAuth();

  const ref = useRef(null);

  const { data: res } = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  //   const handleCopyAddress = () => {
  //     navigator.clipboard.writeText(`${process.env.REACT_APP_HOST_URL}/${code}`);

  //     toast.info("Destination copied!");
  //   };

  const handleDownloadQrCode = () => {
    html2canvas(
      document.getElementById(
        `qr-${user.entityIdentification}`
      ) as HTMLCanvasElement
    ).then((canvas) => {
      let anchor = document.createElement("a");
      anchor.download = `create-user-${user.entityIdentification}.jpeg`;
      anchor.href = canvas.toDataURL("image/jpeg");
      anchor.click();
      anchor.remove();
    });

    // qrCode.download({
    //   extension: "jpeg",
    //   name: `create-user-${user.entityIdentification}`,
    // });
  };

  //   const handleShareNatively = async (destination: string) => {
  //     try {
  //       const shareData = {
  //         title: "Registration",
  //         text: "Please fill out the form",
  //         url: destination,
  //       };

  //       await navigator.share(shareData);
  //       // dispatch(setToast({ content: 'Shared successfully', variant: 'success' }))
  //     } catch (err) {
  //       // dispatch(setToast({ content: `Not supported. Try in native device (android,ios,...)`, variant: 'danger' }))
  //     }
  //   };

  useEffect(() => {
    if (ref.current) qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: `${process.env.REACT_APP_HOST_URL}/user/${user.entityIdentification}/registration`,
      image:
        res?.data.element.cdn_logo_project_path ||
        process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL,
      dotsOptions: {
        color: "#000000",
        type: "rounded",
      },
    });
  }, [res?.data.element.cdn_logo_project_path, user.entityIdentification]);

  return (
    <div className="share-modal">
      <center className="mb-6">
        <div ref={ref} id={`qr-${user.entityIdentification}`} />
      </center>

      <div className="d-flex justify-content-center align-items-center">
        <Button className="bg-sky-500 w-full" onClick={handleDownloadQrCode}>
          {t("dashboard.createUserQr.btn-02")}
        </Button>
      </div>
      {/* {/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && (
        <div className="text-center mt-3">
          <Button
            variant="primary"
            className="w-50 mx-auto"
            onClick={() =>
              handleShareNatively(`${process.env.REACT_APP_HOST_URL}/${code}`)
            }
          >
            {t("dashboard.createUserQr.btn-03")}
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default CreateUserQrCode;
