import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import {
  ServiceAvatarUpdateTypes,
  umdBase64,
} from "../develop/ServiceAvatarUpdate";
import { schema } from "../entities/EntityPreferences";
import { Button, Label, Modal, Select, TextInput } from "flowbite-react";
import { BiTrash } from "react-icons/bi";
import { AiOutlineLoading } from "react-icons/ai";
import { useTranslation } from "react-i18next";

type EntityPreferencesTypes = {
  hadleChangeStep: Dispatch<SetStateAction<"intro" | "noBasic" | "form">>;
  handleCloseModal: () => void;
  logoPath?: string;
  data: any;
  user_identification: string;
  entity_identification: string;
};

const ModalLogoUploader = ({
  data,
  entity_identification,
  user_identification,
  onChangeNewLogo,
}: ServiceAvatarUpdateTypes) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const CdnAvatarMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.post(
        `/${user_identification}/${entity_identification}/cdn/avatar`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateEntityPreferences = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/entity/preferences`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        queryClient.invalidateQueries([
          "entity_preferences",
          entity_identification,
        ]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleTriggerFileInput = () => {
    let inputFileElement = document.getElementById("avatarInput");

    inputFileElement?.click();
  };

  const handleUploadAvatar = (files: any) => {
    if (files.length > 0) {
      if (files[0].size > 3 * 1048576) return toast.error("File too large");

      CdnAvatarMutation.mutate({ image: files[0] } as any, {
        onSuccess: (res: AxiosResponse) => {
          onChangeNewLogo(res.data.element[0].cdnPath as string);

          UpdateEntityPreferences.mutate({
            entity_data_preferences: {
              cdn_logo_project_path: res.data.element[0].cdnPath,
            },
            //delete_keys: [],
          });
        },
      });
    }
  };

  const handleDeleteServiceAvatar = () => {
    UpdateEntityPreferences.mutate(
      {
        entity_data_preferences: { cdn_logo_project_path: umdBase64 },
        //delete_keys: [],
      },
      {
        onSuccess() {
          if (inputFileRef.current) inputFileRef.current.value = "";
        },
      }
    );
  };

  return (
    <div className="px-6 py-3 flex-col justify-start items-start gap-2 inline-flex">
      <div className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
        {t("dashboard.onBoarding.key13")}
      </div>
      <div className="justify-start items-center gap-5 inline-flex">
        <input
          type={"file"}
          hidden
          id="avatarInput"
          accept=".jpg,.png,.jpeg"
          ref={inputFileRef}
          onChange={(e) => handleUploadAvatar(e.target.files)}
        />
        <div className="w-12 h-12 relative bg-gray-100 rounded-md">
          <img
            title="Super User Manager"
            loading="lazy"
            src={data.cdn_logo_project_path}
            alt="send"
            className="w-full h-full object-cover rounded-md"
          />
        </div>
        <div className="justify-start items-center gap-4 flex">
          {data.cdn_logo_project_path !== umdBase64 && (
            <Button
              color="light"
              size="sm"
              className="font-['Figtree'] shadow"
              onClick={handleDeleteServiceAvatar}
              isProcessing={UpdateEntityPreferences.isLoading}
              disabled={UpdateEntityPreferences.isLoading}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
            >
              <BiTrash size={20} />
            </Button>
          )}
          <Button
            className="font-['Figtree'] shadow"
            color={"light"}
            onClick={handleTriggerFileInput}
            size={"sm"}
            type="button"
            isProcessing={CdnAvatarMutation.isLoading}
            disabled={CdnAvatarMutation.isLoading}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
          >
            {t("dashboard.onBoarding.key14")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const ModalPreferencesUpdater = ({
  entity_identification,
  user_identification,
  data,
  logoPath,
  hadleChangeStep,
  handleCloseModal,
}: EntityPreferencesTypes) => {
  const { t } = useTranslation();
  const [updateErrors, setUpdateErrors] = useState<any>({});

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const {
    data: passwordLevelsObj,
    isSuccess: isPassLevelsSuccess,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    "password-levels",
    () => HttpService.get("/auth/password-levels"),
    {
      onSuccess: () => {
        setValue(
          "password_validation_regex_level",
          data.password_validation_regex_level
        );
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateEntityPreferences = useMutation<any, AxiosError<any, any>>(
    (data) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/entity/preferences`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse, variables: any, context) => {
        if (
          Object.entries(variables.entity_data_preferences).toString() ===
          Object.entries(data).toString()
        ) {
          toast.success("Preferences updated successfully");
        }

        queryClient.invalidateQueries([
          "entity_preferences",
          entity_identification,
        ]);
        queryClient.invalidateQueries("entity-check");

        handleCloseModal();
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setUpdateErrors(err.response?.data.element);
      },
    }
  );

  const onUpdatePreferences = (data: any) => {
    let normalizedData = {
      ...data,
      cdn_logo_project_path: !!logoPath ? logoPath : null,
    };

    UpdateEntityPreferences.mutate({
      entity_data_preferences: { ...normalizedData },
      //delete_keys: [],
    } as any);
  };

  let content;

  if (isLoading) content = <p>Loading...</p>;

  if (isError)
    content = (
      <Button color="light" onClick={() => refetch()}>
        Refetch
      </Button>
    );

  if (isPassLevelsSuccess)
    content = (
      <form onSubmit={handleSubmit(onUpdatePreferences)}>
        <div className="px-6 py-3">
          <div className="mb-3">
            <Label
              htmlFor="web_site_login_page"
              className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              value={t("dashboard.onBoarding.key15").toString()}
            />

            <TextInput
              id="web_site_login_page"
              type="text"
              color={
                !!errors.web_site_login_page ||
                !!updateErrors?.web_site_login_page?.message
                  ? "failure"
                  : ""
              }
              defaultValue={data.web_site_login_page}
              {...register("web_site_login_page")}
              helperText={
                <>
                  {errors.web_site_login_page?.message ||
                    updateErrors?.web_site_login_page?.message}
                </>
              }
            />
          </div>

          <div className="mb-3">
            <Label
              htmlFor="web_site_reset_password_page"
              className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              value={t("dashboard.onBoarding.key16").toString()}
            />
            <TextInput
              id="web_site_reset_password_page"
              type="text"
              color={
                !!errors.web_site_reset_password_page ||
                !!updateErrors?.web_site_reset_password_page?.message
                  ? "failure"
                  : ""
              }
              defaultValue={data.web_site_reset_password_page}
              {...register("web_site_reset_password_page")}
              helperText={
                <>
                  {errors.web_site_reset_password_page?.message ||
                    updateErrors?.web_site_reset_password_page?.message}
                </>
              }
            />
          </div>

          <div className="mb-3">
            <Label
              htmlFor="email_address_sender"
              className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              value={t("dashboard.onBoarding.key17").toString()}
            />

            <TextInput
              id="email_address_sender"
              type="text"
              color={
                !!errors.email_address_sender ||
                !!updateErrors?.email_address_sender?.message
                  ? "failure"
                  : ""
              }
              defaultValue={data.email_address_sender}
              {...register("email_address_sender")}
              helperText={
                <>
                  {errors.email_address_sender?.message ||
                    updateErrors?.email_address_sender?.message}
                </>
              }
            />
          </div>

          <div className="mb-3">
            <Label
              htmlFor="password_validation_regex_level"
              className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              value={t("dashboard.onBoarding.key18").toString()}
            />

            <Select
              id="password_validation_regex_level"
              color={
                !!errors.password_validation_regex_level ||
                !!updateErrors?.password_validation_regex_level?.message
                  ? "failure"
                  : ""
              }
              defaultValue={data.password_validation_regex_level}
              {...register("password_validation_regex_level")}
              helperText={
                <>
                  {errors.password_validation_regex_level?.message ||
                    updateErrors?.password_validation_regex_level?.message}
                </>
              }
            >
              <option value="">{t("dashboard.onBoarding.key19")}</option>
              {isPassLevelsSuccess &&
                Object.keys(passwordLevelsObj?.data.element).map(
                  (key, index) => (
                    <option key={key} value={key}>
                      {key} = {passwordLevelsObj?.data.element[key].description}
                    </option>
                  )
                )}
            </Select>
          </div>

          <input
            type="hidden"
            defaultValue={!!logoPath ? logoPath : data.cdn_logo_project_path}
            {...register("cdn_logo_project_path")}
          />
        </div>

        <div className="w-full px-6 py-3 bg-gray-50 rounded-bl-lg rounded-br-lg justify-end items-center gap-3 inline-flex">
          <Button
            color="light"
            type="button"
            className="me-3"
            onClick={() => hadleChangeStep("intro")}
          >
            {t("dashboard.onBoarding.key20")}
          </Button>
          <Button
            className="bg-sky-500"
            type="submit"
            disabled={UpdateEntityPreferences.isLoading}
            isProcessing={UpdateEntityPreferences.isLoading}
          >
            {t("dashboard.onBoarding.key21")}
          </Button>
        </div>
      </form>
    );

  return <>{content}</>;
};

const ModalCompleteServiceInfo = ({
  hadleChangeStep,
  handleCloseModal,
}: {
  hadleChangeStep: Dispatch<SetStateAction<"intro" | "noBasic" | "form">>;
  handleCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const { user, customer_identification } = useAuth();

  const [entityLogoPath, setEntityLogoPath] = useState<string>();

  const {
    isLoading,
    isError,
    data: res,
    refetch,
  } = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      onSuccess: (value) => {
        setEntityLogoPath(value.data.element.cdn_logo_project_path);
      },
    }
  );

  if (isLoading) return <p>Loading...</p>;

  if (isError)
    return (
      <Button color="secondary" onClick={() => refetch()}>
        Retry
      </Button>
    );

  return (
    <Modal.Body className="p-0">
      <div className=" px-6 py-3 ">
        <h2 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7 mb-2">
          {t("dashboard.onBoarding.key8")}
        </h2>
        <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight mb-5">
          {t("dashboard.onBoarding.key9")}
        </p>
      </div>

      <ModalLogoUploader
        data={res?.data.element}
        entity_identification={user.entityIdentification}
        user_identification={customer_identification}
        onChangeNewLogo={setEntityLogoPath}
      />

      <ModalPreferencesUpdater
        data={res?.data.element}
        logoPath={entityLogoPath}
        entity_identification={user.entityIdentification}
        user_identification={customer_identification}
        hadleChangeStep={hadleChangeStep}
        handleCloseModal={handleCloseModal}
      />
    </Modal.Body>
  );
};

export default ModalCompleteServiceInfo;
