import { AxiosError } from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Modal } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { useDropzone } from "react-dropzone";

const AddUserAutomaticallyModal = ({
  show,
  onHide,
  size,
  className,
  onBackClick,
}: {
  show: boolean;
  onHide: () => void;
  size?: "lg" | "sm";
  className?: string | undefined;
  onBackClick: () => void;
}) => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const queryClient = useQueryClient();

  const token = HttpService.getToken();

  const addUserAutomaticallyMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/user/add/automatically`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: token,
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      addUserAutomaticallyMutation.mutate({ file: acceptedFiles[0] } as any, {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries("entity-users");
          queryClient.invalidateQueries([
            "roles_list",
            customer_identification,
          ]);
          queryClient.invalidateQueries([
            "types_list",
            customer_identification,
          ]);

          onHide();

          toast.success(data.message);
        },
      });
    },
    [addUserAutomaticallyMutation, customer_identification, onHide, queryClient]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    maxSize: 5242880 * 2,
  });

  return (
    <Modal show={show} onClose={onHide} size={size} className={className}>
      <Modal.Header className="border-b-0" as={"div"}>
        <h3 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7 mb-2">
          {t("dashboard.addUserAutomatically.title")}
        </h3>
        <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.addUserAutomatically.text")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div
          className="w-full px-[26px] pt-[84px] pb-[88px] rounded-md border-2 border-gray-300 border-dashed flex-col justify-start items-center gap-1 inline-flex mb-6"
          {...getRootProps()}
        >
          <div className="w-12 h-12 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                d="M18 26H30M24 20L24 32M34 42H14C11.7909 42 10 40.2091 10 38V10C10 7.79086 11.7909 6 14 6H25.1716C25.702 6 26.2107 6.21071 26.5858 6.58579L37.4142 17.4142C37.7893 17.7893 38 18.298 38 18.8284V38C38 40.2091 36.2091 42 34 42Z"
                stroke="#9CA3AF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <input {...getInputProps()} />
          <div className="justify-start items-center gap-1 inline-flex">
            <div className="bg-white rounded-md justify-start items-center flex">
              <div className="text-sky-600 text-sm font-semibold font-['Figtree'] leading-tight">
                {t("dashboard.addUserAutomatically.key1")}
              </div>
            </div>
            <div className="text-gray-600 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.addUserAutomatically.key2")}
            </div>
          </div>
          <div className="text-center text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
            {t("dashboard.addUserAutomatically.key3")}
          </div>
          {addUserAutomaticallyMutation.isLoading && (
            <div className="text-center">
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            </div>
          )}
        </div>

        <div className="justify-center items-start gap-2 flex">
          <div className="w-5 h-5 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6 13C3.79086 13 2 11.2091 2 9C2 6.79086 3.79086 5 6 5C6 2.79086 7.79086 1 10 1C12.2091 1 14 2.79086 14 5C16.2091 5 18 6.79086 18 9C18 11.2091 16.2091 13 14 13H11V8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8V13H6Z"
                fill="#0EA5E9"
              />
              <path
                d="M9 13H11V15.5858L12.2929 14.2929C12.6834 13.9024 13.3166 13.9024 13.7071 14.2929C14.0976 14.6834 14.0976 15.3166 13.7071 15.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071L6.29289 15.7071C5.90237 15.3166 5.90237 14.6834 6.29289 14.2929C6.68342 13.9024 7.31658 13.9024 7.70711 14.2929L9 15.5858V13Z"
                fill="#0EA5E9"
              />
            </svg>
          </div>
          <a
            href="/sample.csv"
            download
            className="text-sky-600 text-sm font-semibold font-['Figtree'] leading-tight"
          >
            {t("dashboard.addUserAutomatically.title-01")}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserAutomaticallyModal;
