import { AxiosError, AxiosResponse } from "axios";
import { useRef, useState } from "react";
import {
  Button,
  Spinner,
  Modal,
  TextInput,
  Select,
  Label,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import HttpService from "../../services/http";
import moment from "moment";
import BrokenLinkError from "../BrokenLinkError";
import CountryOptions from "../../components/public/CountryOptions";
import { HiCheck, HiUser, HiX } from "react-icons/hi";
import BsInputPassword from "../../components/public/BsInputPassword";

type RegisterType = {
  user_first_name: string | null;
  user_last_name: string | null;
  user_birth_date: string | null;
  user_country_code: string | null;
  user_user_name: string | null;
  user_email: string;
  user_password: string;
  user_confirm_password: string;
  user_role: string;
  user_type: string;
  user_avatar: string | null;
};

const schema = Joi.object({
  user_avatar: Joi.string()
    .trim()
    .uri()
    .optional()
    .allow("", null)
    .label("Avatar"),

  user_email: Joi.string().trim().required().label("Email"),

  user_user_name: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .optional()
    .allow(null, "")
    .label("Username"),

  user_first_name: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .optional()
    .allow(null, "")
    .label("First name"),

  user_last_name: Joi.string()
    .trim()
    .min(3)
    .max(150)
    .optional()
    .allow(null, "")
    .label("Last name"),

  user_birth_date: Joi.date().optional().allow(null, "").label("Birth date"),

  user_country_code: Joi.string()
    .trim()
    .optional()
    .allow(null, "")
    .label("Country code"),

  user_password: Joi.string().trim().required().label("Password"),
});

const RegisterViaLink = () => {
  const { entity_identification } = useParams();

  const inputFileRef = useRef<any>(null);

  const [serviceDetail, setServiceDetail] = useState<{ [key: string]: any }>(
    {}
  );

  const [successRegModal, setSuccessRegModal] = useState(false);
  const [errorRegModal, setErrorRegModal] = useState(false);
  const [apiError, setApiError] = useState<{ [key: string]: string }>({});
  const [isServiceDeactive, setIsServiceDeactive] = useState(false);
  const [toggleInputDateType, setToggleInputDateType] = useState<
    "text" | "date"
  >("text");

  const { isLoading, isSuccess, isError, refetch } = useQuery(
    "getEntityBasicInfo",
    () =>
      HttpService.get(
        `/link-registration/${entity_identification}/service-basic-info`
      ),
    {
      onSuccess: (data) => {
        setServiceDetail(data.data.element);
        setErrorRegModal(false);
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);

        if (err.response?.data.code === "RSUSKO010") setIsServiceDeactive(true);
        else setErrorRegModal(true);
      },
    }
  );

  const CdnAvatarMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.post(
        `/link-registration/${entity_identification}/avatar`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const RegisterLinkly = useMutation<any, AxiosError<any, any>>((data: any) =>
    HttpService.post(
      `/link-registration/${entity_identification}/register`,
      data
    )
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<RegisterType>({
    resolver: joiResolver(schema),
    defaultValues: {
      user_avatar: "",
      user_birth_date: "",
      user_country_code: "",
      user_email: "",
      user_first_name: "",
      user_last_name: "",
      user_password: "",
      user_user_name: "",
    },
  });

  const handleTriggerFileInput = () => {
    let inputFileElement = document.getElementById("avatarInput");

    inputFileElement?.click();
  };

  const handleUploadAvatar = (files: FileList | null) => {
    if (files && files.length > 0) {
      if (files[0].size > 3 * 1048576) return toast.error("File too large");

      CdnAvatarMutation.mutate({ image: files[0] } as any, {
        onSuccess: (res: AxiosResponse) => {
          setValue("user_avatar", res.data.element[0].cdnPath);
        },
      });
    }
  };

  const handleDeleteAvatarFile = () => {
    setValue("user_avatar", "");
    inputFileRef.current.value = null;
  };

  const onSubmit = (data: any) => {
    setApiError({});

    let finalPayload = {
      ...data,
      user_confirm_password: data.user_password,
      user_birth_date: moment(data.user_birth_date)
        .format("YYYY-MM-DD")
        .toString(),
      user_role: "default",
      user_type: "default",
    };

    Object.keys(finalPayload).forEach((key) => {
      if (
        finalPayload[key] === "" ||
        finalPayload[key] === null ||
        finalPayload[key] === undefined
      )
        delete finalPayload[key];

      if (key === "user_birth_date" && finalPayload[key] === "Invalid date")
        delete finalPayload[key];
    });

    RegisterLinkly.mutate(finalPayload, {
      onSuccess: (data) => {
        setSuccessRegModal(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        if (err.response?.data.element) setApiError(err.response?.data.element);
      },
    });
  };

  let content;

  if (isLoading)
    content = (
      <div className="h-28 flex items-center justify-center">
        <Spinner />
      </div>
    );

  if (isError && isServiceDeactive) content = <BrokenLinkError />;

  if (isError && !isServiceDeactive) content = null;

  if (isSuccess)
    content = (
      <div className="w-full md:w-[792px] mx-auto self-center px-6 pt-6 pb-10 bg-white rounded-lg shadow border border-gray-300 flex-col justify-start items-center gap-6 inline-flex">
        <div className="flex flex-col items-center justify-center gap-4">
          <div className="flex items-center justify-center gap-1">
            {serviceDetail?.entity_informations?.entity_logo ? (
              <img
                src={serviceDetail?.entity_informations?.entity_logo}
                alt={serviceDetail.entity_informations.entity_name}
                className="w-16 h-16 relative bg-gray-100 rounded-full shrink-0"
              />
            ) : null}

            {/* <span className="text-indigo-500 text-lg font-black font-['Figtree'] leading-[29px]">
              {serviceDetail?.entity_informations?.entity_name ||
                "Service name"}
            </span> */}
          </div>
          <h3 className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
            Create account
          </h3>
        </div>

        <div className="flex flex-col justify-start items-center gap-6">
          <div className="w-16 h-16 relative bg-gray-100 rounded-full overflow-hidden">
            {getValues("user_avatar") ? (
              <img
                className="w-16 h-16 object-cover rounded-full"
                src={getValues("user_avatar") as string}
                alt={"Super user manager"}
              />
            ) : (
              <HiUser size={64} className="text-gray-300 mt-2" />
            )}
          </div>
          <div className="">
            <div className="flex justify-center items-center gap-2">
              <input
                type={"file"}
                hidden
                id="avatarInput"
                accept=".jpg,.png,.jpeg"
                onChange={(e) => handleUploadAvatar(e.target.files)}
                ref={inputFileRef}
              />
              <Button
                color={"light"}
                onClick={handleTriggerFileInput}
                disabled={CdnAvatarMutation.isLoading}
                isProcessing={CdnAvatarMutation.isLoading}
              >
                {watch("user_avatar") === "" || watch("user_avatar") === null
                  ? "Add profile picture"
                  : "Replace picture"}
              </Button>
              {watch("user_avatar") && (
                <Button color={"light"} onClick={handleDeleteAvatarFile}>
                  Delete
                </Button>
              )}
            </div>
            {(apiError?.user_avatar || !!errors.user_avatar) && (
              <p className="text-red-700">
                {apiError?.user_avatar || errors?.user_avatar?.message}
              </p>
            )}
          </div>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col justify-start items-center gap-6"
        >
          <input hidden {...register("user_avatar")} />

          <div className="w-full">
            <Label
              htmlFor="user_email"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              Email
            </Label>
            <TextInput
              id="user_email"
              type="email"
              placeholder="Enter your email"
              color={
                !!errors.user_email || !!apiError?.user_email
                  ? "failure"
                  : undefined
              }
              {...register("user_email")}
              helperText={errors.user_email?.message || apiError?.user_email}
            />
          </div>

          <div className="w-full relative">
            <BsInputPassword
              rhfControls={register("user_password")}
              errorMessage={
                errors.user_password?.message || apiError?.user_password
              }
              isInvalid={!!errors.user_password || !!apiError?.user_password}
              label="Password"
              placeholder="Define your password"
            />
          </div>

          <div className="w-full flex flex-col md:flex-row justify-start items-center gap-6">
            <div className="w-full">
              <Label
                htmlFor="user_first_name"
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                First name
              </Label>
              <TextInput
                id="user_first_name"
                type="text"
                placeholder="Enter your first name"
                color={
                  !!errors.user_first_name || !!apiError?.user_first_name
                    ? "failure"
                    : undefined
                }
                {...register("user_first_name")}
                helperText={
                  errors.user_first_name?.message || apiError?.user_first_name
                }
              />
            </div>
            <div className="w-full">
              <Label
                htmlFor="user_last_name"
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                Last name
              </Label>
              <TextInput
                id="user_last_name"
                type="text"
                placeholder="Last name"
                color={
                  !!errors.user_last_name || !!apiError?.user_last_name
                    ? "failure"
                    : undefined
                }
                {...register("user_last_name")}
                helperText={
                  errors.user_last_name?.message || apiError?.user_last_name
                }
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row justify-start items-center gap-6">
            <div className="w-full">
              <Label
                htmlFor="user_birth_date"
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                Birth date
              </Label>
              <TextInput
                id="user_birth_date"
                type={toggleInputDateType}
                placeholder="mm/dd/yyyy"
                color={
                  !!errors.user_birth_date || !!apiError?.user_birth_date
                    ? "failure"
                    : undefined
                }
                onFocus={() => setToggleInputDateType("date")}
                {...register("user_birth_date", {
                  onBlur(event) {
                    setToggleInputDateType("text");
                  },
                })}
                helperText={
                  errors.user_birth_date?.message || apiError?.user_birth_date
                }
                max={moment().format("YYYY-MM-DD")}
              />
            </div>

            <div className="w-full">
              <Label
                htmlFor="user_country_code"
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                Region
              </Label>
              <Select
                id="user_country_code"
                color={
                  !!errors.user_country_code || !!apiError?.user_country_code
                    ? "failure"
                    : undefined
                }
                {...register("user_country_code")}
                helperText={
                  errors.user_country_code?.message ||
                  apiError?.user_country_code
                }
              >
                <option value="">Choose a region...</option>
                <CountryOptions />
              </Select>
            </div>
          </div>

          <div className="w-full">
            <Label
              htmlFor="user_user_name"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              User name
            </Label>
            <TextInput
              id="user_user_name"
              type="text"
              placeholder="Username"
              color={
                !!errors.user_user_name || !!apiError?.user_user_name
                  ? "failure"
                  : undefined
              }
              {...register("user_user_name")}
              helperText={
                errors.user_user_name?.message || apiError?.user_user_name
              }
            />
          </div>

          <div className="w-full">
            <Button
              className="w-full bg-gray-700"
              type="submit"
              disabled={
                // !watch("user_email") ||
                // watch("user_email") === "" ||
                // watch("user_email") === null ||
                RegisterLinkly.isLoading
              }
              isProcessing={RegisterLinkly.isLoading}
            >
              Create account
            </Button>
          </div>
        </form>
      </div>
    );

  return (
    <div className="w-full min-h-screen py-6 px-2 bg-gray-500 bg-opacity-75 flex justify-center items-start registerViaLink">
      {content}

      {/* Success modal */}
      <Modal
        show={successRegModal}
        size="md"
        onClose={() => setSuccessRegModal(false)}
        popup
        className=""
      >
        <Modal.Body className="p-4 flex flex-col justify-start items-center">
          <HiCheck size={24} className="mx-auto text-emerald-400" />
          <h4 className="mt-2 text-gray-900 text-sm font-medium font-['Inter'] leading-tight">
            Success!
          </h4>
          <p className="m-0 mt-3 text-gray-500 text-sm font-normal font-['Inter'] leading-tight">
            Your account has been created.
          </p>
        </Modal.Body>
      </Modal>

      {/* Error modal */}
      <Modal show={errorRegModal} size="md" className="">
        <Modal.Body className="p-4 flex flex-col justify-start items-center">
          <HiX size={24} className="mx-auto text-red-400" />
          <h4 className="mt-2 text-gray-900 text-sm font-medium font-['Inter'] leading-tight">
            Error!
          </h4>
          <p className="my-3">Oops! something went wrong please</p>

          <div className="flex items-center justify-center gap-5">
            <a
              href={process.env.REACT_APP_HOST_URL}
              className="no-underline	text-gray-500 text-sm font-normal font-['Inter'] leading-tight"
            >
              Back
            </a>
            <Button
              onClick={() => refetch()}
              className="bg-sky-500 hover:bg-sky-700"
            >
              try again
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterViaLink;
