import { AxiosError } from "axios";
import { Button } from "flowbite-react";
import { useMutation } from "react-query";
import HttpService from "../../../../services/http";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const ExportData = () => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const exportDataMutation = useMutation<any, AxiosError<any, any>, any>(
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/user/export`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "exportedData.csv");
        document.body.appendChild(link);
        link.click();
      },
    }
  );

  const handleExportData = () => {
    exportDataMutation.mutate(undefined);
  };

  return (
    <div className="w-full flex justify-between items-center">
      <div>
        <h3 className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.develop.exportData.title")}
        </h3>
        <small className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.develop.exportData.title-01")}
        </small>
      </div>

      <Button
        color="blue"
        className="bg-sky-500 hover:bg-sky-700"
        onClick={handleExportData}
        disabled={exportDataMutation.isLoading}
        isProcessing={exportDataMutation.isLoading}
      >
        {t("dashboard.develop.exportData.btn-01")}
      </Button>
    </div>
  );
};

export default ExportData;
