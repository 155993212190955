import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useIsAuthenticate from "./hooks/useIsAuthenticate";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { LanguageChanger } from "../dashboard/Layout";
import { HiArrowLeft } from "react-icons/hi";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../services/http";
import { toast } from "react-toastify";
import { Button, Label, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { lmcInputClass } from "./Index";
import { Timer } from "../../../routes/auth/ForgotPassword";

const LmcUserViewForgotPassword = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { processId } = useParams();
  const isAuthenticated = useIsAuthenticate();

  const {
    state: {
      entity: { entity_data },
    },
  } = useLmcUserView();

  const [showTimer, setShowTimer] = useState(false);

  const forgotPasswordMutation = useMutation<
    any,
    AxiosError<any, any>,
    { email_address: string }
  >(
    (data) =>
      HttpService.get(
        `/user/${
          entity_data?.entity_identification
        }/password?email_address=${encodeURIComponent(data.email_address)}`
      ),
    {
      onSuccess: (res) => {
        toast.success(t("lmcUser.key59"));

        setShowTimer(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setShowTimer(false);
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    forgotPasswordMutation.mutate(data);
  };

  useEffect(() => {
    if (isAuthenticated)
      navigate(`/process/${processId}/dashboard`, { replace: true });
  }, [isAuthenticated, navigate, processId]);

  return (
    <div className="w-full md:w-[400px] mx-auto bg-gray-900 flex flex-col justify-start items-center min-h-[100lvh] relative">
      <header className="w-full px-4 py-5 bg-gray-800 rounded-tl-[32px] rounded-tr-[32px] flex items-center justify-between">
        <div className="flex items-center gap-3" onClick={() => navigate(-1)}>
          <HiArrowLeft size={20} className="text-gray-400" />
          <span className="text-gray-400 text-base font-normal font-['Figtree'] leading-normal">
            {t("lmcUser.key26")}
          </span>
        </div>

        <div className="w-[226px] text-center text-zinc-200 text-xl font-semibold font-['Figtree'] leading-7">
          {t("lmcUser.key57")}
        </div>

        <LanguageChanger i18n={i18n} className="bg-transparent" />
      </header>

      <main className="w-full h-full p-4 gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full h-[calc(100lvh-100px)] flex flex-col relative"
        >
          <div className="w-full bg-gray-900 mb-4">
            <Label
              htmlFor="email_address"
              className="text-gray-200 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("lmcUser.key21")}
            </Label>
            <TextInput
              id="email_address"
              type="email"
              className={lmcInputClass}
              placeholder={t("lmcUser.key21").toString()}
              color={!!errors.email_address ? "failure" : undefined}
              {...register("email_address", {
                required: true,
                pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
              })}
              helperText={
                errors.email_address?.type === "required"
                  ? t("lmcUser.key22")
                  : errors.email_address?.type === "pattern"
                  ? t("lmcUser.key23")
                  : ""
              }
            />
          </div>

          <div className="w-full max-w-[400px] px-4 mx-auto mt-auto fixed bottom-5 left-0 right-0">
            <Button
              className="w-full h-11 bg-sky-400 rounded-[100px] text-neutral-900"
              type="submit"
              disabled={showTimer}
              isProcessing={forgotPasswordMutation.isLoading}
            >
              {showTimer ? (
                <span className="text-muted ms-2">
                  <Timer setShowTimer={setShowTimer} ttl={120} />{" "}
                  {t("register.confirmEmailModal.text_5")}
                </span>
              ) : (
                t("register.confirmEmailModal.btn_2")
              )}
            </Button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default LmcUserViewForgotPassword;
