import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import HttpService from "../../../services/http";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Spinner } from "flowbite-react";

const GroupAndUsersCounter = () => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    "count-users",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/count-users`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  let content;
  if (isLoading) content = <Spinner size="sm" />;

  if (isError)
    content = (
      <Button color="light" onClick={() => refetch()} size="sm">
        {t("dashboard.totalUsersCounter.btn-01")}
      </Button>
    );

  if (isSuccess) {
    content = res?.data;
  }

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
      <div className="text-gray-700 text-2xl font-bold font-['Figtree'] md:leading-loose leading-none">
        {t("dashboard.totalUsersCounter.key1")}
      </div>
      <Link
        to={"/control-panel/users"}
        className="self-stretch h-[152px] bg-white rounded-lg shadow flex-col justify-center items-start flex"
      >
        <div className="self-stretch p-6 justify-start items-center gap-5 inline-flex">
          <div className="p-3 bg-sky-500 rounded-md justify-center items-center flex">
            <div className="w-6 h-6 px-[5px] py-[3px] justify-center items-center flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
              >
                <path
                  d="M12 5C12 7.20914 10.2091 9 8 9C5.79086 9 4 7.20914 4 5C4 2.79086 5.79086 1 8 1C10.2091 1 12 2.79086 12 5Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 12C4.13401 12 1 15.134 1 19H15C15 15.134 11.866 12 8 12Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="self-stretch text-gray-500 text-sm font-medium font-['Inter'] leading-tight">
              {t("dashboard.totalUsersCounter.btn-02")}
            </div>
            <div className="justify-start items-end gap-2 inline-flex">
              <div className="text-gray-700 text-2xl font-semibold font-['Inter'] leading-loose">
                {content.users
                  ? new Intl.NumberFormat().format(content.users)
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </Link>
      <Link
        to={"/control-panel/groups"}
        className="self-stretch h-[152px] bg-white rounded-lg shadow flex-col justify-center items-start flex"
      >
        <div className="self-stretch p-6 justify-start items-center gap-5 inline-flex">
          <div className="p-3 bg-sky-500 rounded-md justify-center items-center flex">
            <div className="w-6 h-6 p-[3px] justify-center items-center flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4.35418C12.7329 3.52375 13.8053 3 15 3C17.2091 3 19 4.79086 19 7C19 9.20914 17.2091 11 15 11C13.8053 11 12.7329 10.4762 12 9.64582M15 21H3V20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21ZM15 21H21V20C21 16.6863 18.3137 14 15 14C13.9071 14 12.8825 14.2922 12 14.8027M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="self-stretch text-gray-500 text-sm font-medium font-['Inter'] leading-tight">
              {t("dashboard.totalUsersCounter.btn-03")}
            </div>
            <div className="justify-start items-end gap-2 inline-flex">
              <div className="text-gray-700 text-2xl font-semibold font-['Inter'] leading-loose">
                {content.groups
                  ? new Intl.NumberFormat().format(content.groups)
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GroupAndUsersCounter;
