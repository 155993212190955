import { Label, TextInput } from "flowbite-react";
import { useState } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

type InputPasswordProps = {
  label?: string;
  className?: string;
  isInvalid?: boolean;
  rhfControls: any;
  errorMessage?: string;
  placeholder?: string;
  rest?: Record<string, any>;
};

const BsInputPassword = (props: InputPasswordProps) => {
  const [show, setShow] = useState(false);

  return (
    <div className={props.className + " flowBitePasswordInput"}>
      {props.label && (
        <Label
          htmlFor={props.label}
          className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none block mb-3"
        >
          {props.label}
        </Label>
      )}

      <div
        className={`w-full pl-[17px] pr-[13px] py-[9px] rounded-md
         justify-between items-center inline-flex ${
           props.isInvalid
             ? "border-red-500 border-[2px]"
             : "border-gray-300 border-[1px]"
         }`}
      >
        <TextInput
          className="w-full mr-2 text-zinc-900 text-sm font-normal font-['Figtree'] leading-tight"
          id={props.label}
          placeholder={props.placeholder}
          type={show ? "text" : "password"}
          color={props.isInvalid ? "failure" : undefined}
          {...props.rest}
          {...props.rhfControls}
        />

        <button
          className="w-5 h-5 relative"
          type="button"
          onClick={() => setShow(!show)}
          disabled={props.rest?.disabled}
        >
          {!show ? (
            <HiOutlineEye color="#9CA3AF" size={20} />
          ) : (
            <HiOutlineEyeOff color="#9CA3AF" size={20} />
          )}
        </button>
      </div>

      {props.errorMessage && (
        <div className="block mt-2 text-red-600 text-sm">
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};

export default BsInputPassword;
