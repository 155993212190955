import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { useWindowSize } from "../hooks/useWindowSize";
import HttpService from "../services/http";
import { useTranslation } from "react-i18next";
import { Button, Table } from "flowbite-react";
import { FaArrowRight, FaClock, FaCopy } from "react-icons/fa6";
import { AiOutlineArrowLeft } from "react-icons/ai";
type User = {
  id: number;
  user_identification: string;
  user_first_name: string;
  user_last_name: string;
  user_user_name: string;
  user_creation_date_time: string;
  user_is_active: boolean;
  user_role: string;
  user_type: string;
  user_email: string;
  user_account_status: string;
};

const SearchPage = () => {
  const { user, customer_identification } = useAuth();
  const [t] = useTranslation();
  const [size] = useWindowSize();

  const location: any = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (location.state?.query) {
      setIsLoading(true);

      queryClient
        .fetchQuery(["search-user", location.state.query], () =>
          HttpService.get(
            `/${customer_identification}/${
              user.entityIdentification
            }/user/search?query=${encodeURIComponent(
              location.state.query ?? ""
            )}`,
            {
              auth: HttpService.getToken(),
            }
          )
        )
        .then((value) => {
          setData(value.data.element);

          setIsSuccess(true);
        })
        .catch((err: any) => {
          toast.error(err.response?.data?.message || err.message);

          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [
    location,
    customer_identification,
    queryClient,
    user.entityIdentification,
  ]);

  const handleCopy = (identification: string) => {
    navigator.clipboard.writeText(identification);

    toast.info("Identification copied!");
  };

  const columns: ColumnDef<User>[] = [
    {
      header: () => "#",
      accessorKey: "id",
      cell: (props) => {
        return (
          <Link
            to={
              props.row.original.user_identification !== customer_identification
                ? `/control-panel/users/${props.row.original.user_identification}`
                : `/control-panel/profile`
            }
            className="text-sky-500 underline"
          >
            {props.row.index + 1}
          </Link>
        );
      },
    },
    {
      accessorKey: "user_identification",
      header: () => t("dashboard.searchPage.item-01"),
      cell(props) {
        return (
          <div className="flex items-center gap-2 group h-4">
            <span>{props.row.original.user_identification}</span>

            <button
              className="group-hover:block hidden"
              onClick={() => handleCopy(props.row.original.user_identification)}
            >
              <FaCopy className="text-gray-400 cursor-pointer" />
            </button>
          </div>
        );
      },
    },
    // {
    //   accessorKey: "user_first_name",
    //   header: () => t("dashboard.searchPage.item-02"),
    // },
    // {
    //   accessorKey: "user_last_name",
    //   header: () => t("dashboard.searchPage.item-03"),
    // },
    // {
    //   accessorKey: "user_user_name",
    //   header: () => t("dashboard.searchPage.item-04"),
    // },
    {
      accessorKey: "user_email",
      header: () => t("dashboard.searchPage.item-05"),
      cell(props) {
        return (
          <div className="flex items-center gap-2">
            {props.row.original.user_email}

            {!props.row.original.user_is_active && (
              <FaClock className="text-gray-400" />
            )}
          </div>
        );
      },
    },
    // {
    //   accessorKey: "user_account_status",
    //   header: () => <i className="bi bi-person"></i>,
    //   cell: (props) => {
    //     if (props.row.original.user_account_status === "BANNED")
    //       return (
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="16"
    //           height="16"
    //           fill="#DC3545"
    //           className="bi bi-ban"
    //           viewBox="0 0 16 16"
    //         >
    //           <path d="M15 8a6.973 6.973 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
    //         </svg>
    //       );
    //     else
    //       return (
    //         <i
    //           className="bi bi-person-check text-success"
    //           style={{ fontSize: "1rem" }}
    //         ></i>
    //       );
    //   },
    // },
    // {
    //   accessorKey: "user_is_active",
    //   header: () => t("dashboard.searchPage.item-06"),
    //   cell: (props) => {
    //     if (props.row.original.user_is_active)
    //       return t("dashboard.searchPage.status-01");
    //     else return t("dashboard.searchPage.status-02");
    //   },
    // },
    {
      accessorKey: "user_role",
      header: () => t("dashboard.searchPage.item-07"),
    },
    {
      accessorKey: "user_type",
      header: () => t("dashboard.searchPage.key1"),
    },
    {
      accessorKey: "user_creation_date_time",
      header: () => t("dashboard.searchPage.item-08"),
      cell: (props) =>
        moment(props.row.original.user_creation_date_time)
          .utc()
          .format("MMMM D | HH:mm"),
    },
    {
      accessorKey: "user_is_active",
      header: () => t("dashboard.usersList.item-02"),
      cell: (props) => {
        if (props.row.original.user_account_status === "BANNED")
          return (
            <div className="pl-2 pr-2.5 py-0.5 bg-red-100 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
              <div className="w-2 h-2 relative">
                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-red-400 rounded-full" />
              </div>
              <div className="text-center text-red-800 text-xs font-medium font-['Inter'] leading-none">
                {t("dashboard.usersList.item-17")}
              </div>
            </div>
          );

        if (props.row.original.user_account_status === "OK")
          return (
            <div className="pl-2 pr-2.5 py-0.5 bg-emerald-100 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
              <div className="w-2 h-2 relative">
                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-emerald-400 rounded-full" />
              </div>
              <div className="text-center text-emerald-800 text-xs font-medium font-['Inter'] leading-none">
                {t("dashboard.usersList.item-11")}
              </div>
            </div>
          );
      },
    },
    {
      id: "actions",
      cell: (props) => {
        if (props.row.original.user_identification !== customer_identification)
          return (
            <Link
              to={`/control-panel/users/${props.row.original.user_identification}`}
              className=""
            >
              <FaArrowRight />
            </Link>
          );
        else
          return (
            <Link to={`/control-panel/profile`}>
              <FaArrowRight />
            </Link>
          );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        user_identification: size < 768 ? false : true,
        user_email: true,
        user_account_status: size < 768 ? false : true,
        user_is_active: size < 768 ? false : true,
        user_role: size < 768 ? false : true,
        user_first_name: size < 768 ? false : true,
        user_last_name: size < 768 ? false : true,
        user_creation_date_time: size < 768 ? false : true,
      },
    },
  });

  let content;

  if (isLoading) content = <div className="p-3 text-center">Loading...</div>;

  if (isError)
    content = (
      <Button
        color="light"
        size="sm"
        onClick={() => queryClient.refetchQueries("")}
      >
        Refetch
      </Button>
    );

  if (isSuccess)
    content =
      data.length > 0 ? (
        <div className="overflow-x-auto">
          <Table striped>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <React.Fragment>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5"
                      style={{
                        width:
                          header.id === "user_identification"
                            ? "230px"
                            : "auto",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </React.Fragment>
              ))}
            </Table.Head>
            <Table.Body>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <Table.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Table.Cell key={cell.id} className="px-3 py-2.5">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={10} className="px-3 py-2.5">
                    {t("dashboard.searchPage.text-01")}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <div className="p-3 text-center">
          <h4>{t("dashboard.searchPage.text-02")}</h4>
          <p>
            {t("dashboard.searchPage.text-03")}{" "}
            {location.state?.query ? (
              <b>{location.state?.query}</b>
            ) : (
              "this search key"
            )}
          </p>
          {/* <Link to={'/control-panel/users'}>
        <i className='bi bi-arrow-left me-2'></i>
        <span>{t("dashboard.searchPage.btn")}</span>
      </Link> */}
        </div>
      );

  return (
    <div className="users-page">
      <Helmet>
        <title>Search users | Super User Manager</title>
        <meta name="description" content="Search users" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/search`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        {location.state?.query ? (
          <div className="flex items-center gap-3 mb-6">
            <Link
              to={"/control-panel/users"}
              className="btn btn-link text-decoration-none text-dark d-flex align-items-center me-3 px-0"
            >
              <AiOutlineArrowLeft />
              {/* <span>{t("dashboard.searchPage.btn")}</span> */}
            </Link>
            <h4 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
              {t("dashboard.searchPage.text-04")}
              <b className="ms-2"> {location.state?.query}</b>
            </h4>
          </div>
        ) : (
          <p className="p-3">{t("dashboard.searchPage.text-05")}</p>
        )}
        {content}
      </div>
    </div>
  );
};

export default SearchPage;
