import React from "react";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  LoginSocialTiktok,
  IResolveParams,
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";

type Provider =
  | "Google"
  | "Amazon"
  | "Facebook"
  | "Github"
  | "Instagram"
  | "Linkedin"
  | "Microsoft"
  | "Pinterest"
  | "Twitter"
  | "Apple"
  | "Tiktok";

type providersIcon = {
  [item in Provider]?: React.ReactNode;
};

interface ISocialAuth {
  className?: string;
  providers: Provider | Array<Provider>;
  clientIds?: Record<Provider, string | undefined>;
  providersIcon?: providersIcon;
  providersRelatedClassName?: Record<Provider, string>;
  redirectUrl?: any;
  rest?: any;
  onLoginStartSocialAuthStart: () => void;
  onResolveSocialAuth: (
    provider: IResolveParams["provider"],
    data: IResolveParams["data"]
  ) => void;
  onRejectSocialAuth: (err: any) => void;
}

const defaultClientIds = {
  Facebook: process.env.REACT_APP_FB_APP_ID,
  Google: process.env.REACT_APP_GG_APP_ID,
  Amazon: process.env.REACT_APP_AMAZON_APP_ID,
  Instagram: process.env.REACT_APP_INSTAGRAM_APP_ID,
  Microsoft: process.env.REACT_APP_MICROSOFT_APP_ID,
  Linkedin: process.env.REACT_APP_LINKEDIN_APP_ID,
  Github: process.env.REACT_APP_GITHUB_APP_ID,
  Pinterest: process.env.REACT_APP_PINTEREST_APP_ID,
  Twitter: process.env.REACT_APP_TWITTER_APP_ID,
  Apple: process.env.REACT_APP_APPLE_ID,
  Tiktok: process.env.REACT_APP_TIKTOK_CLIENT_KEY,
};

const defaultRedirectUrls = {
  Amazon: window.location.href,
  Apple: window.location.href,
  Facebook: window.location.href,
  Github: window.location.href,
  Google: window.location.href,
  Instagram: window.location.href,
  Linkedin: window.location.href,
  Microsoft: window.location.href,
  Pinterest: window.location.href,
  Tiktok: window.location.href,
  Twitter: window.location.href,
};

const SocialAuth = ({
  clientIds = { ...defaultClientIds },
  providersIcon,
  providersRelatedClassName,
  providers,
  className,
  redirectUrl = { ...defaultRedirectUrls },
  rest,
  onLoginStartSocialAuthStart,
  onResolveSocialAuth,
  onRejectSocialAuth,
}: ISocialAuth) => {
  return (
    <div className={className} {...rest}>
      {(providers === "Amazon" || providers.includes("Amazon")) && (
        <LoginSocialAmazon
          isOnlyGetToken
          client_id={clientIds.Amazon || ""}
          redirect_uri={redirectUrl.Amazon}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Amazon}>
            {providersIcon?.Amazon || <AmazonLoginButton />}
          </div>
        </LoginSocialAmazon>
      )}

      {(providers === "Apple" || providers.includes("Apple")) && (
        <LoginSocialApple
          client_id={clientIds.Apple || ""}
          scope={"name email"}
          redirect_uri={redirectUrl.Apple}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Apple}>
            {providersIcon?.Apple || <AppleLoginButton />}
          </div>
        </LoginSocialApple>
      )}

      {(providers === "Google" || providers.includes("Google")) && (
        <LoginSocialGoogle
          isOnlyGetToken
          client_id={clientIds.Google || ""}
          scope={"profile email"}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err) => {
            // console.log(err)
          }}
        >
          <div className={providersRelatedClassName?.Google}>
            {providersIcon?.Google || <GoogleLoginButton />}
          </div>
        </LoginSocialGoogle>
      )}

      {(providers === "Facebook" || providers.includes("Facebook")) && (
        <LoginSocialFacebook
          isOnlyGetToken
          appId={clientIds.Facebook || ""}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Facebook}>
            {providersIcon?.Facebook || <FacebookLoginButton />}
          </div>
        </LoginSocialFacebook>
      )}

      {(providers === "Github" || providers.includes("Github")) && (
        <LoginSocialGithub
          isOnlyGetToken
          client_id={clientIds.Github || ""}
          client_secret={process.env.REACT_APP_GITHUB_APP_SECRET || ""}
          redirect_uri={redirectUrl.Github}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Github}>
            {providersIcon?.Github || <GithubLoginButton />}
          </div>
        </LoginSocialGithub>
      )}

      {(providers === "Instagram" || providers.includes("Instagram")) && (
        <LoginSocialInstagram
          isOnlyGetToken
          client_id={clientIds.Instagram || ""}
          client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ""}
          redirect_uri={redirectUrl.Instagram}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Instagram}>
            {providersIcon?.Instagram || <InstagramLoginButton />}
          </div>
        </LoginSocialInstagram>
      )}

      {(providers === "Linkedin" || providers.includes("Linkedin")) && (
        <LoginSocialLinkedin
          isOnlyGetToken
          client_id={clientIds.Linkedin || ""}
          client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ""}
          redirect_uri={redirectUrl.Linkedin}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Linkedin}>
            {providersIcon?.Linkedin || <LinkedInLoginButton />}
          </div>
        </LoginSocialLinkedin>
      )}

      {(providers === "Microsoft" || providers.includes("Microsoft")) && (
        <LoginSocialMicrosoft
          isOnlyGetToken
          client_id={clientIds.Microsoft || ""}
          redirect_uri={redirectUrl.Microsoft}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Microsoft}>
            {providersIcon?.Microsoft || <MicrosoftLoginButton />}
          </div>
        </LoginSocialMicrosoft>
      )}

      {(providers === "Pinterest" || providers.includes("Pinterest")) && (
        <LoginSocialPinterest
          isOnlyGetToken
          client_id={clientIds.Pinterest || ""}
          client_secret={process.env.REACT_APP_PINTEREST_APP_SECRET || ""}
          redirect_uri={redirectUrl.Pinterest}
          className="pinterest-btn"
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Pinterest}>
            {providersIcon?.Pinterest || (
              <div className="content">
                <span>Login With Pinterest</span>
              </div>
            )}
          </div>
        </LoginSocialPinterest>
      )}

      {(providers === "Tiktok" || providers.includes("Tiktok")) && (
        <LoginSocialTiktok
          client_key={clientIds.Tiktok as string}
          redirect_uri={redirectUrl.Tiktok}
          onLoginStart={onLoginStartSocialAuthStart}
          className="pinterest-btn"
          onResolve={({ provider, data }) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err) => {
            // console.log(err);
          }}
        >
          <div className={providersRelatedClassName?.Tiktok}>
            {providersIcon?.Tiktok || (
              <div className="content">
                <span>Login With Tiktok</span>
              </div>
            )}
          </div>
        </LoginSocialTiktok>
      )}

      {(providers === "Twitter" || providers.includes("Twitter")) && (
        <LoginSocialTwitter
          isOnlyGetToken
          client_id={clientIds.Twitter || ""}
          redirect_uri={redirectUrl.Twitter}
          onLoginStart={onLoginStartSocialAuthStart}
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err: any) => {
            onRejectSocialAuth(err);
          }}
        >
          <div className={providersRelatedClassName?.Twitter}>
            {providersIcon?.Tiktok || <TwitterLoginButton />}
          </div>
        </LoginSocialTwitter>
      )}
    </div>
  );
};

export default SocialAuth;
