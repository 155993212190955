import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "react-query";
import HttpService from "../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { BiCheck, BiX } from "react-icons/bi";
import PasswordValidator from "../../components/public/PasswordValidator";

const schema = Joi.object({
  new_password: Joi.string().trim().required().label("New password"),
  new_confirm_password: Joi.any()
    .valid(Joi.ref("new_password"))
    .required()
    .messages({ "any.only": "Those passwords didn't match. Try again." })
    .label("Confirm password"),
});

const ResetPassword = () => {
  const { t } = useTranslation();

  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const from = "/control-panel";

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user.isAuthenticated) navigate(from);

    if (!searchParams.get("user_identification") && !searchParams.get("token"))
      navigate("/");

    return () => {
      cleaner = false;
    };
  }, [auth, from, navigate, searchParams]);

  const [apiErrors, setApiErrors] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validatorCurrentPassword, setValidatorCurrentPassword] =
    useState<string>("");

  const rules = [
    {
      regex: "^.{8,32}$",
      message: t("ruleValidator.key1", { min: 8, max: 32 }),
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const ResetPasswordMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.patch(
        `/auth/${searchParams.get("user_identification")}/${searchParams.get(
          "token"
        )}/change-password`,
        data
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const PasswordCheckMutation = useMutation<
    any,
    AxiosError<any, any>,
    { password: string; level?: string | null }
  >((data) => HttpService.post("/auth/check-password", data), {
    onError: (err) => {
      toast.error(err.response?.data?.message || err.message);

      setApiErrors({ ...apiErrors, new_password: err.response?.data?.message });
      setError("new_password", err.response?.data?.message);
    },
  });

  const onSubmit = (data: any) => {
    PasswordCheckMutation.mutate(
      {
        password: data.new_password,
        level: "level2",
      },
      {
        onSuccess: () => {
          ResetPasswordMutation.mutate(data, {
            onSuccess: (res) => {
              toast.success(t("resetPassword.msg_1"));

              navigate("/login", { replace: true });
            },
          });
        },
      }
    );
  };

  return (
    <div className="auth-page min-vh-100 d-flex align-items-center">
      <Helmet>
        <title>Super User Manager - Reset password</title>
        <meta
          name="description"
          content="Super User Manager - Reset your password"
        />
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/reset-password`}
        />
      </Helmet>

      <div className="md:w-[448px] w-full flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-start items-center gap-6 flex">
          <div className="flex-col justify-start items-center gap-2 flex">
            <div className="text-center text-gray-700 text-3xl font-bold font-['Figtree'] leading-9">
              {t("resetPassword.title")}
            </div>
            <div className="justify-start items-center gap-1 inline-flex">
              <div className="text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                {t("resetPassword.text")}
              </div>
            </div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="self-stretch flex-col justify-start items-start gap-6 flex"
        >
          <div
            className={`self-stretch bg-white rounded-md border ${
              Object.keys(errors).length > 0
                ? "border-red-500"
                : "border-gray-300"
            } flex-col justify-start items-start flex`}
          >
            <div className="self-stretch px-[13px] py-[9px] bg-white rounded-tl-md rounded-tr-md shadow justify-between items-center inline-flex">
              <input
                type={showPassword ? "text" : "password"}
                className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
                placeholder={t("resetPassword.label_1").toString()}
                {...register("new_password", {
                  onChange(event) {
                    setValidatorCurrentPassword(event.target.value);
                  },
                })}
              />

              <button
                className="w-5 h-5 relative"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <HiEye color="#9CA3AF" size={20} />
                ) : (
                  <HiEyeOff color="#9CA3AF" size={20} />
                )}
              </button>
            </div>

            <div className="self-stretch px-[13px] py-[9px] bg-white rounded-bl-md rounded-br-md shadow border-t border-gray-300 justify-between items-center inline-flex">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
                placeholder={t("resetPassword.label_2").toString()}
                {...register("new_confirm_password")}
              />

              <button
                className="w-5 h-5 relative"
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {!showConfirmPassword ? (
                  <HiEye color="#9CA3AF" size={20} />
                ) : (
                  <HiEyeOff color="#9CA3AF" size={20} />
                )}
              </button>
            </div>

            {/* Password validator */}
            <PasswordValidator
              className={validatorCurrentPassword.length > 0 ? "p-3" : "hidden"}
              ruleSet={rules}
              passwordValue={validatorCurrentPassword}
              passedRuleClassName="flex items-center text-green-500"
              failedRuleClassName="flex items-center text-red-500"
              passIcon={<BiCheck size={20} />}
              failIcon={<BiX size={20} />}
            />
          </div>

          {Object.keys(errors).length > 0 && (
            <div>
              {Object.keys(errors).map((key) => (
                <p key={key} className="text-red-500 text-sm mb-0">
                  {errors[key]?.message?.toString()}
                </p>
              ))}
            </div>
          )}

          {apiErrors && Object.keys(apiErrors).length > 0 && (
            <div>
              {Object.keys(apiErrors).map((key) => (
                <p key={key} className="text-red-500 text-sm mb-0">
                  {apiErrors[key]}
                </p>
              ))}
            </div>
          )}

          <Button
            type="submit"
            className="w-full bg-sky-500 font-['Figtree']"
            disabled={ResetPasswordMutation.isLoading}
            isProcessing={ResetPasswordMutation.isLoading}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            size={"sm"}
          >
            Reset
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
