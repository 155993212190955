import axios from "axios";
import { storageAvailable } from "../providers/LocalStorageProvider";

let token: string | null | any;

const httpService = axios.create({
  baseURL: process.env.REACT_APP_UMD_BACK_END_DOMAIN
    ? process.env.REACT_APP_UMD_BACK_END_DOMAIN
    : "/umd-service/api",
});

httpService.interceptors.response.use(undefined, (error) => {
  if (
    (error.response.status === 403 &&
      !window.location.pathname.includes("/process/")) ||
    (error.response.status === 401 &&
      error?.response?.data?.code === "RSSEKO001")
  )
    return (window.location.href = "/logout");
  else return Promise.reject(error);
});

const setToken = (authPayload: any) => {
  let auth = {
    username: "",
    password: "",
  };

  auth.username = authPayload.userIdentification;
  auth.password = authPayload.accessToken;

  token = auth;

  return;
};

const getToken = () => {
  let authPayload = null;

  if (storageAvailable("localStorage"))
    authPayload = localStorage.getItem("_umd_service");

  let auth = {
    username: "",
    password: "",
  };

  if (authPayload) {
    let jsonAuthPayload = JSON.parse(authPayload);

    auth.username = jsonAuthPayload.userIdentification;
    auth.password = jsonAuthPayload.accessToken;
  } else {
    auth = token;
  }

  return auth;
};

const HttpService = {
  get: httpService.get,
  post: httpService.post,
  put: httpService.put,
  patch: httpService.patch,
  delete: httpService.delete,
  getToken,
  setToken,
};

export default HttpService;
