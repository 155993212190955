import UsersList from "../components/pages/dashboard/users/UsersList";
import { Helmet } from "react-helmet-async";

const Users = () => {
  return (
    <div className="users-page">
      <Helmet>
        <title>Users | Super User Manager</title>
        <meta name="description" content="List of users" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/users`}
        />
      </Helmet>
      <UsersList />
    </div>
  );
};

export default Users;
