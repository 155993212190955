import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import HttpService from "../services/http";
import { Helmet } from "react-helmet-async";
import UserEntityData from "../components/pages/dashboard/UserEntityData";
import UserEntityPreferences from "../components/pages/dashboard/UserEntityPreferences";
import ProfileBasicInfo from "../components/pages/dashboard/profile/ProfileBasicInfo";
import ProfileEmailChange from "../components/pages/dashboard/profile/ProfileEmailChange";
import ProfilePasswordChange from "../components/pages/dashboard/profile/ProfilePasswordChange";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const { user, customer_identification } = useAuth();

  const userDataQuery = useQuery(
    ["user-data", customer_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/data`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  return (
    <div>
      <Helmet>
        <title>Profile | Super User Manager</title>
        <meta name="description" content="Your profile" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/profile`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-none mb-5">
          {t("dashboard.profile.key1")}
        </h1>

        <div className="flex flex-col gap-5">
          <ProfileBasicInfo />

          <ProfileEmailChange />

          <ProfilePasswordChange />

          {!user.isBasic && (
            <div className="px-5 pt-5 pb-8 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
              <UserEntityPreferences
                entity_identification={user.entityIdentification}
                isUser={false}
                customerId={customer_identification}
                isEditable={true}
              />
            </div>
          )}

          {!user.isBasic && (
            <div className="px-5 pt-5 pb-8 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
              <UserEntityData
                query={userDataQuery}
                entity_identification={user.entityIdentification}
                isUser={false}
                customerId={customer_identification}
                isEditable={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
